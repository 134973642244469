/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState, useRef } from 'react';

import { Container } from './styles';

interface Props {
  title?: string;
  customState?: any;
  setCustomState?: any;
  typeOf?: any;
  disabled?: boolean;
  [x: string]: any;
}

BlueButton.defaultProps = {
  title: 'Salvar',
  customState: null,
  setCustomState: false,
  typeOf: 'button',
  disabled: false,
};

type ButtonProps = JSX.IntrinsicElements['button'] & Props;

export default function BlueButton({
  title,
  customState,
  setCustomState,
  typeOf,
  disabled,
  ...rest
}: ButtonProps) {
  const [loading, setLoading] = useState(false);

  const buttonRef = useRef(null);

  function customFunction() {
    if (typeof customState === 'boolean' && setCustomState) {
      setCustomState(!customState);
    } else if (
      (typeof customState === 'number' || typeof customState === 'string') &&
      setCustomState
    ) {
      setCustomState(customState);
    } else if (!customState && setCustomState) {
      setCustomState();
    }
  }

  useEffect(() => {
    buttonRef.current;
  }, [buttonRef.current]);

  return (
    <Container disableProp={disabled}>
      <button type={typeOf} onClick={customFunction} {...rest}>
        {title}
      </button>
    </Container>
  );
}
