import React, { useState, useEffect } from 'react';
import { formatISO } from 'date-fns';
import { toast } from 'react-toastify';
import { ValueType } from 'rsuite/lib/DateRangePicker';
import api from '../../services/api';
import { ReactComponent as Dots } from '../../icons/3dots-icon.svg';
import CargosPopUp from '../../components/userPopUps/Cargos';
import TableWControllers from '../../components/tableWControllers';
import Filter from '../../components/PDVfilter';
import { useError } from '../../hooks/errors';

import { Container } from './styles';

export default function Cargos() {
  const [data, setData] = useState();
  const [addCargos, setAddCargos] = useState(false);
  const [editCargos, setEditCargos] = useState(false);
  const [addRowData, setAddRowData] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [isFiltering, setIsFiltering] = useState<ValueType>();
  const { emitError } = useError();

  const handleFilter = async (e: ValueType) => {
    setIsFiltering(e);
    if (!e) return;
    try {
      const { data: response } = await api.get('/roles', {
        params: {
          startAt: formatISO(e[0]),
          endAt: formatISO(new Date(formatISO(e[1])).setHours(23, 59, 59)),
        },
      });

      setFilteredData(response);
    } catch (error) {
      emitError(error);
    }
  };

  useEffect(() => {
    api
      .get('/roles')
      .then((res: any) => setData(res.data))
      .catch(error => {
        emitError(error);
      });
  }, []);

  const columnCargo = [{ title: 'Cargo', field: 'name' }];

  return (
    <Container>
      <TableWControllers
        addButtonText="Adicionar Cargos"
        title="Permissões"
        filter
        filterState={openFilter}
        setFilter={setOpenFilter}
        isFiltering={!!isFiltering}
        column={[
          ...columnCargo,
          {
            width: '10%',
            cellStyle: {
              textAlign: 'right',
            },

            render: (rowData: any) => (
              <button
                type="button"
                disabled={!rowData?.isEditable}
                title={
                  !rowData?.isEditable
                    ? 'Esse cargo não pode ser editado'
                    : null
                }
                onClick={() => {
                  setAddRowData(rowData);
                  setEditCargos(true);
                }}
              >
                <Dots />
              </button>
            ),
          },
        ]}
        data={isFiltering ? filteredData : data}
        addButtonState={addCargos}
        addButtonFunction={setAddCargos}
      />

      {addCargos ? (
        <CargosPopUp isOpen={addCargos} setPopUpState={setAddCargos} />
      ) : null}
      {editCargos ? (
        <CargosPopUp
          isOpen={editCargos}
          setPopUpState={setEditCargos}
          rowData={addRowData}
        />
      ) : null}

      {openFilter ? (
        <Filter
          isOpen={openFilter}
          setPopUpState={setOpenFilter}
          handleChange={handleFilter}
          handleClearFilters={setIsFiltering}
          date={isFiltering}
        />
      ) : null}
    </Container>
  );
}
