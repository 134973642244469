import React, { useState, useEffect } from 'react';
import GMLogo from '../../icons/LOGO-GM-White.svg';
import { useAuth } from '../../hooks/auth';
import { ReactComponent as PlusSign } from '../../icons/blue-plus-sign-icon.svg';
import { ReactComponent as PDVLogo } from '../../icons/pdv-logo-white.svg';
import MenuCascating from './menuItens';
import {
  adminRoute,
  superRoute,
  managerRoute,
  userRoute,
} from './menuItens/items';
import PopUpAddOccurrence from '../PopsUpOcurence/addOcurrence';
import Mobile from './mobile';

import { ReactComponent as OcorrenciaIcon } from '../../icons/ocorrencia-icon.svg';
import { ReactComponent as DashIcon } from '../../icons/dash-icon.svg';
import { ReactComponent as PDVIcon } from '../../icons/ponto-de-venda-icon.svg';
import { ReactComponent as UserIcon } from '../../icons/users-icon.svg';
import { ReactComponent as ProductIcon } from '../../icons/products-icon.svg';

import { Container } from './styles';

export default function SideMenu() {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    if (
      user?.role.basicRole === 'ADMIN' ||
      user?.role.basicRole === 'MANAGER1'
    ) {
      setMenuList(adminRoute);
    } else if (
      user?.role.basicRole === 'USER' ||
      user?.role.basicRole === 'USER2'
    ) {
      setMenuList(userRoute);
    } else if (user?.role.basicRole === 'SUPERVISOR') {
      setMenuList(superRoute);
    } else if (user?.role.basicRole === 'MANAGER2') {
      setMenuList(managerRoute);
    }
  }, []);

  return (
    <Container>
      <Mobile />
      <div className="container-desk">
        <div className="logo-column">
          <a href="/">
            <img src={GMLogo} alt="gm-logo" />
          </a>
        </div>

        {user?.role?.permissions?.includes('canOpenOccurrence') && (
          <div className="new-occurrence-button">
            <button type="button" onClick={() => setOpenPopUp(!openPopUp)}>
              <PlusSign />
              Nova Ocorrência
            </button>
          </div>
        )}

        <nav className="menu-items">
          <ul>
            <MenuCascating
              icon={<OcorrenciaIcon />}
              title="Ocorrencia"
              forceState
              subMenu={[
                {
                  title: 'Central de Ocorrências',
                  path: '/central-de-ocorrencias',
                },
                {
                  title: 'Acompanhar Ocorrências',
                  path: '/acompanhar-ocorrencia',
                },
              ]}
            />
            <MenuCascating
              icon={<DashIcon />}
              title="Dashboard"
              hide={
                user?.role.basicRole === 'USER' ||
                user?.role.basicRole === 'USER2' ||
                user?.role.basicRole === 'SUPERVISOR'
              }
              subMenu={[
                {
                  title: 'Geral',
                  path: '/dashboard/geral',
                },
                {
                  title: 'Rede',
                  path: '/dashboard/rede',
                },
              ]}
            />
            <MenuCascating
              icon={<PDVIcon />}
              title="Ponto de Venda"
              hide={
                user?.role.basicRole !== 'ADMIN' &&
                !user?.role.permissions.includes('canEditPDV')
              }
              subMenu={[
                {
                  title: 'Pontos de Venda',
                  path: '/pdvs',
                  permission: 'canEditPDV',
                },
                {
                  title: 'Redes',
                  path: '/redes',
                  hide: user?.role.basicRole !== 'ADMIN',
                },
                {
                  title: 'Regionais',
                  path: '/regionais',
                  hide: user?.role.basicRole !== 'ADMIN',
                },
                {
                  title: 'Canais',
                  path: '/canais',
                  hide: user?.role.basicRole !== 'ADMIN',
                },
                {
                  title: 'Contratos',
                  path: '/contratos',
                  hide: user?.role.basicRole !== 'ADMIN',
                },
              ]}
            />
            <MenuCascating
              icon={<UserIcon />}
              title="Usuários"
              hide={
                user?.role.basicRole !== 'ADMIN' &&
                !user?.role.permissions.includes('canEditUser')
              }
              subMenu={[
                {
                  title: 'Usuários',
                  path: '/usuarios',
                  permission: 'canEditUser',
                },
                {
                  title: 'Equipe',
                  path: '/equipes',
                  hide: user?.role.basicRole !== 'ADMIN',
                },
                {
                  title: 'Cargos',
                  path: '/cargos',
                  hide: user?.role.basicRole !== 'ADMIN',
                },
              ]}
            />
            <MenuCascating
              icon={<ProductIcon />}
              title="Produtos"
              hide={
                user?.role.basicRole !== 'ADMIN' &&
                !user?.role.permissions.includes('canEditProduct')
              }
              subMenu={[
                {
                  title: 'Produtos',
                  path: '/produtos',
                  permission: 'canEditProduct',
                },
                {
                  title: 'Categorias',
                  path: '/categorias',
                  hide: user?.role.basicRole !== 'ADMIN',
                },
              ]}
            />
          </ul>
        </nav>

        <PDVLogo className="pdv-logo-sidemenu" />
      </div>
      {openPopUp ? (
        <PopUpAddOccurrence isOpen={openPopUp} setPopUpState={setOpenPopUp} />
      ) : null}
    </Container>
  );
}
