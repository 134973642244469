import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  display: flex;
  align-items: center;

  .active {
    background: var(--darkBlue);
    span {
      right: 4px;
      opacity: 1;
    }
  }
  button {
    width: 40px;
    height: 20px;
    background: #d9d9db;
    border-radius: 12px;
    position: relative;

    span {
      width: 12px;
      height: 12px;
      position: absolute;
      border-radius: 99px;
      background: white;
      top: 50%;
      transform: translateY(-50%);
      right: 22px;
      opacity: 0.8;
      transition: 0.3s;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  h6 {
    margin-left: 14px;
  }
`;
