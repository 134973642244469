import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  width: 100%;
  position: relative;

  .MuiPaper-root {
    box-shadow: none;

    .Component-horizontalScrollContainer-12 {
      padding: 0 16px;
    }
    h6 {
      font-size: var(--pSize);
      font-weight: var(--pWeight);
      letter-spacing: var(--pLetter);
      line-height: var(--pLine);
      color: #404249;
      font-family: 'Montserrat', sans-serif !important;
    }

    th {
      text-align: left;
      font-family: 'Montserrat', sans-serif !important;
      div {
        font-size: var(--smallSize);
        font-weight: var(--smallWeight);
        letter-spacing: var(--smallLetter);
        line-height: var(--smallLine);
        color: #66686d;
      }

      .MuiButtonBase-root {
        flex-direction: row;
      }
    }

    td {
      padding: 26px 16px;
      font-size: var(--h6Size) !important;
      font-weight: var(--h6Weight) !important;
      letter-spacing: var(--h6Letter);
      line-height: var(--h6Line);
      color: rgba(102, 104, 109, 1);
      font-family: 'Montserrat', sans-serif !important;
    }

    .MuiIconButton-root,
    .MuiTypography-caption {
      color: var(--darkBluePDV);
    }

    .MuiTableCell-footer {
      border: 0;
    }
  }

  .carregando {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .carregando2 {
      background: #fff;
      opacity: 0.6;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3;
    }
    img {
      height: 50px;
      z-index: 4;
    }
  }
`;
