/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const PhotoContainer = styled.div`
  label {
    font-size: var(--h6Size);
    font-weight: var(--h6Weight);
    letter-spacing: var(--h6Letter);
    line-height: var(--h6Line);
  }

  .photo-controllers {
    margin-top: 28px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .search-box {
      flex-grow: 1;
      color: var(--mediumGrey);
      padding: 13px 24px;
      border: 1px solid #abccf7;
      border-radius: 12px;
      font-size: var(--smallSize);
      font-weight: var(--smallWeight);
      letter-spacing: var(--smallLetter);
      line-height: var(--smallLine);
      display: flex;
      align-items: center;
    }

    .cancel-button {
      padding: 14px;
      border-radius: 12px;
      background: var(--red);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .search-button {
    padding: 13px 24px;
    background: var(--green);
    border-radius: 12px;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .description-field {
    margin-top: 24px;

    textarea {
      min-height: 50px;
      font-size: var(--pSize);
      font-weight: var(--pWeight);
      letter-spacing: var(--pLetter);
      line-height: var(--pLine);
    }

    label {
      display: block;
      margin-bottom: 6px;
    }
  }
`;

interface photoProp {
  url: any;
}

export const PhotoSection = styled.div<photoProp>`
  width: 100%;
  height: 167px;
  position: relative;
  background-image: url(${prop => prop.url});
  background-size: cover;
  background-position: center;
  border: 1px solid #abccf7;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;

  input {
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  svg {
    display: ${prop => (prop.url ? 'none' : '')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
`;
