/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:900px;
  opacity:1;
}

`;

interface Prop {
  size: number;
  status: string;
}

export const Container = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: calc(9 * 99);
`;

export const PopUp = styled.div<Prop>`
  width: 100%;
  max-width: 579px;
  margin-top: 32px;
  padding: 12px 16px 48px 16px;
  background: var(--white);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: relative;
  animation: 0.6s ease ${entranceAnim};

  .steps-on-history {
    width: 100%;
    max-width: 441px;
    margin: 36px auto 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;

    &::after {
      content: '';
      width: 1px;
      background: #d9d9db;
      top: 20px;
      bottom: 50px;
      position: absolute;
      left: 14px;
      z-index: 1;
    }
  }

  .pop-up-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;

    button {
      position: absolute;
      top: 10px;
      right: 16px;

      svg {
        width: 16px;
      }
    }
  }

  .current-active {
    background: ${prop =>
      prop.status === 'Reprovado' ? 'var(--red)' : 'var(--green)'} !important;
    &::before {
      content: '';
      width: 1px;
      background: var(--green);
      background: ${prop =>
        prop.status === 'Reprovado' ? 'var(--red)' : 'var(--green)'};
      height: ${prop => prop.size}%;
      top: 20px;
      position: absolute;
      left: 15px;
      /* transform: translateY(-50%); */
      z-index: -1;
    }
  }

  .current-state {
    height: 83px;
    display: flex;
    align-items: flex-start;

    div {
      max-width: 300px;
      display: flex;
      margin-left: 20px;
      flex-direction: column;
    }
    p,
    small,
    span {
      z-index: 2;
    }

    span {
      width: 28px;
      height: 28px;
      left: 0px;
      border-radius: 99px;
      margin: 12px 0 15px 0;
      background: #d4d4d4;
      z-index: 2;
    }
  }

  @media screen and (max-width: 880px) {
    .current-state {
      div {
        max-width: 250px;
      }
    }
  }
`;

export const HistoryContainer = styled.div``;
