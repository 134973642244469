import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { MainContainer, Container } from './styles';
import SideMenu from '../components/SideMenu';
import Header from '../components/Header';
import { useAuth } from '../hooks/auth';

import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import RecoverPassword from '../pages/RecoverPassword';
import Notifications from '../pages/Notifications';
import FollowOccurrences from '../pages/FollowOccurrences';
import Occurrences from '../pages/Occurrences';
import DashboardGeneral from '../pages/DashboardGeneral';
import DashboardNetworks from '../pages/DashboardNetworks';
import PDVs from '../pages/PDVs';
import Networks from '../pages/Networks';
import Regionals from '../pages/Regionals';
import Channels from '../pages/Channels';
import Contracts from '../pages/Contracts';
import Users from '../pages/Users';
import Teams from '../pages/Teams';
import Products from '../pages/Products';
import Categories from '../pages/Categories';
import Roles from '../pages/Roles';
import HookProviders from '../hooks';

const AdminRoutes = () => (
  <HookProviders>
    <MainContainer>
      <SideMenu />
      <Container>
        <Header />

        <Switch>
          <Route path="/central-de-ocorrencias" exact component={Occurrences} />
          <Route
            path="/acompanhar-ocorrencia"
            exact
            component={FollowOccurrences}
          />
          <Route path="/dashboard/geral" exact component={DashboardGeneral} />
          <Route path="/dashboard/rede" exact component={DashboardNetworks} />
          <Route path="/pdvs" exact component={PDVs} />
          <Route path="/redes" exact component={Networks} />
          <Route path="/regionais" exact component={Regionals} />
          <Route path="/canais" exact component={Channels} />
          <Route path="/contratos" exact component={Contracts} />
          <Route path="/usuarios" exact component={Users} />
          <Route path="/cargos" exact component={Roles} />
          <Route path="/equipes" exact component={Teams} />
          <Route path="/produtos" exact component={Products} />
          <Route path="/categorias" exact component={Categories} />
          <Route path="/notificacoes" exact component={Notifications} />
          <Redirect to="/central-de-ocorrencias" />
        </Switch>
      </Container>
    </MainContainer>
  </HookProviders>
);

const ManagerRoutes = () => (
  <HookProviders>
    <MainContainer>
      <SideMenu />
      <Container>
        <Header />

        <Switch>
          <Route path="/central-de-ocorrencias" exact component={Occurrences} />
          <Route
            path="/acompanhar-ocorrencia"
            exact
            component={FollowOccurrences}
          />
          <Route path="/dashboard/geral" exact component={DashboardGeneral} />
          <Route path="/dashboard/rede" exact component={DashboardNetworks} />
          <Route path="/redes" exact component={Networks} />
          <Route path="/regionais" exact component={Regionals} />
          <Route path="/canais" exact component={Channels} />
          <Route path="/contratos" exact component={Contracts} />
          <Route path="/pdvs" exact component={PDVs} />
          <Route path="/usuarios" exact component={Users} />
          <Route path="/produtos" exact component={Products} />
          <Route path="/categorias" exact component={Categories} />
          <Route path="/notificacoes" exact component={Notifications} />
          <Redirect to="/central-de-ocorrencias" />
        </Switch>
      </Container>
    </MainContainer>
  </HookProviders>
);

const SupervisorRoutes = () => (
  <HookProviders>
    <MainContainer>
      <SideMenu />
      <Container>
        <Header />

        <Switch>
          <Route path="/central-de-ocorrencias" exact component={Occurrences} />
          <Route
            path="/acompanhar-ocorrencia"
            exact
            component={FollowOccurrences}
          />
          <Route path="/pdvs" exact component={PDVs} />
          <Route path="/usuarios" exact component={Users} />
          <Route path="/equipes" exact component={Teams} />
          <Route path="/produtos" exact component={Products} />
          <Route path="/notificacoes" exact component={Notifications} />
          <Redirect to="/central-de-ocorrencias" />
        </Switch>
      </Container>
    </MainContainer>
  </HookProviders>
);

const UserRoutes = () => (
  <HookProviders>
    <MainContainer>
      <SideMenu />
      <Container>
        <Header />

        <Switch>
          <Route path="/central-de-ocorrencias" exact component={Occurrences} />
          <Route
            path="/acompanhar-ocorrencia"
            exact
            component={FollowOccurrences}
          />
          <Route path="/pdvs" exact component={PDVs} />
          <Route path="/usuarios" exact component={Users} />
          <Route path="/produtos" exact component={Products} />
          <Route path="/notificacoes" exact component={Notifications} />
          <Redirect to="/central-de-ocorrencias" />
        </Switch>
      </Container>
    </MainContainer>
  </HookProviders>
);

const AuthRoutes = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/esqueci-a-senha" component={ForgotPassword} />
    <Route path="/recuperar-senha" component={RecoverPassword} />
    <Redirect to="/login" />
  </Switch>
);

const PrivateRoute: React.FC = () => {
  const { user, signed } = useAuth();

  if (signed && user?.role?.basicRole === 'ADMIN') return <AdminRoutes />;
  if (
    (signed && user?.role?.basicRole === 'MANAGER1') ||
    user?.role?.basicRole === 'MANAGER2'
  )
    return <ManagerRoutes />;
  if (signed && user?.role?.basicRole === 'SUPERVISOR')
    return <SupervisorRoutes />;
  if (
    (signed && user?.role?.basicRole === 'USER') ||
    user?.role?.basicRole === 'USER2'
  )
    return <UserRoutes />;
  return <AuthRoutes />;
};

export default PrivateRoute;
