import styled from 'styled-components';
// eslint-disable-next-line import/prefer-default-export
export const Container = styled.section`
  width: 100%;
  max-width: 270px;
  min-height: 100%;
  display: block;
  padding: 16px 24px 4em 24px;
  background: var(--darkBlue);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: absolute;
  z-index: 99;

  @media screen and (max-width: 880px) {
    max-width: unset;
    height: 80px;
    min-height: 80px;
    background: none;
    box-shadow: none;
    position: fixed;
    bottom: 0;
    padding: 0px 20px;

    &::before {
      content: '';
      width: 100%;
      height: 64px;
      background: var(--white);
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    .container-desk {
      display: none;
    }
  }

  .logo-column {
    display: flex;
    justify-content: center;

    img {
      width: 131px;
      text-align: center;
    }
  }

  .new-occurrence-button {
    margin-top: 45px;
    > button {
      margin: 0 auto;
      padding: 7px 20px;
      border: 1px solid var(--darkBluePDV);
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Exo 2';
      color: var(--white);
      font-weight: 600;
      font-size: 12px;
      line-height: 1.2em;
      letter-spacing: 0.05em;
      transition: 0.3s;

      &:hover {
        background: #0099d933;
      }

      svg {
        margin-right: 11px;

        path {
          fill: var(--orange);
        }
      }
    }
  }

  .menu-items {
    margin-top: 28px;

    > ul {
      width: 100%;
      padding: 0;
    }

    .menu-active {
      span {
        color: var(--white);
        font-weight: 600;
        z-index: 2;
      }
      .nav-link {
        &::before {
          background: #0099d933;
          z-index: 1;
        }

        &::after {
          background: var(--orange);
        }
      }

      span {
        path,
        svg {
          fill: var(--orange);
        }
      }
    }
  }

  .pdv-logo-sidemenu {
    width: 128px;
    position: absolute;
    bottom: 23px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
