/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as CloseButton } from '../../icons/blue-close-button-icon.svg';
import RedButton from '../redButton';
import GreenButton from '../greenButton';
import { PopUp } from './styles';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  setCustomState?: any;
  sampleText: string;
};

ApprovePopUp.defaultProps = {
  // customState: null,
  setCustomState: false,
};

export default function ApprovePopUp({
  isOpen,
  setPopUpState,

  setCustomState,
  sampleText,
}: popUpProp) {
  const popUpRef = useRef(null);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  return (
    <PopUp ref={popUpRef}>
      <div className="pop-up-header-approve">
        <p style={{ width: '100%', justifyContent: 'center' }}>
          Deseja executar esta ação?
        </p>

        <button type="button" onClick={() => setPopUpState(!isOpen)}>
          <CloseButton />
        </button>
      </div>

      <div className="button-field">
        <GreenButton title="Confirmar" typeOf="submit" />

        <button
          type="button"
          className="cancel-button"
          onClick={() => setPopUpState(!isOpen)}
        >
          Cancelar
        </button>
      </div>
    </PopUp>
  );
}
