/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:900px;
  opacity:1;
}

`;

export const Container = styled.div`
  position: absolute;
  top: 100%;
  left: -50%;
  white-space: nowrap;
  background: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 14px 1em rgba(0, 0, 0, 0.1);
  z-index: 99999;

  button {
    padding: 10px 20px;
    border-radius: 10px;
    color: white;
    background: var(--blue);
  }

  .file-square {
    width: 100%;
    min-height: 30px;
    border-radius: 10px;
    padding: 10px 14px;
    background: var(--lightBlue);
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 1em 0;

    svg {
      margin-left: 20px;

      path {
        fill: white;
      }
    }
    input {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .downloadFile {
    a {
      padding-left: 5px;
      color: var(--lightBlue);
    }
  }
`;
