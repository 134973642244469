import React, { useRef, useEffect } from 'react';
import { ReactComponent as CloseButton } from '../../icons/blue-close-button-icon.svg';
import { Container, PopUp } from './styles';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
};

export default function PopUpPreco({ isOpen, setPopUpState }: popUpProp) {
  const popUpRef = useRef(null);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  return (
    <Container>
      <PopUp ref={popUpRef}>
        <div className="pop-up-header">
          <p> Preço Fora do Padrão</p>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>

        <h6>Motivo</h6>
        <p>Preço fora do padrão.</p>
      </PopUp>
    </Container>
  );
}
