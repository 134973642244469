/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ReactComponent as CloseButton } from '../../../icons/blue-close-button-icon.svg';
import BlueButton from '../../blueButton';
import { Container, PopUp } from './styles';
import Input from '../../Form/input';
import Select from '../../Form/select';
import FilterButton from '../../FilterButton';
import Delete from '../../DeletePopUp';
import RedButton from '../../redButton';
import { useUsers } from '../../../hooks/users';
import { useError } from '../../../hooks/errors';
import api from '../../../services/api';

import ApprovePopUp from '../../ApprovePopUp';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  rowData?: string[] | any;
};

const EquipePopUp = ({ setPopUpState, isOpen, rowData = false }: popUpProp) => {
  const { emitError } = useError();
  const [steps, setSteps] = useState('info');
  const [fieldPosition, setFieldPosition] = useState(1);
  const [selectedSupervisor, setSelectedSupervisor] = useState();
  const { users, getUsers } = useUsers();
  const formRef = useRef(null);
  const [deletePdv, setDeletePdv] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const popUpRef = useRef(null);

  useEffect(() => {
    if (!users) getUsers();
  }, []);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  const infoFields = [
    {
      title: 'Nome',
      type: 'input',
      name: 'name',
      value: rowData?.name || null,
      placeholder: rowData.name || 'Time Exemplo',
      isRequired: true,
    },
    {
      title: 'Gerente Regional',
      type: 'select',
      name: 'regional_id',
      optValue: true,
      value:
        rowData?.users?.find(user => user?.role?.basicRole === 'MANAGER2')
          ?.id || null,
      options: [
        { id: 'none', name: 'Nenhum' },
        ...users?.filter(user => user?.role?.basicRole === 'MANAGER2'),
      ],
    },

    {
      title: 'Gerente Distrital',
      type: 'select',
      name: 'distrital_id',
      optValue: true,
      value:
        rowData?.users?.find(user => user?.role?.basicRole === 'MANAGER1')
          ?.id || null,
      options: [
        { id: 'none', name: 'Nenhum' },
        ...users?.filter(user => user?.role?.basicRole === 'MANAGER1'),
      ],
    },
    {
      title: 'Supervisor',
      type: 'select',
      name: 'supervisor_id',
      optValue: true,
      change: (e: any) => setSelectedSupervisor(e),
      value:
        rowData?.users?.find(user => user?.role?.basicRole === 'SUPERVISOR')
          ?.id || null,
      options: [
        { id: 'none', name: 'Nenhum' },
        ...users?.filter(user => user?.role?.basicRole === 'SUPERVISOR'),
      ],
    },
  ] as any;

  async function handlePost(data: any) {
    try {
      let parsedData: any = {
        name: data?.name,
      };

      delete data?.name;

      const selectedUsers = [];
      Object.values(data).forEach(user => {
        selectedUsers.push(user);
      });

      parsedData = {
        ...parsedData,
        users: selectedUsers?.filter(user => user !== 'none'),
      };

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome do time é obrigatório'),
        users: Yup.array().of(Yup.object()),
      });

      /* await schema.validate(parsedData, {
        abortEarly: false,
      }); */

      await api.post('/teams', parsedData);
      window.location.reload();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        console.log(error);
        emitError({}, 'Preencha os campos obrigatórios!');
        setShowConfirmation(false);
        setSteps('info');

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  async function handlePut(data: any) {
    try {
      let parsedData: any = {
        name: data?.name,
      };

      delete data?.name;

      const selectedUsers = [];
      Object.values(data).forEach(user => {
        selectedUsers.push(user);
      });

      parsedData = {
        ...parsedData,
        users: selectedUsers?.filter(user => user !== 'none'),
      };

      const schema = Yup.object().shape({
        name: Yup.string().required('O nome do time é obrigatório'),
        users: Yup.array().of(Yup.object()),
      });

      // await schema.validate(parsedData, {
      //   abortEarly: false,
      // });

      await api.put(`/teams/${rowData.id}`, parsedData);
      window.location.reload();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        console.log('AAA', error);
        emitError({}, 'Preencha os campos obrigatórios!');
        setShowConfirmation(false);
        setSteps('info');

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  async function handleDelete() {
    try {
      await api.delete(`/teams/${rowData.id}`);
      toast.success('Equipe deletada');
      window.location.reload();
    } catch (error: any) {
      toast.error('Equipe referenciada por outra tabela');
    }
  }

  useEffect(() => {
    if (steps === 'info') {
      setFieldPosition(1);
    } else if (steps === 'end') {
      setFieldPosition(2);
    }
  }, [steps]);

  return (
    <Container>
      <PopUp ref={popUpRef}>
        {deletePdv ? (
          <Delete
            title="Ponto de Venda"
            isOpen={deletePdv}
            setCustomState={handleDelete}
            setPopUpState={setDeletePdv}
          />
        ) : null}
        <div className="pop-up-header">
          <h5>Equipe</h5>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>
        <div className="popUp-controllers">
          <button
            type="button"
            className={steps === 'info' ? 'active' : ''}
            onClick={() => setSteps('info')}
          >
            Geral
          </button>
          <button
            type="button"
            className={steps === 'end' ? 'active' : ''}
            onClick={() => setSteps('end')}
          >
            Equipe
          </button>
        </div>

        <div className="conditional-content">
          <Form onSubmit={rowData ? handlePut : handlePost} ref={formRef}>
            <div className=" fields-section teste-action">
              <div
                className={
                  fieldPosition === 1
                    ? 'information-section action'
                    : 'information-section'
                }
              >
                <ul className="input-field">
                  {infoFields.map(e => (
                    <li>
                      <h6>
                        {e.title}
                        {e?.isRequired && (
                          <span style={{ display: 'inline', color: 'red' }}>
                            {' '}
                            *
                          </span>
                        )}
                      </h6>

                      {e.type === 'select' ? (
                        <Select
                          fields={e.options}
                          name={e.name}
                          value={e?.value}
                          optValue={e?.optValue}
                          change={e?.change}
                        />
                      ) : (
                        <Input
                          type="text"
                          name={e.name}
                          value={e.value}
                          placeholder={e.placeholder}
                        />
                      )}
                    </li>
                  ))}
                </ul>
                <div className="step-buttons">
                  <BlueButton
                    title="Próximo"
                    onClick={() => {
                      setSteps('end');
                    }}
                  />

                  <button
                    type="button"
                    className="cancel-button"
                    onClick={() => setPopUpState(!isOpen)}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
              <div
                className={
                  fieldPosition === 2 ? 'end-section action' : 'end-section'
                }
              >
                <span style={{ marginTop: '20px' }}>Representantes</span>
                {selectedSupervisor ||
                rowData?.users?.find(
                  user => user?.role?.basicRole === 'SUPERVISOR',
                ) ? (
                  <ul className="executive-selection">
                    {users
                      ?.filter(user => user.role.basicRole === 'USER')
                      .map((e, index) => (
                        <li>
                          <FilterButton
                            isTrue={
                              !!rowData?.users?.find(
                                team => team.name === e?.name,
                              )
                            }
                            name={index.toString()}
                            title={e.name}
                            value={e.id}
                          />
                        </li>
                      ))}
                  </ul>
                ) : (
                  <span
                    style={{
                      color: 'var(--lightBlue)',
                      fontSize: '11px',
                      lineHeight: '1em',
                    }}
                  >
                    Para adicionar representantes é necessário um supervisor
                    vinculado ao time.
                  </span>
                )}

                <span style={{ marginTop: '20px' }}>Executivos</span>
                <ul className="executive-selection">
                  {users
                    ?.filter(user => user.role.basicRole === 'USER2')
                    .map((e, index) => (
                      <li>
                        <FilterButton
                          isTrue={
                            !!rowData?.users?.find(
                              team => team.name === e?.name,
                            )
                          }
                          name={index.toString()}
                          title={e.name}
                          value={e.id}
                        />
                      </li>
                    ))}
                </ul>

                {!rowData ? (
                  <div className="step-buttons">
                    <BlueButton
                      setCustomState={setShowConfirmation}
                      customState={showConfirmation}
                      title="Criar"
                    />

                    <button
                      type="button"
                      className="cancel-button"
                      onClick={() => setPopUpState(!isOpen)}
                    >
                      Cancelar
                    </button>
                  </div>
                ) : (
                  <div className="edit-pdv">
                    <RedButton
                      title="Apagar"
                      setCustomState={setDeletePdv}
                      customState={deletePdv}
                    />
                    <BlueButton
                      title="Editar"
                      setCustomState={setShowConfirmation}
                      customState={showConfirmation}
                    />
                  </div>
                )}
              </div>
            </div>

            {showConfirmation ? (
              <ApprovePopUp
                sampleText="Adicionar Equipe?"
                isOpen={showConfirmation}
                setPopUpState={setShowConfirmation}
              />
            ) : null}
          </Form>
        </div>
      </PopUp>
    </Container>
  );
};

export default EquipePopUp;
