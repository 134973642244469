/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { Container } from './styles';
import { useError } from '../../../../hooks/errors';
import { ReactComponent as ImportIcon } from '../../../../icons/importIcon.svg';
import api from '../../../../services/api';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  importFile: any;
  modelLink: string;
};

export default function ImportModal({
  setPopUpState,
  isOpen,
  importFile,
  modelLink,
}: popUpProp) {
  const popUpRef = useRef(null);
  const { emitError } = useError();

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  async function handleUpload(e) {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('file', file);
    try {
      await api.post(`/import/${importFile}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Importado com sucesso');
      window.location.reload();
    } catch (error: any) {
      emitError(error);
    }
    e.target.value = '';
  }

  return (
    <Container ref={popUpRef}>
      <span className="file-square">
        <input type="file" name="import" onChange={e => handleUpload(e)} />
        Importar Planilha
        <ImportIcon />
      </span>

      <div className="downloadFile">
        <h6>
          ou baixar
          <a href={modelLink}>planilha modelo</a>
        </h6>
      </div>
    </Container>
  );
}
