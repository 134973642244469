/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-spread */
/* eslint-disable no-plusplus */

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ReactComponent as CloseButton } from '../../../icons/blue-close-button-icon.svg';
import BlueButton from '../../blueButton';
import FilterButton from '../../FilterButton';
import RedButton from '../../redButton';
import { Container, PopUp } from './styles';
import api from '../../../services/api';
import Input from '../../Form/input';
import Delete from '../../DeletePopUp';
import { useTeams } from '../../../hooks/teams';
import { useError } from '../../../hooks/errors';
import ApprovePopUp from '../../ApprovePopUp';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  rowData?: string[] | any;
};

RegionaisPopUp.defaultProps = {
  rowData: false,
};

export default function RegionaisPopUp({
  isOpen,
  setPopUpState,
  rowData,
}: popUpProp) {
  const { emitError } = useError();
  const { teams, getTeam } = useTeams();
  const [filterValue, setFilterValue] = useState([]);
  const popUpRef = useRef(null);
  const [deletePdv, setDeletePdv] = useState(false);
  const formRef = useRef(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    getTeam();
    let i = 0;
    const getTeams = teams.map(e => ({
      title: e.name,
      value: e.id,
      name: `teams[${i++}]`,
    }));

    setFilterValue(getTeams);
  }, []);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  async function handlePost(data: any) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome da regional é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (!data.teams) {
        await api.post('/regionals', data);
        window.location.reload();
      } else {
        const filterArr = data.teams.filter(a => a);
        data.teams.splice(0, data.teams.length, ...filterArr);

        await api.post('/regionals', data);
        window.location.reload();
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios!');
        setShowConfirmation(false);

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  async function handlePut(data: any) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome da regional é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      // eslint-disable-next-line no-param-reassign
      const pTeams = data?.teams?.filter(tms => tms);
      const parseData = {
        ...data,
        teams: pTeams,
      };

      await api.put(`/regionals/${rowData.id}`, parseData);

      window.location.reload();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios!');
        setShowConfirmation(false);

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  async function handleDelete() {
    try {
      await api.delete(`/regionals/${rowData.id}`);
      toast.success('Regional deletado');
      window.location.reload();
    } catch (error: any) {
      toast.error('Regional referenciado por outra tabela');
    }
  }

  const initialData = {
    name: rowData.name,
    team: rowData.team,
  };

  return (
    <Container>
      <PopUp ref={popUpRef}>
        {deletePdv ? (
          <Delete
            title="Regional"
            isOpen={deletePdv}
            setCustomState={handleDelete}
            setPopUpState={setDeletePdv}
          />
        ) : null}

        <div className="pop-up-header">
          <p>Regionais</p>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>
        <Form
          initialData={rowData ? initialData : null}
          ref={formRef}
          onSubmit={rowData ? handlePut : handlePost}
        >
          <ul>
            <li>
              <label htmlFor="name">
                Nome
                <span style={{ display: 'inline', color: 'red' }}> *</span>
              </label>

              <Input name="name" id="name" placeholder="Nome" />
            </li>
            <li>
              <label htmlFor="equipe">Equipes</label>
            </li>
          </ul>
          <ul className="filter-section">
            {filterValue.map(e => (
              <li>
                <FilterButton
                  isTrue={!!rowData?.teams?.find(team => team.id === e?.value)}
                  name={e.name}
                  title={e.title}
                  value={e.value}
                />
              </li>
            ))}
          </ul>

          {!rowData ? (
            <div className="button-field">
              <BlueButton
                title="Salvar"
                setCustomState={setShowConfirmation}
                customState={showConfirmation}
              />

              <button
                type="button"
                className="cancel-button"
                onClick={() => setPopUpState(!isOpen)}
              >
                Cancelar
              </button>
            </div>
          ) : (
            <div className="edit-pdv">
              <RedButton
                title="Apagar"
                setCustomState={setDeletePdv}
                customState={deletePdv}
              />
              <BlueButton
                title="Editar"
                setCustomState={setShowConfirmation}
                customState={showConfirmation}
              />
            </div>
          )}

          {showConfirmation ? (
            <ApprovePopUp
              sampleText="Adicionar Regional?"
              isOpen={showConfirmation}
              setPopUpState={setShowConfirmation}
            />
          ) : null}
        </Form>
      </PopUp>
    </Container>
  );
}
