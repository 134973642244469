/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  .header-container {
    h5 {
      text-align: center;
      font-weight: 600;
    }
    .header-controllers {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  .dados-pdv,
  .dados-chamado,
  .dados-product {
    width: 100%;
    background: white;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 20px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);

    .table-header {
      padding: 8.5px 16px;
      background: #f7fdf8;
    }

    ul {
      padding: 0px 16px;

      li {
        padding: 11px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h6 {
          color: #66686d;
          &:nth-child(odd) {
            font-weight: 600;
          }
        }

        & + li {
          border: solid #f3f3f0;
          border-width: 1px 0px 1px 0px;
        }
      }
    }
  }

  .buttons-section-mobile {
    left: 0;
    right: 0;
    position: fixed;
    padding: 0px 20px;
    bottom: 80px;
    z-index: 999999;

    .continue-progress {
      display: flex;
      gap: 14px;
      justify-content: space-between;
    }
  }

  .menu-add-product {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    position: fixed;
    left: 0;
    bottom: 0;
    gap: 30px;
    z-index: 999999;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 64px;
      left: 0;
      bottom: 0;
      background: white;
      /* z-index: 1; */
    }
    @media screen and (min-width: 880px) {
      display: none !important;
    }

    .new-product {
      width: 90px !important;
      filter: drop-shadow(0px 5.21739px 10.4348px rgba(0, 0, 0, 0.15));
      margin-bottom: 2px;
      color: var(--lightBlue) !important;
    }
    .footer-button {
      width: 52px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--mediumGrey);
      font-size: var(--smallSize);
      font-weight: var(--smallWeight);
      letter-spacing: var(--smallLetter);
      line-height: var(--smallLine);
      z-index: 2;
      text-decoration: none;

      svg {
        width: 24px;
        margin-top: 2px;
      }

      span {
        width: 60px;
        height: 60px;

        background: var(--lightBlue);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 99px;
        font-size: 40px;
      }
    }
  }

  .add-file-field {
    margin-top: 20px;
    flex-basis: 200px;
    height: 43px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background: var(--lightBlue);

    p {
      color: white;
      font-size: 12px;
      font-weight: 600;
      margin: 0;
      cursor: pointer;
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
  #file-section {
    padding: 8px 20px 20px 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    overflow: auto !important;

    .file-field {
      width: 100%;
      max-width: 200px;
      height: 50px;
      background-size: cover;
      background-position: center;
      position: relative;
      border-radius: 12px;
      border: 1px solid #abccf7;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;

      .erase-img {
        position: absolute;
        top: 6px;
        right: 6px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.6);
        font-weight: 800;
        font-family: 'Nunito', sans-serif;
        color: white;
        padding: 4px 10px;

        &:hover {
          background: rgba(0, 0, 0, 0.75);
        }
      }

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      span {
        font-size: 12px;
        padding: 5px;
        overflow: hidden;
        width: calc(100% - 30px);
      }
    }
  }
`;
