import React from 'react';

import { RegionalProvider } from './regional';
import { ChannelProvider } from './channels';
import { ContractProvider } from './contracts';
import { NetworkProvider } from './Network';
import { UsersProvider } from './users';
import { TeamProvider } from './teams';
import { RolesProvider } from './roles';
import { OccurrencesProvider } from './occurrences';
import { CategoriesProvider } from './categories';
import { MotivationsProvider } from './motivations';
import { PDVsProvider } from './pdvs';

const HookProviders: React.FC = ({ children }) => (
  <CategoriesProvider>
    <MotivationsProvider>
      <OccurrencesProvider>
        <TeamProvider>
          <RolesProvider>
            <UsersProvider>
              <RegionalProvider>
                <ChannelProvider>
                  <ContractProvider>
                    <NetworkProvider>
                      <PDVsProvider>{children}</PDVsProvider>
                    </NetworkProvider>
                  </ContractProvider>
                </ChannelProvider>
              </RegionalProvider>
            </UsersProvider>
          </RolesProvider>
        </TeamProvider>
      </OccurrencesProvider>
    </MotivationsProvider>
  </CategoriesProvider>
);

export default HookProviders;
