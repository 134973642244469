import React, { useState, useEffect, useRef } from 'react';
import { Container, PopUp } from './styles';
import { ReactComponent as CloseButton } from '../../icons/blue-close-button-icon.svg';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  rowData?: string[] | any;
};

Fluxo.defaultProps = {
  rowData: false,
};

export default function Fluxo({ isOpen, setPopUpState, rowData }: popUpProp) {
  const popUpRef = useRef(null);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  return (
    <Container>
      <PopUp ref={popUpRef}>
        <div className="pop-up-header">
          <h5>Fluxo de aprovação</h5>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>

        <ul>
          <li className="approval-row">
            <h6 className="title">Devoluções abaixo de R$400,00</h6>
            <span className="line" />
            <div className="person-row">
              <div className="person">
                <h6 style={{ paddingLeft: '20px' }}>Supervisor</h6>
              </div>
              <div className="person">
                <h6>Gerente distrital</h6>
              </div>
            </div>
          </li>
          <li className="approval-row">
            <h6 className="title">Devoluções entre R$400,01 e R$ 5.000,00</h6>
            <span className="line" />
            <div className="person-row">
              <div className="person">
                <h6 style={{ paddingLeft: '20px' }}>Supervisor</h6>
              </div>
              <div className="person">
                <h6>Gerente distrital</h6>
              </div>
            </div>
          </li>
          <li className="approval-row">
            <h6 className="title">Devoluções acima de R$ 5.000,01</h6>
            <span className="line">
              <span />
            </span>
            <div className="person-row">
              <div className="person">
                <h6 style={{ paddingLeft: '20px' }}>Supervisor</h6>
              </div>
              <div className="person">
                <h6>Gerente distrital</h6>
              </div>
              <div className="person">
                <h6>Gerente regional</h6>
              </div>
            </div>
          </li>
        </ul>
      </PopUp>
    </Container>
  );
}
