import React, { useState, useRef, useEffect } from 'react';
import { ValueType } from 'rsuite/lib/DateRangePicker';
import { toast } from 'react-toastify';
import { ReactComponent as CloseButton } from '../../icons/blue-close-button-icon.svg';
import { Container, PopUp, Button } from './styles';
import DatePicker from '../DatePicker';

type popUpProp = {
  setPopUpState?: any;
  isOpen?: boolean;
  handleChange(e: ValueType): void;
  handleClearFilters(e: ValueType): void;
  date: ValueType;
};

PDVFilter.defaultProps = {
  setPopUpState: false,
  isOpen: true,
};

export default function PDVFilter({
  setPopUpState,
  isOpen,
  handleChange,
  handleClearFilters,
  date,
}: popUpProp) {
  const [localDate, setLocalDate] = useState<ValueType>();
  const modalOutsideRef = useRef(null);

  const handleClick = (e: MouseEvent) => {
    if (e.target === modalOutsideRef?.current) setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  return (
    <Container ref={modalOutsideRef}>
      <PopUp>
        <div className="pop-up-header">
          <h5>Filtros</h5>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>

        <div className="filter-section">
          <div className="select-field" style={{ marginTop: '20px' }}>
            <DatePicker
              style={{ width: '100%', padding: '0 30px' }}
              onChange={e => setLocalDate(e)}
              value={date?.length ? date : localDate}
              onClean={() => handleChange(undefined)}
            />
          </div>
        </div>

        <div className="buttons-section">
          <Button type="button" onClick={() => handleChange(localDate)}>
            Filtrar
          </Button>
          {(date?.length || localDate) && (
            <button
              type="button"
              onClick={() => {
                toast.info('Os filtros foram limpos');
                handleChange(undefined);
                handleClearFilters(undefined);
              }}
            >
              Limpar Filtros
            </button>
          )}
        </div>
      </PopUp>
    </Container>
  );
}
