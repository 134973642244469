/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { useError } from '../../../../../hooks/errors';
import AddProductPopUp from '../addProductPopUp';
import { Container } from './styles';
import Fluxo from '../../../../FluxoDeAprovacao';
import Table from '../../../../Table';
import GreenButton from '../../../../greenButton';
import RedButton from '../../../../redButton';
import api from '../../../../../services/api';
import { ReactComponent as OcorrenciaIcon } from '../../../../../icons/ocorrencia-icon.svg';
import { ReactComponent as Tag } from '../../../../../icons/tag-icon.svg';
import { ReactComponent as MessageIcon } from '../../../../../icons/message-icon-mobile.svg';
import ApprovePopUp from '../../../../ApprovePopUp';
import CancelPopUp from '../../../../CancelPopUp';

type ProductdProp = {
  pdvData: string[] | any;
  setPopUpState: any;
  isOpen: boolean;
  pdvId: string;
};

export default function AddProduct({
  pdvData,
  setPopUpState,
  isOpen,
  pdvId,
}: ProductdProp) {
  const [fluxoDeAprovacao, setFluxoDeAprovacao] = useState(false);
  const [showInfo, setShowInfo] = useState([]);
  const [showProducts, setShowProducts] = useState([]);
  const [howMuch, setHowMuch] = useState(0);
  const [addProduct, setAddProduct] = useState(false);
  const [listData, setListData] = useState([]);
  const { emitError } = useError();
  const [cancelAction, setCancelAction] = useState(false);
  const [addOccurrence, setAddOccurrence] = useState(false);
  const [newTableData, setNewTableData] = useState([]);
  const [selectCategory, setSelectCategory] = useState<any>();

  const [fileList, setFileList] = useState<
    { id: string; name: string; url: string }[]
  >([]);

  const formRef = useRef(null);

  async function handleSubmit(data) {
    delete data.sku;
    delete data.category;

    if (fileList.length === 0) {
      alert('Você precisa adicionar um arquivo');
      return;
    }

    try {
      await api.post('/occurrences', {
        ...data,
        files: fileList.map(file => file.url) || [],
        products: listData.filter(e => e),
      });
      window.location.reload();
    } catch (error: any) {
      emitError(error);
    }
  }

  function handleInfo(data) {
    setAddProduct(!addProduct);
    setHowMuch(howMuch + 1);
    const array = data.products;

    listData.push(...array);
    const newData = [
      {
        title: 'Categoria',
        content: selectCategory,
      },

      {
        title: 'Motivo devolução',
        content: data.products[howMuch].justification,
      },

      {
        title: 'Quantidade',
        content: data.products[howMuch].quantity,
      },
    ];

    showProducts.push(...array);

    setShowInfo(newData);

    const newerData = listData
      .filter(e => e)
      .map(Products => ({
        motivo: Products.justification,
        // valorTotal: Products.sell_price * Products.quantity,
        valorTotal: (Products?.sell_price * Products?.quantity)?.toLocaleString(
          'pt-br',
          {
            style: 'currency',
            currency: 'BRL',
          },
        ),
      }));

    setNewTableData([...newTableData, newerData[newerData.length - 1]]);
    formRef?.current.reset();
  }

  const tableColumn = [
    {
      title: 'Motivo',
      field: 'motivo',
    },

    {
      title: 'Valor Total',
      field: 'valorTotal',
    },
  ];

  async function handleDelete(id) {
    try {
      await api.delete(`/upload/${id}`);
      setFileList(list => list.filter(item => item.id !== id));
    } catch (error: any) {
      emitError(error);
    }
  }

  async function setNewFile(element) {
    const file = element.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post(`/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setFileList(list => [
        ...list,
        {
          id: response.data.key,
          name: response.data.name,
          url: response.data.url,
        },
      ]);
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  return (
    <Container>
      {fluxoDeAprovacao ? (
        <Fluxo isOpen={fluxoDeAprovacao} setPopUpState={setFluxoDeAprovacao} />
      ) : null}

      {cancelAction ? (
        <CancelPopUp
          sampleText="Cancelar abertura de Ocorrência?"
          isOpen={cancelAction}
          setCustomState={setPopUpState}
          customState={isOpen}
          setPopUpState={setCancelAction}
        />
      ) : null}

      <div className="header-container">
        <div className="header-controllers">
          <button type="button" onClick={() => setPopUpState(false)}>
            Cancelar
          </button>
          <button
            type="button"
            onClick={() => setFluxoDeAprovacao(!fluxoDeAprovacao)}
          >
            Fluxo de aprovação
          </button>
        </div>

        <h5> Chamada 000</h5>
      </div>

      <div className="dados-pdv">
        <div className="table-header">
          <p>Dados do cliente</p>
        </div>

        <ul>
          {pdvData.map(e => (
            <li>
              <h6>{e.titulo1}</h6> <h6>{e.conteudo}</h6>
            </li>
          ))}
        </ul>
      </div>
      {selectCategory && (
        <div className="add-file-field">
          <input
            type="file"
            accept="application/pdf"
            onInput={element => setNewFile(element)}
          />
          <p>Inserir arquivo</p>
        </div>
      )}
      <div id="file-section">
        {fileList.map(pic => (
          <div className="file-field">
            <button
              type="button"
              className="erase-img"
              onClick={() => handleDelete(pic.id)}
            >
              X
            </button>
            <span>{pic.name}</span>
          </div>
        ))}
        <span />
      </div>

      {showInfo.length > 0 ? (
        <>
          <div className="dados-chamado">
            <div className="table-header">
              <p>Dados do chamado</p>
            </div>

            <ul>
              {showInfo.map(e => (
                <li>
                  <h6>{e.title}</h6> <h6>{e.content}</h6>
                </li>
              ))}
            </ul>
          </div>
          <div className="dados-product">
            <div className="table-header">
              <p>Dados do Produto</p>
            </div>

            <Table title="" column={tableColumn} data={newTableData} />
          </div>{' '}
        </>
      ) : null}
      <Form ref={formRef} onSubmit={addProduct ? handleInfo : handleSubmit}>
        <AddProductPopUp
          pdvId={pdvId}
          isOpen={addProduct}
          setPopUpState={setAddProduct}
          finalData={showInfo}
          howMuch={howMuch}
          selectCategory={selectCategory}
          setSelectCategory={setSelectCategory}
        />

        <div className="buttons-section-mobile">
          {showInfo.length > 0 ? (
            <div className="continue-progress">
              <RedButton
                title="Cancelar"
                setCustomState={setCancelAction}
                customState={cancelAction}
              />
              <GreenButton
                title="Salvar"
                setCustomState={setAddOccurrence}
                customState={addOccurrence}
              />
            </div>
          ) : null}
        </div>

        {addOccurrence ? (
          <ApprovePopUp
            sampleText="Adicionar Ocorrência?"
            isOpen={addOccurrence}
            setPopUpState={setAddOccurrence}
          />
        ) : null}
      </Form>

      <div className="menu-add-product">
        <button
          type="button"
          className="footer-button"
          onClick={() => setPopUpState(false)}
        >
          <OcorrenciaIcon />
          Ocorrências
        </button>

        <button
          type="button"
          className="new-product footer-button"
          onClick={() => setAddProduct(!addProduct)}
        >
          <span>
            <Tag />
          </span>
          Adicionar Produto
        </button>
        <Link to="/message" type="button" className="footer-button">
          <MessageIcon />
          Mensagens
        </Link>
      </div>
    </Container>
  );
}
