/* eslint-disable consistent-return */
import React, { createContext, useContext, useState } from 'react';
import api from '../services/api';
import { Role } from '../models';
import { useError } from './errors';

export interface RolesContextData {
  roles: Role[] | null;
  getRoles(): Promise<void>;
  isLoading: boolean;
}
export const RolesContext = createContext<RolesContextData>(
  {} as RolesContextData,
);
export const RolesProvider: React.FC = ({ children }: any) => {
  const [roles, setRoles] = useState<Role[]>();
  const [loading, setLoading] = useState(false);
  const { emitError } = useError();

  const getRoles = async () => {
    try {
      setLoading(true);

      const response = await api.get('/roles');
      setRoles(response.data);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      // emitError(error);
    }
  };

  return (
    <RolesContext.Provider
      value={{
        isLoading: loading,
        roles,
        getRoles,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

export function useRoles(): RolesContextData {
  const context = useContext(RolesContext);
  return context;
}
