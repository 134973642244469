import React from 'react';
import MaterialTable from 'material-table';
import loadingGif from '../../img/loading.gif';

import { Container } from './styles';

type tableProps = {
  title?: string;
  column: any;
  data: any;
  searchBar?: boolean;
  options?: any | string[];
  isLoading?: boolean;
};

Table.defaultProps = {
  title: '',
  searchBar: false,
  options: {},
};

export default function Table({
  title,
  column,
  data,
  searchBar,
  options,
  isLoading,
}: tableProps) {
  return (
    <Container>
      {isLoading ? (
        <div className="carregando">
          <div className="carregando2" />
          <img src={loadingGif} alt="" />
        </div>
      ) : null}
      <MaterialTable
        title={title}
        columns={column}
        data={data}
        options={{
          ...options,
          search: searchBar,
        }}
      />
    </Container>
  );
}
