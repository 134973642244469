/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import Textarea from '../../../../Form/textarea';
import { ReactComponent as CloseButton } from '../../../../../icons/blue-close-button-icon.svg';
import { Container, PopUp } from './styles';
import { useError } from '../../../../../hooks/errors';
import RedButton from '../../../../redButton';

import api from '../../../../../services/api';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  rowData?: string[] | any;
};

ReprovePopUp.defaultProps = {
  rowData: [],
};

export default function ReprovePopUp({
  isOpen,
  setPopUpState,
  rowData,
}: popUpProp) {
  const popUpRef = useRef(null);
  const { emitError } = useError();

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  async function ReproveOccurrence() {
    try {
      await api.post(`/occurrences/aprove`, {
        occurrence_id: rowData.id,
        approve: false,
      });
      toast.success('Ocorrência reprovada!');
      window.location.reload();
    } catch (error: any) {
      toast.success(error.message);
      emitError(error);
    }
  }

  return (
    <Container>
      <PopUp ref={popUpRef}>
        <div className="pop-up-header">
          <h5>Reprovar Chamado </h5>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>
        <div className="description-section">
          <Form onSubmit={ReproveOccurrence}>
            <h6>Motivo</h6>
            <Textarea name="description" />

            <div className="buttons-section">
              <RedButton title="Reprovar" typeOf="submit" />

              <button type="submit" onClick={() => setPopUpState(false)}>
                Cancelar
              </button>
            </div>
          </Form>
        </div>
      </PopUp>
    </Container>
  );
}
