import React from 'react';
import styled, { keyframes } from 'styled-components';
import GMLogo from '../../icons/LOGO-GM-White.svg';

export default function Loading() {
  const loadingAnim = keyframes`
0%{
  opacity:0;
}


50% {

  opacity:1;
}


100%{ opacity:0;}

`;

  const Container = styled.div`
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;

    img {
      width: 200px;
      animation: 2s ${loadingAnim} infinite ease-out;
    }
  `;

  return (
    <Container>
      <img src={GMLogo} alt="Genereal Mills Logo" />
    </Container>
  );
}
