/* eslint-disable consistent-return */
import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import { Contract } from '../models';
import { useError } from './errors';

export interface ContractContextData {
  contracts: Contract[] | null;
  getContract(): Promise<void>;
  isLoading: boolean;
}
export const ContractContext = createContext<ContractContextData>(
  {} as ContractContextData,
);
export const ContractProvider: React.FC = ({ children }: any) => {
  const { emitError } = useError();
  const [contracts, setContracts] = useState<Contract[]>();
  const [loading, setLoading] = useState(false);

  const getContract = async () => {
    try {
      setLoading(true);

      const response = await api.get('/contracts');
      setContracts(response.data);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      emitError(error);
    }
  };

  return (
    <ContractContext.Provider
      value={{
        isLoading: loading,
        contracts,
        getContract,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};

export function useContracts(): ContractContextData {
  const context = useContext(ContractContext);
  return context;
}
