/* eslint-disable no-useless-return */
import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as PDVLogo } from '../../../icons/pdv-logo-white.svg';
import { ReactComponent as GMLogo } from '../../../icons/LOGO-GM-White.svg';
import { ReactComponent as LeaveIcon } from '../../../icons/leave-icon.svg';

import PerfilPic from '../../../img/perfilPic.webp';
import { Header, SignOut } from './styles';
import { useAuth } from '../../../hooks/auth';

export default function HeaderMobile() {
  const { user, signOut } = useAuth();
  const [isSigned, setIsSigned] = useState(false);

  const popUpRef = useRef(null);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setIsSigned(false);
  };

  useEffect(() => {
    if (isSigned) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isSigned]);

  useEffect(() => {
    if (!user) return;
  }, [user]);

  return (
    <Header>
      <PDVLogo />
      <GMLogo />
      <button
        type="button"
        className="perfil-pic-button"
        onClick={() => setIsSigned(!isSigned)}
      >
        <img
          src={user?.details?.avatar || PerfilPic}
          alt="foto do perfil"
          className="perfil-pic"
        />
      </button>
      {isSigned ? (
        <SignOut ref={popUpRef}>
          <button type="button" onClick={() => signOut()}>
            <LeaveIcon />
            <h6>Logout</h6>
          </button>
        </SignOut>
      ) : null}
    </Header>
  );
}
