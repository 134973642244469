/* eslint-disable consistent-return */
import React, { createContext, useContext, useState } from 'react';
import api from '../services/api';
import { useError } from './errors';

export interface PDVsContextData {
  pdvs: any[] | null;
  setPDVs: any;
  getPDVs(): Promise<void>;
  getNewPage(): Promise<void>;
  isLoading: boolean;
}
export const PDVsContext = createContext<PDVsContextData>(
  {} as PDVsContextData,
);

export const PDVsProvider: React.FC = ({ children }: any) => {
  const [pdvs, setPDVs] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const { emitError } = useError();

  const getPDVs = async () => {
    try {
      setLoading(true);

      const response = await api.get('/pdvs');
      setPDVs(response.data);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      emitError(error);
    }
  };

  const filterDuplicates = (list: any[]) => {
    const newPdvs: any[] = [];
    // eslint-disable-next-line array-callback-return
    list?.map(pdv => {
      const alreadyExists = pdvs.find(it => it.id === pdv.id);
      if (!alreadyExists) newPdvs.push(pdv);
    });

    return newPdvs;
  };

  const getNewPage = async () => {
    const newPage = page + 1;

    const response = await api.get('/pdvs', {
      params: {
        page: newPage,
      },
    });

    const newPdvs = filterDuplicates(response.data);
    setPDVs(old => [...old, ...newPdvs]);
    setPage(newPage);
  };

  return (
    <PDVsContext.Provider
      value={{
        isLoading: loading,
        pdvs,
        getPDVs,
        getNewPage,
        setPDVs,
      }}
    >
      {children}
    </PDVsContext.Provider>
  );
};

export function usePDVs(): PDVsContextData {
  const context = useContext(PDVsContext);
  return context;
}
