import React from 'react';
import Table from '../../../../components/Table';
import loadingGif from '../../../../img/loading.gif';
import PrecoPadrao from './price';
import { Container } from './styles';

interface Props {
  fullData: any;
  isLoading: boolean;
}

export default function DevolucoesPorProduto({ fullData, isLoading }: Props) {
  const column = [
    {
      title: 'Produto',
      field: 'name',
    },

    {
      title: 'Categoria',
      field: 'category',
    },

    {
      title: 'Total de PDVs  com devolução',
      field: 'pdvs',
    },

    {
      title: 'Valor Unitário',
      field: 'mediumSellPrice',
      render: rowData => <PrecoPadrao price={rowData.mediumSellPrice} />,
    },

    {
      title: '% Devolução',
      field: 'percentApproval',
      render: rowData => rowData.percentApproval.toFixed(2),
    },
  ];

  return (
    <Container>
      {isLoading ? (
        <div className="carregando">
          <div className="carregando2" />
          <img src={loadingGif} alt="" />
        </div>
      ) : null}
      <Table
        title="Devoluções por Produto"
        column={column}
        data={fullData?.occurrenceByProductAudit}
      />
    </Container>
  );
}
