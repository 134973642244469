import styled from 'styled-components';

export const Container = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: calc(9 * 99);
`;

export const PopUp = styled.div`
  width: 100%;
  max-width: 800px;
  min-height: 70px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 30px 30px 36px 30px;

  .pop-up-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding: 0;

    button {
      position: absolute;
      top: 0px;
      right: 0px;

      svg {
        width: 16px;
      }
    }
  }

  .photo-section {
    max-width: 800px;
    overflow-x: auto;
    display: flex;

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: #99a0aa;
      border-radius: 99px;
    }

    &::-webkit-scrollbar {
      height: 10px;

      border-radius: 99px;
    }

    img {
      max-width: 250px;
      height: 250px;
      object-fit: cover;
      background: var(--mediumGrey);

      & + img {
        margin: 0 1em;
      }
    }
  }
`;
