import styled from 'styled-components';
// eslint-disable-next-line import/prefer-default-export
export const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  gap: 30px;
  z-index: 9;
  @media screen and (min-width: 880px) {
    display: none !important;
  }

  .new-ocurrence {
    width: 75px !important;
    filter: drop-shadow(0px 5.21739px 10.4348px rgba(0, 0, 0, 0.15));
    margin-bottom: 2px;
  }
  .footer-button {
    width: 52px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--mediumGrey);
    font-size: var(--smallSize);
    font-weight: var(--smallWeight);
    letter-spacing: var(--smallLetter);
    line-height: var(--smallLine);

    svg {
      width: 24px;
      margin-top: 2px;
    }

    span {
      width: 60px;
      height: 60px;
      background: var(--darkBlue);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 99px;
      font-size: 40px;
    }
  }
`;
