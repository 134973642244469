/* eslint-disable import/extensions */
import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import api from '../services/api';
import { User } from '../models';
import { useError } from './errors';
import { useNotifications } from './notifications';

interface AuthState {
  token: string;
  user: User;
}

export interface AuthContextData {
  signed: boolean;
  loading: boolean;
  user: User | null;
  signIn(email: string, password: string, remember: boolean): Promise<void>;
  signOut(): void;
  updateProfile(user: User): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

export const AuthProvider: React.FC = ({ children }: any) => {
  const { setManualNotification } = useNotifications();
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@general-mills/token');
    const user: User = JSON.parse(
      localStorage.getItem('@general-mills/user') as string,
    );

    setManualNotification(user?.notifications);
    if (user && token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      if (user && !user?.teams?.length && user?.role?.basicRole !== 'ADMIN')
        toast.warn('Seu usuário não está vinculado a um time');
      return { token, user } as AuthState;
    }

    return {} as AuthState;
  });
  const [loading, setLoading] = useState(false);

  const signIn = async (email: string, password: string, remember: boolean) => {
    try {
      setLoading(true);

      const response = await api.post('/sessions', { email, password });

      const { user, token } = response.data as { user: User; token: string };
      api.defaults.headers.Authorization = `Bearer ${token}`;
      if (remember) {
        localStorage.setItem('@general-mills/token', token);
        localStorage.setItem('@general-mills/user', JSON.stringify(user));
      }
      if (user && !user?.teams?.length && user?.role?.basicRole !== 'ADMIN')
        toast.warn('Seu usuário não está vinculado a um time');
      setManualNotification(user?.notifications);
      setData({ token, user });

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message || 'Erro ao efetuar login');
    }
  };

  const signOut = () => {
    localStorage.removeItem('@general-mills/token');
    localStorage.removeItem('@general-mills/user');
    delete api.defaults.headers.Authorization;
    setData({} as AuthState);
  };

  const updateProfile = (user: User) => {
    setData(oldData => ({ ...oldData, user: { ...oldData.user, ...user } }));
    localStorage.setItem(
      '@general-mills/user',
      JSON.stringify({
        ...data.user,
        ...user,
      }),
    );
  };

  return (
    <AuthContext.Provider
      value={{
        signed: !!data.user,
        loading,
        user: data.user,
        signIn,
        signOut,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  return context;
}
