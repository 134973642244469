import React, { useRef, useEffect } from 'react';
import { ReactComponent as CloseButton } from '../../../../../icons/blue-close-button-icon.svg';
import { Container, PopUp } from './styles';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  content: string[] | any;
};

export default function Contract({
  isOpen,
  setPopUpState,
  content,
}: popUpProp) {
  const popUpRef = useRef(null);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  return (
    <Container>
      <PopUp ref={popUpRef}>
        <div className="pop-up-header">
          <h5> {content ? content[0].name : 'Contrato'}</h5>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>

        <div className="contract-body">{content[0]?.contract}</div>
      </PopUp>
    </Container>
  );
}
