import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as NotificationIcon } from '../../icons/notification-icon.svg';
import { ReactComponent as LeaveIcon } from '../../icons/leave-icon.svg';
import PerfilPic from '../../img/perfilPic.webp';
import HeaderMobile from './mobile';
import { Header, NotificationContainer } from './styles';
import UserPopUp from '../userPopUps/User';
import { useAuth } from '../../hooks/auth';
import { useNotifications } from '../../hooks/notifications';
import { User } from '../../models';

export default function HeaderGM() {
  const [editPerfil, setEditPerfil] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const { user, signOut } = useAuth();
  const { notifications } = useNotifications();

  useEffect(() => {
    if (!user) return;
    setUserInfo(user);
  }, [user]);

  useEffect(() => {
    setEditPerfil(!editPerfil);
  }, [userDetails]);

  const setUserInfo = (userInfo: User) => {
    const getUserDetails = {
      id: userInfo.id,
      name: userInfo.name,
      email: userInfo.email,
      username: userInfo.username,
      address: userInfo.details.address,
      number: userInfo.details.number,
      complement: userInfo.details.complement,
      district: userInfo.details.district,
      city: userInfo.details.city,
      state: userInfo.details.state,
      avatar: userInfo.details.avatar,
    };

    setUserDetails(getUserDetails);
  };

  return (
    <Header>
      {editPerfil ? (
        <UserPopUp
          isOpen={editPerfil}
          hasRole={false}
          setPopUpState={setEditPerfil}
          rowData={userDetails}
          isEditProfile
        />
      ) : null}
      <HeaderMobile />
      <div className="user-information">
        <div className="user-perfil-pic">
          <img
            src={user?.details?.avatar || PerfilPic}
            style={{ background: 'none' }}
            alt="foto do perfil"
          />
        </div>

        <div className="user-name">
          <p> {user.name}</p>
          <button type="button" onClick={() => setUserInfo(user)}>
            Editar Perfil
          </button>
        </div>
      </div>
      <div className="user-controllers">
        <NotificationContainer
          notifications={
            notifications?.filter(noti => noti.read === false)?.length
          }
        >
          <Link to="/notificacoes">
            <NotificationIcon />
            <h6>Notificações</h6>
          </Link>
        </NotificationContainer>

        <button type="button" onClick={() => signOut()}>
          <LeaveIcon />
          <h6>Logout</h6>
        </button>
      </div>
    </Header>
  );
}
