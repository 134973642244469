import styled from 'styled-components';

export const Container = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: calc(9 * 99);
`;

export const PopUp = styled.div`
  width: 100%;
  max-width: 467px;
  min-height: 434px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 14px 25px;

  h6 {
    margin: 20px 0;
    color: var(--pdvNeutro);
    font-weight: 600 !important;
  }

  .pop-up-header {
    width: 100%;
    height: 36px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding-left: 24px;

    button {
      position: absolute;
      top: 10px;
      right: 0px;

      svg {
        width: 16px;
      }
    }
  }
`;
