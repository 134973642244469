/* eslint-disable react/button-has-type */
import React from 'react';

import { Container } from './styles';

interface Props {
  title?: string;
  customState?: any;
  setCustomState?: any;
  typeOf?: any;
  aproved?: boolean;
  [x: string]: any;
}

GreenButton.defaultProps = {
  title: 'Salvar',
  customState: false,
  setCustomState: false,
  typeOf: 'button',
  aproved: false,
};

type ButtonProps = JSX.IntrinsicElements['button'] & Props;
export default function GreenButton({
  title,
  customState,
  setCustomState,
  typeOf,
  aproved,
  ...rest
}: ButtonProps) {
  function customFunction() {
    if (typeof customState === 'boolean' && setCustomState) {
      setCustomState(!customState);
    } else if (
      (typeof customState === 'number' || typeof customState === 'string') &&
      setCustomState
    ) {
      setCustomState(customState);
    } else if (!customState && setCustomState) {
      setCustomState();
    }
  }

  return (
    <Container aproved={aproved}>
      <button type={typeOf} onClick={customFunction} {...rest}>
        {title}
      </button>
    </Container>
  );
}
