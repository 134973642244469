/* eslint-disable no-param-reassign */

import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ApprovePopUp from '../../ApprovePopUp';

import { ReactComponent as CloseButton } from '../../../icons/blue-close-button-icon.svg';
import BlueButton from '../../blueButton';
import api from '../../../services/api';
import RedButton from '../../redButton';
import { Container, PopUp } from './styles';
import Input from '../../Form/input';
import Select from '../../Form/select';
import Photo from '../../Form/photo';
import { useAuth } from '../../../hooks/auth';
import Delete from '../../DeletePopUp';
import { useError } from '../../../hooks/errors';
import { useRoles } from '../../../hooks/roles';
import { useTeams } from '../../../hooks/teams';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  rowData?: string[] | any;
  hasRole?: boolean;
  isEditProfile?: boolean;
};

UserPopUp.defaultProps = {
  rowData: false,
  hasRole: true,
};

export default function UserPopUp({
  isOpen,
  setPopUpState,
  hasRole,
  rowData,
  isEditProfile,
}: popUpProp) {
  const { emitError } = useError();
  const { updateProfile } = useAuth();
  const [steps, setSteps] = useState('info');
  const [fieldPosition, setFieldPosition] = useState(1);
  const popUpRef = useRef(null);
  const formRef = useRef(null);
  const { roles, getRoles } = useRoles();
  const { teams, getTeam } = useTeams();
  const [editFields, setEditFields] = useState([]);
  const [deletePdv, setDeletePdv] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!roles) getRoles();
    if (!teams) getTeam();
  }, []);

  useEffect(() => {
    if (hasRole === false) {
      const newEditFields = [
        {
          title: 'Nome Usuário',
          name: 'name',
          type: 'input',
        },
        {
          title: 'Email',
          name: 'email',
          type: 'email',
        },
        {
          title: 'Usuário para Login',
          name: 'username',
          type: 'input',
        },

        {
          title: 'Senha',
          name: 'password',
          type: 'password',
        },
        {
          title: 'Confirmar Senha',
          name: 'confirmPassword',
          type: 'password',
        },
        {
          optValue: true,
          title: 'Time',
          type: 'select',
          name: 'team_id',
          options: teams,
        },
      ] as any;

      setEditFields(newEditFields);
    } else {
      const newEditFields = [
        {
          title: 'Nome Usuário',
          name: 'name',
          type: 'input',
        },
        {
          title: 'Email',
          name: 'email',
          type: 'email',
        },
        {
          title: 'Usuário para Login',
          name: 'username',
          type: 'input',
        },

        {
          optValue: true,
          title: 'Cargo',
          type: 'select',
          name: 'role_id',
          value: rowData?.role_id || null,
          options: roles,
        },

        {
          title: 'Senha',
          name: 'password',
          type: 'password',
        },
        {
          title: 'Confirmar Senha',
          name: 'confirmPassword',
          type: 'password',
        },
        {
          optValue: true,
          title: 'Time',
          type: 'select',
          name: 'team_id',
          options: teams,
        },
      ] as any;

      setEditFields(newEditFields);
    }
  }, [rowData]);

  const initialData = {
    name: rowData.name,
    email: rowData.email,
    username: rowData.username,
    address: {
      address: rowData.address,
      complement: rowData.complement,
      state: rowData.state,
      number: rowData.number,
      city: rowData.city,
      district: rowData.district,
    },
  };

  const infoFields = [
    {
      title: 'Nome Usuário',
      name: 'name',
      type: 'input',
      isRequired: true,
    },
    {
      title: 'Email',
      name: 'email',
      type: 'email',
      isRequired: true,
    },
    {
      title: 'Usuário para Login',
      name: 'username',
      type: 'input',
      isRequired: true,
    },
    {
      optValue: true,
      title: 'Cargo',
      type: 'select',
      name: 'role_id',
      options: roles,
      isRequired: true,
    },
    {
      title: 'Senha',
      name: 'password',
      type: 'password',
      isRequired: true,
    },
    {
      title: 'Confirmar Senha',
      name: 'confirmPassword',
      type: 'password',
      isRequired: true,
    },
  ] as any;

  const endFields = [
    {
      title: 'Endereço',
      name: 'address.address',
      type: 'input',
      placeholder: rowData.end,
      value: rowData?.details?.address,
      isRequired: false,
    },
    {
      title: 'Número',
      name: 'address.number',
      type: 'input',
      value: rowData?.details?.number,
      isRequired: false,
    },
    {
      title: 'Complemento',
      name: 'address.complement',
      type: 'input',
      value: rowData?.details?.complement,
    },
    {
      title: 'Bairro',
      name: 'address.district',
      type: 'input',
      value: rowData?.details?.district,
      isRequired: false,
    },
    {
      title: 'Cidade',
      name: 'address.city',
      type: 'input',
      placeholder: rowData.cidade,
      value: rowData?.details?.city,
      isRequired: false,
    },
    {
      title: 'Estado',
      name: 'address.state',
      type: 'select',
      value: rowData?.details?.state,
      isRequired: false,
      options: [
        'AC',
        'AL',
        'AM',
        'AP',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RO',
        'RS',
        'RR',
        'SC',
        'SE',
        'SP',
        'TO',
      ],
    },
  ] as any;

  async function handlePost(data: any) {
    delete data.confirmPassword;

    try {
      const schema = Yup.object().shape({
        name: Yup.string()
          .min(3, 'Nome, deve ter no minimo 3 caracteres')
          .max(30, 'Nome, deve ter no máximo 30 caracteres')
          .required('O Nome do Usuário é obrigatório'),
        email: Yup.string().required('O E-mail é obrigatório'),
        username: Yup.string()
          .min(3, 'Nome, deve ter no minimo 3 caracteres')
          .max(30, 'Nome, deve ter no máximo 30 caracteres')
          .required('O Login é obrigatório'),
        password: Yup.string()
          .min(6, 'A senha deve ter no mínimo 6 caracteres')
          .required('A Senha é obrigatória'),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'As senhas devem corresponder',
        ),
        avatar: Yup.string(),
        address: Yup.object().shape({
          address: Yup.string(),
          state: Yup.string().max(2, 'Estado, campo máximo de 2 caracteres'),
          number: Yup.string(),
          city: Yup.string(),
          district: Yup.string(),
        }),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      try {
        await api.post('/users', data);
        toast.success('Usuário criado!');
        window.location.reload();
      } catch (error: any) {
        emitError(error);
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios!');
        setSteps('info');
        setShowConfirmation(false);

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  async function handlePut(data: any) {
    delete data.confirmPassword;

    try {
      const schema = Yup.object().shape({
        name: Yup.string()
          .min(3, 'Nome, deve ter no minimo 3 caracteres')
          .max(30, 'Nome, deve ter no máximo 30 caracteres')
          .required('O Nome do Usuário é obrigatório'),
        email: Yup.string().required('O E-mail é obrigatório'),
        username: Yup.string()
          .min(3, 'Login, deve ter no minimo 3 caracteres')
          .max(30, 'Login, deve ter no máximo 30 caracteres')
          .required('O Login é obrigatório'),
        password: Yup.string(),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'As senhas devem corresponder',
        ),
        avatar: Yup.string(),
        address: Yup.object().shape({
          address: Yup.string(),
          state: Yup.string().max(2, 'Estado, campo máximo de 2 caracteres'),
          number: Yup.string(),
          city: Yup.string(),
          district: Yup.string(),
        }),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const editData = Object.entries(data).reduce(
        // eslint-disable-next-line no-return-assign
        (a, [k, v]: any) => (v.length <= 0 ? a : ((a[k] = v), a)),
        {} as any,
      );

      await api.put(
        isEditProfile ? `/users/profile` : `/users/${rowData?.id}`,
        editData,
      );
      const parseData = {
        ...editData,
        details: { ...editData?.address, avatar: editData?.avatar },
      };
      delete parseData.address;
      delete parseData.avatar;

      if (isEditProfile) updateProfile(parseData as any);
      else window.location.reload();

      toast.success('Atualizado com sucesso!');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios!');
        setSteps('info');
        setShowConfirmation(false);

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      } else emitError(error);
    }
  }

  async function handleDelete() {
    try {
      await api.delete(`/users/${rowData.id}`);
      toast.success('Usuário deletado');
      window.location.reload();
    } catch (error: any) {
      toast.error('Usuário referenciado por outra tabela');
    }
  }

  useEffect(() => {
    if (steps === 'info') {
      setFieldPosition(1);
    } else if (steps === 'end') {
      setFieldPosition(2);
    } else if (steps === 'pic') {
      setFieldPosition(3);
    }
  }, [steps]);

  return (
    <Container>
      <PopUp ref={popUpRef} size={steps}>
        {deletePdv ? (
          <Delete
            title="Usuário"
            isOpen={deletePdv}
            setCustomState={handleDelete}
            setPopUpState={setDeletePdv}
          />
        ) : null}

        <div className="pop-up-header">
          <h5> Usuário</h5>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>
        <div className="popUp-controllers">
          <button
            type="button"
            className={steps === 'info' ? 'active' : ''}
            onClick={() => setSteps('info')}
          >
            Informação
          </button>
          <button
            type="button"
            className={steps === 'end' ? 'active' : ''}
            onClick={() => setSteps('end')}
          >
            Endereço
          </button>
          <button
            type="button"
            className={steps === 'pic' ? 'active' : ''}
            onClick={() => setSteps('pic')}
          >
            Foto
          </button>
        </div>

        <div className="conditional-content">
          <Form
            ref={formRef}
            onSubmit={rowData ? handlePut : handlePost}
            initialData={rowData ? initialData : null}
          >
            <div className=" fields-section teste-action">
              <div
                className={
                  fieldPosition === 1
                    ? 'information-section action'
                    : 'information-section'
                }
              >
                <ul>
                  {(rowData ? editFields : infoFields).map(e => (
                    <li>
                      <h6>
                        {e?.title}
                        {e?.isRequired && (
                          <span style={{ display: 'inline', color: 'red' }}>
                            {' '}
                            *
                          </span>
                        )}
                      </h6>
                      {e?.type === 'select' ? (
                        <Select
                          name={e?.name}
                          fields={e?.options}
                          value={e?.value}
                          optValue={e?.optValue}
                        />
                      ) : (
                        <Input type={e.type} name={e.name} value={e?.value} />
                      )}
                    </li>
                  ))}
                </ul>

                {!rowData ? (
                  <div className="step-buttons">
                    {/* <div className="switchers">
   <SwitchButton title="Troca Zero" name="zero" />
 </div> */}
                    <BlueButton
                      setCustomState={setSteps}
                      customState="end"
                      title="Próximo"
                      typeOf="button"
                    />

                    <button
                      type="button"
                      className="cancel-button"
                      onClick={() => setPopUpState(!isOpen)}
                    >
                      Cancelar
                    </button>
                  </div>
                ) : null}
              </div>
              <div
                className={
                  fieldPosition === 2 ? 'end-section action' : 'end-section'
                }
              >
                <ul>
                  {endFields.map(e => (
                    <li>
                      <h6>
                        {e.title}
                        {e?.isRequired && (
                          <span style={{ display: 'inline', color: 'red' }}>
                            {' '}
                            *
                          </span>
                        )}
                      </h6>

                      {e.type === 'select' ? (
                        <Select
                          name={e.name}
                          fields={e.options}
                          value={e?.value}
                        />
                      ) : (
                        <Input
                          type={e.type}
                          name={e.name}
                          value={e?.value}
                          placeholder={e.placeholder}
                        />
                      )}
                    </li>
                  ))}
                </ul>

                {!rowData ? (
                  <div className="step-buttons">
                    <BlueButton
                      setCustomState={setSteps}
                      customState="pic"
                      title="Próximo"
                    />

                    <button
                      type="button"
                      className="cancel-button"
                      onClick={() => setPopUpState(!isOpen)}
                    >
                      Cancelar
                    </button>
                  </div>
                ) : null}
              </div>
              <div
                className={
                  fieldPosition === 3 ? 'pic-section action' : 'pic-section'
                }
              >
                <Photo
                  photoName="avatar"
                  hasDescription={false}
                  name="description"
                  id={rowData.id}
                  currentImage={
                    !hasRole ? rowData?.avatar : rowData?.details?.avatar
                  }
                />
                {!rowData ? (
                  <div className="step-buttons">
                    <BlueButton
                      setCustomState={setShowConfirmation}
                      customState={showConfirmation}
                    />

                    <button
                      type="button"
                      className="cancel-button"
                      onClick={() => setPopUpState(!isOpen)}
                    >
                      Cancelar
                    </button>
                  </div>
                ) : null}
              </div>
              {!rowData ? null : (
                <div className="edit-pdv">
                  <RedButton
                    title="Apagar"
                    setCustomState={setDeletePdv}
                    customState={deletePdv}
                  />
                  <BlueButton
                    title="Editar"
                    setCustomState={setShowConfirmation}
                    customState={showConfirmation}
                  />
                </div>
              )}
            </div>

            {showConfirmation ? (
              <ApprovePopUp
                sampleText="Adicionar Usuário?"
                isOpen={showConfirmation}
                setPopUpState={setShowConfirmation}
              />
            ) : null}
          </Form>
        </div>
      </PopUp>
    </Container>
  );
}
