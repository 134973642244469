/* eslint-disable consistent-return */
import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import { Channel } from '../models';
import { useError } from './errors';

export interface ChannelContextData {
  channel: Channel[] | null;
  getChannel(): Promise<void>;
  isLoading: boolean;
}
export const ChannelContext = createContext<ChannelContextData>(
  {} as ChannelContextData,
);
export const ChannelProvider: React.FC = ({ children }: any) => {
  const [channel, setChannels] = useState<Channel[]>();
  const [loading, setLoading] = useState(false);
  const { emitError } = useError();

  const getChannel = async () => {
    try {
      setLoading(true);

      const response = await api.get('/channels');
      setChannels(response.data);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      emitError(error);
    }
  };

  return (
    <ChannelContext.Provider
      value={{
        isLoading: loading,
        channel,
        getChannel,
      }}
    >
      {children}
    </ChannelContext.Provider>
  );
};

export function useChannel(): ChannelContextData {
  const context = useContext(ChannelContext);
  return context;
}
