/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as CloseButton } from '../../icons/blue-close-button-icon.svg';
import RedButton from '../redButton';
import { PopUp } from './styles';

type popUpProp = {
  setPopUpState: any;
  title: string;
  isOpen: boolean;
  customState?: any;
  setCustomState?: any;
};

Delete.defaultProps = {
  customState: null,
  setCustomState: false,
};

export default function Delete({
  isOpen,
  setPopUpState,
  customState,
  title,
  setCustomState,
}: popUpProp) {
  const popUpRef = useRef(null);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  return (
    <PopUp ref={popUpRef}>
      <div className="pop-up-header">
        <p>Apagar {title} </p>

        <button type="button" onClick={() => setPopUpState(!isOpen)}>
          <CloseButton />
        </button>
      </div>
      <div className="body">
        <p>Tem certeza que deseja apagar?</p>
        <p>Esta ação é irreversível</p>
      </div>

      <div className="button-field">
        <RedButton title="Apagar" setCustomState={setCustomState} />

        <button
          type="button"
          className="cancel-button"
          onClick={() => setPopUpState(!isOpen)}
        >
          Cancelar
        </button>
      </div>
    </PopUp>
  );
}
