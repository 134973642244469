/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { formatISO } from 'date-fns';
import { ValueType } from 'rsuite/lib/DateRangePicker';
import api from '../../services/api';
import { ReactComponent as Dots } from '../../icons/3dots-icon.svg';
import TableWControllers from '../../components/tableWControllers';
import UserPopUp from '../../components/userPopUps/User';
import Filter from '../../components/PDVfilter';
import { Container } from './styles';
import { useError } from '../../hooks/errors';

export default function Usuarios() {
  const { emitError } = useError();
  const [tableData, setTableData] = useState<any[]>();
  const [addUser, setAddUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [addRowData, setAddRowData] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [isFiltering, setIsFiltering] = useState<ValueType>();

  const parseData = (list: any[]): any => {
    const parsed = list?.map((it: any) => ({
      ...it,
      parsedCreateAt: `${new Date(
        it?.create_at,
      ).toLocaleDateString()} às ${new Date(
        it?.create_at,
      ).toLocaleTimeString()} `,
      parsedUpdateAt: `${new Date(
        it?.update_at,
      ).toLocaleDateString()} às ${new Date(
        it?.update_at,
      ).toLocaleTimeString()} `,
    }));

    return parsed;
  };

  const handleFilter = async (e: ValueType) => {
    setIsFiltering(e);
    if (!e) return;
    try {
      const { data: response } = await api.get('/users', {
        params: {
          startAt: formatISO(e[0]),
          endAt: formatISO(new Date(formatISO(e[1])).setHours(23, 59, 59)),
        },
      });

      setFilteredData(response);
    } catch (error) {
      emitError(error);
    }
  };

  useEffect(() => {
    api
      .get('/users')
      .then((res: any) => {
        const parsed = parseData(res.data);
        setTableData(parsed);
      })
      .catch(error => {
        emitError(error);
      });
  }, []);

  const columnUser = [
    { title: 'Nome', field: 'name' },
    { title: 'E-mail', field: 'email' },
    { title: 'Cargo', field: 'role.name' },
    { title: 'Endereço', field: 'details.address' },
    { title: 'Cidade', field: 'details.city' },
    { title: 'Estado', field: 'details.state' },
    { title: 'Criado em', field: 'parsedCreateAt' },
    { title: 'Atualizado em', field: 'parsedUpdateAt' },
  ];

  return (
    <Container>
      <TableWControllers
        importLink="user"
        importModelLink="https://pdv-general-mills.s3.sa-east-1.amazonaws.com/0e3ef2d43255b320f9fe132cf8f8a872-Usu%C3%A1rios.xlsx"
        addButtonText="Adicionar Usuários"
        filter
        filterState={openFilter}
        data={isFiltering ? filteredData : tableData}
        setFilter={setOpenFilter}
        isFiltering={!!isFiltering}
        title="Usuários"
        column={[
          ...columnUser,
          {
            width: '10%',
            cellStyle: {
              textAlign: 'right',
            },

            render: (rowData: any) => (
              <button
                type="button"
                onClick={() => {
                  setAddRowData(rowData);
                  setEditUser(true);
                }}
              >
                <Dots />
              </button>
            ),
          },
        ]}
        addButtonState={addUser}
        addButtonFunction={setAddUser}
      />

      {addUser ? (
        <UserPopUp isOpen={addUser} setPopUpState={setAddUser} />
      ) : null}

      {editUser ? (
        <UserPopUp
          isOpen={editUser}
          setPopUpState={setEditUser}
          rowData={addRowData}
        />
      ) : null}

      {openFilter ? (
        <Filter
          isOpen={openFilter}
          setPopUpState={setOpenFilter}
          handleChange={handleFilter}
          handleClearFilters={setIsFiltering}
          date={isFiltering}
        />
      ) : null}
    </Container>
  );
}
