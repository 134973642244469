/* eslint-disable consistent-return */
import styled, { keyframes, css } from 'styled-components';

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:900px;
  opacity:1;
}

`;

const animationIn = keyframes`

from {
  left:700px;
}

to {
  left:00px;
}

`;
const animationOut = keyframes`

from {
  left:0;
}

to {
  left:-700px;
}

`;

interface PopUpProps {
  isOpen: boolean;
}

export const Container = styled.section<PopUpProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999999999999999;
  transition: 0.3s;

  ${props => {
    if (props.isOpen) {
      return css`
        pointer-events: unset;
        opacity: 1;
      `;
    }
    if (!props.isOpen) {
      return css`
        pointer-events: none;
        opacity: 0;
      `;
    }
  }}
`;

interface Steps {
  stepsProps: string;
}

export const PopUp = styled.div<Steps>`
  width: 90%;
  max-height: 510px;
  overflow: hidden;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 14px 16px 16px 16px;
  position: relative;
  animation: 0.6s ease ${entranceAnim};

  ${props => {
    if (props.stepsProps === 'category') {
      return css`
        .product-category {
          position: relative;
          animation: 0.4s ${animationIn} ease;
          visibility: visible;
        }
        .product-devolution,
        .product-sku,
        .product-confirmation,
        .product-quantity,
        .product-value,
        .product-pic {
          position: absolute;
          animation: 0.4s ${animationOut} ease;
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
      `;
    }
    if (props.stepsProps === 'devolution') {
      return css`
        .product-devolution {
          position: relative;
          animation: 0.4s ${animationIn} ease;
        }

        .product-category,
        .product-sku,
        .product-confirmation,
        .product-quantity,
        .product-value,
        .product-pic {
          position: absolute;
          animation: 0.4s ${animationOut} ease;
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
      `;
    }
    if (props.stepsProps === 'sku') {
      return css`
        .product-sku {
          position: relative;
          animation: 0.4s ${animationIn} ease;
        }

        .product-category,
        .product-devolution,
        .product-confirmation,
        .product-quantity,
        .product-value,
        .product-pic {
          position: absolute;
          animation: 0.4s ${animationOut} ease;
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
      `;
    }
    if (props.stepsProps === 'confirm') {
      return css`
        .product-confirmation {
          position: relative;
          animation: 0.4s ${animationIn} ease;
        }

        .product-category,
        .product-devolution,
        .product-sku,
        .product-quantity,
        .product-value,
        .product-pic {
          position: absolute;
          animation: 0.4s ${animationOut} ease;
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
      `;
    }

    if (props.stepsProps === 'quantity') {
      return css`
        .product-quantity {
          position: relative;
          animation: 0.4s ${animationIn} ease;
        }

        .product-category,
        .product-devolution,
        .product-sku,
        .product-confirmation,
        .product-value,
        .product-pic {
          position: absolute;
          animation: 0.4s ${animationOut} ease;
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
      `;
    }
    if (props.stepsProps === 'value') {
      return css`
        .product-value {
          position: relative;
          animation: 0.4s ${animationIn} ease;
        }

        .product-category,
        .product-devolution,
        .product-sku,
        .product-confirmation,
        .product-quantity,
        .product-pic {
          position: absolute;
          animation: 0.4s ${animationOut} ease;
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
      `;
    }
    if (props.stepsProps === 'pic' || props.stepsProps === 'end') {
      return css`
        .product-pic {
          position: relative;
          animation: 0.4s ${animationIn} ease;
        }

        .product-category,
        .product-devolution,
        .product-sku,
        .product-confirmation,
        .product-quantity,
        .product-value {
          position: absolute;
          animation: 0.4s ${animationOut} ease;
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
      `;
    }
  }};

  .pop-up-header-addProduct {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding-top: 10px;
    margin-bottom: 16px;

    .close-button {
      position: absolute;
      top: 10px;
      right: 0px;

      svg {
        width: 16px;
      }
    }
  }

  .product-steps {
    select,
    input {
      margin: 18px 0 20px 0;
    }

    .lembrete {
      text-align: center;
      color: var(--pdvNeutro);
    }

    .search-button {
      color: #82b2f3;
      padding: 13px 47px;
      border: solid 1px #82b2f3;
      border-radius: 12px;
      display: block;
      margin: 0 auto;
    }
  }

  .product-confirmation {
    ul {
      margin: 20px 0;
      max-height: 252px;
      overflow-y: auto;

      li {
        display: flex;
        align-items: center;
        input {
          width: 20px;
          margin: 0 11px 0 0;
        }

        & + li {
          margin: 20px 0;
        }
      }
    }
  }

  .product-pic {
    > h6 {
      margin-bottom: 8px;
    }
  }

  .final-data {
    ul {
      margin: 20px 0;
      max-height: 102px;
      overflow-y: auto;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;

        h6 {
          color: #66686d;
          &:nth-child(odd) {
            font-weight: 600;
          }
        }

        & + li {
          margin: 20px 0;
        }
      }
    }
  }
`;
