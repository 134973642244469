/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import Loading from '../../components/Loading';
import GMLogo from '../../icons/LOGO-GM-White.svg';
import PDVLogo from '../../icons/pdv-logo-white.svg';
import Input from '../../components/Form/input';
import { MainContainer, Container } from './styles';
import { AuthContext } from '../../hooks/auth';
import { useError } from '../../hooks/errors';
import { ReactComponent as Angle } from '../../icons/angle-right-icon.svg';

export default function LoginPage() {
  const { signIn, loading } = useContext(AuthContext);
  const { emitError } = useError();
  const formRef = useRef(null);
  const [resetPassword, setResetPassword] = useState('login');
  const [userData, setUserData] = useState({ email: '', password: '' });
  const [remember, setRemember] = useState(true);

  async function handleLogin(data: any) {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().required('O Usuário é obrigatório'),
        password: Yup.string()
          .min(6, 'A senha precisa ter ao menos 6 digitos')
          .required('A senha é obrigatória'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      signIn(userData.email, userData.password, remember);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError(error);
        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
      emitError(error);
    }
  }

  return (
    <MainContainer>
      {loading ? <Loading /> : null}
      <Container resetPass={resetPassword}>
        <img src={GMLogo} alt="gm-logo" className="gm-logo" />

        <section className="conditional-content">
          <Form
            ref={formRef}
            onSubmit={handleLogin}
            className={
              resetPassword === 'login' ? 'action login-form' : 'login-form'
            }
          >
            <div className="login-fields">
              <label>
                Nome de Usuário ou E-mail
                <Input
                  type="text"
                  name="email"
                  autocomplete="on"
                  placeholder="Usuário"
                  value={userData.email}
                  onChange={e =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                />
              </label>
              <label>
                Senha
                <Input
                  type="password"
                  name="password"
                  autocomplete="on"
                  placeholder="senha"
                  value={userData.password}
                  onChange={e =>
                    setUserData({ ...userData, password: e.target.value })
                  }
                />
              </label>
            </div>
            <div className="secondary-options">
              <label>
                <input
                  type="checkbox"
                  checked={remember}
                  onChange={e => setRemember(e.target.checked)}
                />
                Lembrar de mim
              </label>

              <Link to="/esqueci-a-senha" className="forgot">
                Esqueci a senha
              </Link>
            </div>

            <button type="submit" className="login-button">
              Login
            </button>
          </Form>
        </section>

        <img src={PDVLogo} alt="pdv-logo" className="pdv-logo" />
      </Container>
    </MainContainer>
  );
}
