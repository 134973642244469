/* eslint-disable consistent-return */
import React, { createContext, useContext, useState } from 'react';

export interface MotivationsContextData {
  motivations: any[] | null;
  getMotivations(category: any): void;
}
export const MotivationsContext = createContext<MotivationsContextData>(
  {} as MotivationsContextData,
);
export const MotivationsProvider: React.FC = ({ children }: any) => {
  const [motivations, setMotivations] = useState<any[]>([]);

  const getMotivations = (category: any) => {
    const c = ['Qualidade', 'Embalagem', 'Validade'];
    if (category === 'HD') c.push('Quebra de Freezer');
    setMotivations(c);
  };

  return (
    <MotivationsContext.Provider
      value={{
        motivations,
        getMotivations,
      }}
    >
      {children}
    </MotivationsContext.Provider>
  );
};

export function useMotivations(): MotivationsContextData {
  const context = useContext(MotivationsContext);
  return context;
}
