/* eslint-disable consistent-return */
import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import { Network } from '../models';
import { useError } from './errors';

export interface NetworkContextData {
  networks: Network[] | null;
  getNetwork(): Promise<void>;
  isLoading: boolean;
}
export const NetworkContext = createContext<NetworkContextData>(
  {} as NetworkContextData,
);
export const NetworkProvider: React.FC = ({ children }: any) => {
  const [networks, setNetworks] = useState<Network[]>();
  const [loading, setLoading] = useState(false);
  const { emitError } = useError();

  const getNetwork = async () => {
    try {
      setLoading(true);

      const response = await api.get('/Networks');
      setNetworks(response.data);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      emitError(error);
    }
  };

  return (
    <NetworkContext.Provider
      value={{
        isLoading: loading,
        networks,
        getNetwork,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};

export function useNetworks(): NetworkContextData {
  const context = useContext(NetworkContext);
  return context;
}
