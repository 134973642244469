/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import PopUpHistory from './components/popUpHisorico';
import { ReactComponent as CloseButton } from '../../../icons/blue-close-button-icon.svg';
import { ReactComponent as Camera } from '../../../icons/camera.svg';
import { ReactComponent as Angle } from '../../../icons/angle-right-icon.svg';
import Table from '../../Table';
import GreenButton from '../../greenButton';
import Input from '../../Form/input';
import { Container, PopUp } from './styles';
import RedButton from '../../redButton';
import api from '../../../services/api';
import Contract from './components/popUpContrato';
import { useError } from '../../../hooks/errors';
import Carrousel from './components/Carrousel';
import Reprove from './components/popUp';
import { useAuth } from '../../../hooks/auth';
import ApprovePopUp from './components/ApprovePopUp';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  rowData?: string[] | any;
  handleChange(e: any): void;
};

PopUpMAddOccurrence.defaultProps = {
  rowData: false,
};

export default function PopUpMAddOccurrence({
  isOpen,
  setPopUpState,
  rowData,
  handleChange,
}: popUpProp) {
  const { user } = useAuth();
  const { emitError } = useError();
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState();
  const popUpRef = useRef(null);
  const [openContract, setOpenContract] = useState(false);
  const [seeHistory, setSeeHistory] = useState(false);
  const [aproveOccurrence, setAproveOcurrence] = useState(false);
  const [reproveOccurrence, setReproveOcurrence] = useState(false);
  const [openCarrousel, setOpenCarrousel] = useState(false);
  const [addRowData, setAddRowData] = useState(false);
  const [approve, setApprove] = useState(false);
  const [showContract, setShowContract] = useState(null);
  const [pdvInfo, setPdvInfo] = useState<any>();

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  useEffect(() => {
    getContract();
  }, []);

  async function getContract() {
    try {
      const contract = await api.get(`/contracts/${rowData.pdv.contract_id}`);
      setShowContract(contract);
    } catch (error: any) {
      emitError(error.message);
    }
  }

  const detalhesDaOcorrencia = [
    {
      titulo: 'Detalhes da ocorrência',
      detalhes: [
        {
          titulo1: 'Status do chamado',
          conteudo: rowData.status.status,
        },
        {
          titulo1: 'Data de abertura',
          conteudo: `${new Date(
            rowData.create_at,
          ).toLocaleDateString()} às ${new Date(
            rowData.create_at,
          ).toLocaleTimeString()} `,
        },
        {
          titulo1: 'Responsável',
          conteudo: rowData.status.next_user.name || '--',
        },
      ],
    },
  ];

  const dadosDoCliente = [
    {
      titulo: 'Dados do Cliente',
      detalhes: [
        {
          titulo1: 'CNPJ',
          conteudo: pdvInfo?.details?.cnpj || rowData.pdv.details.cnpj,
          change: GetPDV,
          input_name: 'cnpj',
        },

        {
          titulo1: 'Nome Fantasia',
          conteudo: pdvInfo?.name || rowData.pdv.name,
          input_name: 'nomeFantasia',
          disabled: true,
        },
        {
          titulo1: 'Contrato',
          conteudo: showContract?.name || 'Diamante',
          input_name: 'contrato',
          disabled: true,
        },
        {
          titulo1: 'Cidade',
          conteudo: pdvInfo?.details?.city || rowData.pdv.details.city,
          input_name: 'details.city',
          disabled: true,
        },
        {
          titulo1: 'Estado',
          conteudo: pdvInfo?.details?.state || rowData.pdv.details.state,
          input_name: 'details.state',
          disabled: true,
        },
        {
          titulo1: 'Endereço',
          conteudo: pdvInfo?.details?.address || rowData.pdv.details.address,
          input_name: 'details.address',
          disabled: true,
        },
      ],
    },
  ];

  const dadosDoChamado = [
    {
      titulo: 'Dados do chamado',
      detalhes: [
        {
          titulo1: 'Tipo de ocorrência',
          conteudo: 'Devolução de produtos impróprios',
          input_name: '',
        },
        {
          titulo1: 'Produtos Reportados',
          input_name: '',
          conteudo: rowData?.details?.length,
        },
        {
          titulo1: 'Categoria',
          conteudo: rowData?.details[0]?.product?.category?.name,
          input_name: '',
        },
        {
          titulo1: 'Arquivos',
          conteudo: '',
          files: rowData?.files || [],
          input_name: '',
        },
      ],
    },
  ];

  const tableColumn = [
    {
      title: 'Produto',
      field: 'produto',
    },

    {
      title: 'Motivo',
      field: 'motivo',
    },
    {
      title: 'Laudo Tecnico',
      field: 'report',
      render: row =>
        row?.report ? (
          <a href={row?.report} target="_blank" rel="noreferrer">
            Ver
          </a>
        ) : (
          'Nenhum'
        ),
    },

    {
      title: 'Quantidade',
      field: 'quantidade',
    },

    {
      title: 'Valor Total',
      field: 'valorTotal',
      render: row =>
        row?.valorTotal?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
  ];

  async function GetPDV(e: any) {
    if (e?.length >= 14) {
      try {
        const postOcurrence = await api.get(`/pdvs/${e}`);
        setPdvInfo(postOcurrence.data);
        toast.success('CNPJ Encontrado');
      } catch (error: any) {
        toast.error('CNPJ Incorreto');
      }
    }
  }

  useEffect(() => {
    const newData = rowData.details.map((e: any) => ({
      produto: e.product.name,
      motivo: e.justification,
      quantidade: e.quantity,
      valorTotal: e.sell_price,
      photos: e.photos,
      report: e?.report,
    }));
    setData(newData);
  }, []);

  async function AproveOccurrence() {
    try {
      if (
        (rowData.total_price < 400 &&
          user.role.permissions.indexOf('canApprove400') > -1) ||
        (rowData.total_price < 5000 &&
          user.role.permissions.indexOf('canApprove5000') > -1) ||
        (rowData.total_price > 5001 &&
          user.role.permissions.indexOf('canApproveMore') > -1)
      ) {
        const aproved = await api.post(`/occurrences/aprove`, {
          occurrence_id: rowData.id,
          approve: true,
        });

        setAproveOcurrence(true);
        toast.success('Ocorrência aprovada!');
        handleChange(aproved.data);
        // window.location.reload();
      } else {
        emitError({}, 'Usuário sem Permissão ');
      }
    } catch (error: any) {
      emitError(error);
    }
  }

  async function handlePut(formData) {
    setAproveOcurrence(true);
    try {
      await api.put(`/occurrences/${rowData.id}`, {
        pdv_id: pdvInfo?.id,
      });

      window.location.reload();
    } catch (error: any) {
      emitError(error);
    }
  }

  const editingOcurrence = {
    color: 'var(--darkBlue)',
    fontWeight: 600,
  };

  return (
    <Container>
      <PopUp ref={popUpRef}>
        {openContract ? (
          <Contract
            content={showContract.data}
            isOpen={openContract}
            setPopUpState={setOpenContract}
          />
        ) : null}

        {reproveOccurrence ? (
          <Reprove
            rowData={rowData}
            isOpen={reproveOccurrence}
            setPopUpState={setReproveOcurrence}
          />
        ) : null}

        {seeHistory ? (
          <PopUpHistory
            isOpen={seeHistory}
            setPopUpState={setSeeHistory}
            status={rowData}
          />
        ) : null}

        {openCarrousel ? (
          <Carrousel
            rowData={addRowData}
            isOpen={openCarrousel}
            setPopUpState={setOpenCarrousel}
          />
        ) : null}

        {approve ? (
          <ApprovePopUp
            isOpen={approve}
            setPopUpState={setApprove}
            occurrence={rowData.num}
            setCustomState={AproveOccurrence}
          />
        ) : null}

        <div className="pop-up-header-mobile">
          <div className="occurrence-options">
            <button
              type="button"
              onClick={() => setPopUpState(!isOpen)}
              className="close-button"
            >
              <Angle />
              Voltar
            </button>
            <button
              type="button"
              onClick={() => setOpenContract(!openContract)}
            >
              Contrato
            </button>
            <button type="button" onClick={() => setSeeHistory(!seeHistory)}>
              Histórico do chamado
            </button>

            {rowData?.status?.status === 'Em Andamento' && (
              <button
                type="button"
                className="edit-btn"
                onClick={() => setEdit(!edit)}
                style={edit ? editingOcurrence : {}}
              >
                Editar
              </button>
            )}
          </div>
          <h5> Chamado {rowData.num}</h5>

          <span className="approval-codigo"> {rowData.num}</span>
        </div>
        {/* <Mobile rowData={rowData} /> */}
        <section className="desktop-section">
          <div className="pop-up-header">
            <h5> Chamado {rowData.num}</h5>
            <div className="occurrence-options">
              <button
                type="button"
                onClick={() => setOpenContract(!openContract)}
              >
                Contrato
              </button>
              <button type="button" onClick={() => setSeeHistory(!seeHistory)}>
                Histórico do chamado
              </button>
              {rowData?.status?.status === 'Em Andamento' && (
                <button
                  type="button"
                  onClick={() => setEdit(!edit)}
                  style={edit ? editingOcurrence : {}}
                >
                  Editar
                </button>
              )}
            </div>
            <button
              type="button"
              onClick={() => setPopUpState(!isOpen)}
              className="close-button"
            >
              <CloseButton />
            </button>
          </div>
          <Form onSubmit={edit ? handlePut : AproveOccurrence}>
            <section>
              {detalhesDaOcorrencia.map(e => (
                <div className="occurence-details">
                  <div className="table-title">
                    <p>{e.titulo}</p>
                  </div>
                  <ul className="table-list">
                    {e.detalhes.map(d => (
                      <li className="table-content">
                        <p> {d.titulo1}</p>
                        <h6> {d.conteudo}</h6>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              {dadosDoCliente.map(e => (
                <div className="client-details">
                  <div className="table-title">
                    <p>{e.titulo}</p>
                  </div>
                  <ul className="table-list">
                    {e.detalhes.map(d => (
                      <li className="table-content">
                        {edit ? (
                          <>
                            <label htmlFor={d.titulo1}> {d.titulo1}</label>
                            <Input
                              type="text"
                              id={d.titulo1}
                              name={d.input_name}
                              change={d?.change}
                              placeholder={d.conteudo}
                              disabled={d?.disabled || false}
                            />
                          </>
                        ) : (
                          <>
                            <p> {d.titulo1}</p>
                            <h6> {d.conteudo}</h6>
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              {dadosDoChamado.map(e => (
                <div className="call-details">
                  <div className="table-title">
                    <p>{e.titulo}</p>
                  </div>
                  <ul className="table-list">
                    {e.detalhes.map(d => (
                      <li className="table-content">
                        <p> {d.titulo1}</p>
                        {!d.files && <h6>{d.conteudo}</h6>}
                        {d.files?.map(f => (
                          <a href={f} target="_blank" rel="noreferrer">
                            {f.split('/').pop().substring(33)}
                          </a>
                        ))}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </section>

            <div className="table-section">
              <Table
                title=""
                column={[
                  ...tableColumn,
                  {
                    width: '10%',
                    cellStyle: {
                      textAlign: 'right',
                    },
                    render: (tableData: any) => (
                      <button
                        type="button"
                        disabled={!tableData?.photos?.length}
                        title={!tableData?.photos?.length && 'Nenhuma foto'}
                        onClick={() => {
                          setAddRowData(tableData);
                          setOpenCarrousel(true);
                        }}
                      >
                        <Camera />
                      </button>
                    ),
                  },
                ]}
                data={data}
              />
            </div>

            <div className="buttons-section">
              <span className="approval-codigo"> {rowData.num}</span>
              <div className="status-occurrence">
                <p>
                  {reproveOccurrence ? 'Reprovado' : null}
                  {aproveOccurrence ? 'Aprovado' : null}
                </p>
              </div>
              <div className="buttons">
                {rowData?.status?.status !== 'Em Andamento' ? (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                      paddingRight: '14px',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      color:
                        rowData?.status?.status === 'Aprovado'
                          ? 'var(--green)'
                          : 'var(--red)',
                    }}
                  >
                    {rowData?.status?.status}
                  </div>
                ) : edit ? (
                  <>
                    <RedButton title="Cancelar" />
                    <GreenButton title="Salvar" typeOf="submit" />
                  </>
                ) : (
                  <>
                    {user?.role?.permissions?.find(perm =>
                      [
                        'canApprove400',
                        'canApprove5000',
                        'canApproveMore',
                      ].includes(perm),
                    ) && (
                      <>
                        {(user?.role?.basicRole ===
                          rowData?.status?.next_approval ||
                          user?.role?.basicRole === 'MANAGER2' ||
                          user?.role?.basicRole === 'ADMIN') && (
                          <>
                            <RedButton
                              typeOf="button"
                              title={
                                reproveOccurrence ? 'Reprovado' : 'Reprovar'
                              }
                              setCustomState={setReproveOcurrence}
                              customState={reproveOccurrence}
                            >
                              Reprovar
                            </RedButton>
                            <GreenButton
                              // typeOf="submit"
                              title={aproveOccurrence ? 'Aprovado' : 'Aprovar'}
                              aproved={approve}
                              setCustomState={setApprove}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </Form>
        </section>
      </PopUp>
    </Container>
  );
}
