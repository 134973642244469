import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type statusProps = {
  title: string | boolean;
};

export default function Status({ title }: statusProps) {
  const [color, setColor] = useState('#000');

  useEffect(() => {
    if (
      title === 'Aprovado' ||
      title === 'Aprovada' ||
      title === 'Ativo' ||
      title === 'Habilitado' ||
      title === true
    ) {
      setColor('var(--green)');
    } else if (title === 'Análise' || title === 'Em Andamento') {
      setColor('var(--orange)');
    } else if (
      title === 'Reprovado' ||
      title === 'Desativado' ||
      title === 'Desabilitado' ||
      title === false
    ) {
      setColor('var(--red)');
    }
  }, [title]);

  const Span = styled.span`
    padding: 4px 8px;
    border-radius: 99px;
    position: relative;

    &::before {
      content: '•';
      position: absolute;
      bottom: 0px;
      transform: translateY(-50%);
      left: -12px;
      color: ${color};
      font-size: 30px;
    }
  `;

  return <Span>{title === true ? 'Aprovado' : title}</Span>;
}
