import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.section`
  width: 100%;
  padding: 0 24px;

  @media screen and (max-width: 880px) {
    .MuiTableFooter-root {
      display: none !important;
    }

    .table {
      padding: 20px 0px !important ;
    }

    .MuiTableRow-root {
      td {
        &:nth-child(2) {
          max-width: 150px;
          min-width: 1px !important;
        }
      }
    }
  }

  h5 {
    margin: 25px 0;
  }
  .table {
    width: 98%;
    border-radius: 20px;
    background: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
    padding: 20px 24px;
  }
`;
