/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';

interface Props {
  occurrenceColor: any;
}

export const Container = styled.div<Props>`
  flex-basis: 340px;
  flex-grow: 1;
  height: 100px;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  padding: 20px 30px 20px 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;

  &::before {
    content: '';
    width: 21px;
    height: 100%;
    position: absolute;
    background: ${props => {
      if (props.occurrenceColor === 'orange') {
        return ' var(--orange)';
      }
      if (props.occurrenceColor === 'red') {
        return ' var(--red)';
      }

      return 'var(--green)';
    }};
    left: 0px;
    top: 0;
  }

  h6 {
    max-width: 133px;
  }

  .dots-icon {
    width: 16px;
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
  }

  .carregando {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .carregando2 {
      background: #fff;
      opacity: 0.6;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3;
    }
    img {
      height: 50px;
      z-index: 4;
    }
  }
`;
