/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';

import { HistoryContainer } from './styles';

type StatusProps = {
  history: string[] | any;
  step: number;
};

export default function HistoryStatus({ history, step }: StatusProps) {
  const [historyData, setHistoryData] = useState({ date: '', title: '' });

  useEffect(() => {
    const historyLength = history?.status?.history?.length;

    if (step + 1 > historyLength) {
      const newData = {
        title: 'Aguardando recebimento',
        date: '',
      };
      setHistoryData(newData);
    } else {
      if (step === 0) {
        const newData = {
          title: `Abertura da Ocorrência ${history?.num} - Analista: ${history?.status?.history[step]?.name}`,
          date: `${new Date(
            history?.status?.history[step]?.create_at,
          ).toLocaleDateString()} às ${new Date(
            history?.status?.history[step]?.create_at,
          ).toLocaleTimeString()} `,
        };
        setHistoryData(newData);
      }
      if (!history?.status?.history[step]?.name && step !== 0) {
        const newData = {
          title: 'Aguardando recebimento',
          date: `${new Date(
            history?.status?.history[step]?.create_at,
          ).toLocaleDateString()} às ${new Date(
            history?.status?.history[step]?.create_at,
          ).toLocaleTimeString()} `,
        };
        setHistoryData(newData);
      }
      if (history.status.history[step].value !== 'Approve' && step !== 0) {
        const newData = {
          title: `Ocorrência recebida por ${history?.status?.history[step]?.name} - aguardando aprovação`,
          date: `${new Date(
            history?.status?.history[step]?.create_at,
          ).toLocaleDateString()} às ${new Date(
            history?.status?.history[step]?.create_at,
          ).toLocaleTimeString()} `,
        };
        setHistoryData(newData);
      }
      if (history.status.history[step].value === 'Approve' && step !== 0) {
        const newData = {
          title: `Ocorrência recebida por ${history?.status?.history[step]?.name} - Aprovada - Transferência para ${history.status.history[step].next_user.name}`,
          date: `${new Date(
            history?.status?.history[step]?.create_at,
          ).toLocaleDateString()} às ${new Date(
            history?.status?.history[step]?.create_at,
          ).toLocaleTimeString()} `,
        };

        setHistoryData(newData);
      }
    }
  }, []);

  return (
    <HistoryContainer>
      <small>{historyData.date}</small>
      <p> {historyData.title} </p>
    </HistoryContainer>
  );
}
