import React, { useState, useEffect, useRef } from 'react';

import { formatISO } from 'date-fns';
import { ValueType } from 'rsuite/lib/DateRangePicker';
import api from '../../services/api';
import { ReactComponent as Dots } from '../../icons/3dots-icon.svg';
import TableWControllers from '../../components/tableWControllers';
import PDVPopUp from '../../components/PDVPopUps/PDV';
import { useRegionals } from '../../hooks/regional';
import { useNetworks } from '../../hooks/Network';
import { useContracts } from '../../hooks/contracts';
import { useChannel } from '../../hooks/channels';
import { Container } from './styles';
import Filter from '../../components/PDVfilter';
import { useError } from '../../hooks/errors';
import { usePDVs } from '../../hooks/pdvs';
import { PDV } from '../../models';

export default function PontoDeVenda() {
  const tableRef = useRef<any>();
  const [addPDV, setAddPDV] = useState(false);
  const [addRowData, setAddRowData] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [tableData, setTableData] = useState({});
  const { regionals, getRegional } = useRegionals();
  const { networks, getNetwork } = useNetworks();
  const { contracts, getContract } = useContracts();
  const { channel, getChannel } = useChannel();
  const { pdvs, getPDVs, setPDVs, getNewPage } = usePDVs();

  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [isFiltering, setIsFiltering] = useState<ValueType>();

  const [isSearching, setIsSearching] = useState(false);

  const { emitError } = useError();

  const parseData = (list: any[]): any => {
    const parsed = list?.map((it: any) => ({
      ...it,
      parsedCNPJ: it?.details?.cnpj?.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5',
      ),
      parsedCreateAt: `${new Date(
        it?.create_at,
      ).toLocaleDateString()} às ${new Date(
        it?.create_at,
      ).toLocaleTimeString()} `,
      parsedUpdateAt: `${new Date(
        it?.update_at,
      ).toLocaleDateString()} às ${new Date(
        it?.update_at,
      ).toLocaleTimeString()} `,
    }));

    return parsed;
  };

  /* useEffect(() => {
    const parsed = parseData(pdvs);
    setTableData({ data: parsed, totalCount: 2000 });
  }, [pdvs]); */

  useEffect(() => {
    if (!regionals?.length) getRegional();
    if (!networks?.length) getContract();
    if (!contracts?.length) getNetwork();
    if (!channel?.length) getChannel();
    // if (!pdvs?.length) getPDVs();
  }, []);

  const filterDuplicates = (list: any[]) => {
    const newPdvs: any[] = [];
    // eslint-disable-next-line array-callback-return
    list?.map(pdv => {
      const alreadyExists = pdvs.find(it => it.id === pdv.id);
      if (!alreadyExists) newPdvs.push(pdv);
    });

    return newPdvs;
  };

  const handleSearch = async (searchText: string) => {
    // console.log(tableRef?.current?.dataManager?.searchedData);
    if (searchText?.length < 3 || isSearching) return;

    try {
      setIsSearching(true);
      const { data: founds } = await api.get(`/pdvs/search/${searchText}`);

      const newPdvs = filterDuplicates(founds);

      setPDVs(old => [...newPdvs, ...old]);
      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
      emitError({}, 'Nada encontrado!');
    }
  };

  const handlePageChange = (currentPage: number, pageSize: number) => {
    if (isFiltering) return;

    // eslint-disable-next-line prettier/prettier
    const limit = (currentPage * pageSize) + (pageSize * 2);
    if (limit >= pdvs?.length) getNewPage();
  };

  const handleFilter = async (e: ValueType) => {
    try {
      const { data: response } = await api.get('/pdvs', {
        params: {
          startAt: formatISO(e[0]),
          endAt: formatISO(new Date(formatISO(e[1])).setHours(23, 59, 59)),
        },
      });

      setIsFiltering(e);
      setFilteredData(response);
    } catch (error) {
      emitError(error);
    }
  };

  const getData = async (params: any) => {
    const response = await api.get('/pdvs', {
      params: {
        page: params.page,
        pageSize: params.pageSize,
        count: true,
      },
    });
    return {
      data: parseData(response.data.data),
      page: params.page,
      totalCount: response.data.total,
    };
  };

  const columnPDV = [
    {
      title: 'Ponto de Venda',
      field: 'name',
    },
    {
      title: 'Rede',
      field: 'network.name',
    },

    {
      title: 'CNPJ',
      field: 'parsedCNPJ',
    },

    {
      title: 'Endereço',
      field: 'details.address',
    },

    {
      title: 'Cidade',
      field: 'details.city',
    },

    {
      title: 'Regional',
      field: 'regional.name',
    },

    {
      title: 'Criado em',
      field: 'parsedCreateAt',
    },

    {
      title: 'Atualizado em',
      field: 'parsedUpdateAt',
    },
  ];

  return (
    <Container>
      <TableWControllers
        importLink="pdv"
        importModelLink="https://pdv-general-mills.s3.sa-east-1.amazonaws.com/03a628195ab2f0e3dab2103afb79512e-Pontos+de+venda.xlsx"
        addButtonText="Adicionar PDV"
        title="Pontos de Venda"
        filter
        handleSearch={handleSearch}
        // handleLastPage={handlePageChange}
        tableRef={tableRef}
        filterState={openFilter}
        setFilter={setOpenFilter}
        importButton
        isFiltering={!!isFiltering}
        column={[
          ...columnPDV,

          {
            width: '10%',

            render: (rowData: any) => (
              <button
                type="button"
                onClick={() => {
                  setAddRowData(rowData);
                  setOpenEdit(true);
                }}
              >
                <Dots />
              </button>
            ),
          },
        ]}
        data={isFiltering ? filteredData : getData} // tableData}
        addButtonState={addPDV}
        addButtonFunction={setAddPDV}
      />

      {addPDV ? <PDVPopUp isOpen={addPDV} setPopUpState={setAddPDV} /> : null}

      {openEdit ? (
        <PDVPopUp
          isOpen={openEdit}
          setPopUpState={setOpenEdit}
          rowData={addRowData}
        />
      ) : null}

      {openFilter ? (
        <Filter
          isOpen={openFilter}
          setPopUpState={setOpenFilter}
          handleChange={handleFilter}
          handleClearFilters={setIsFiltering}
          date={isFiltering}
        />
      ) : null}
    </Container>
  );
}
