/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Pie } from '@ant-design/charts';
import Table from '../../../../components/Table';
import loadingGif from '../../../../img/loading.gif';
import { Container } from './styles';

interface IProps {
  fullData: any;
  isLoading: boolean;
}

export default function Graph({ fullData, isLoading }: IProps) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!fullData) return;

    const a = fullData.map(item => ({
      type: item.name,
      value: item.devolutions,
    }));
    setData(a);
  }, [fullData]);

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.9,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    legend: false,
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    statistic: {
      title: false,
      content: {
        content: '',
        style: {
          whiteSpace: 'no-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
  };

  const column = [
    {
      title: 'Motivo',
      field: 'name',
    },

    {
      title: 'Devoluções',
      field: 'devolutions',
    },

    {
      title: 'Porcentagem',
      field: 'percent',
    },
  ];

  return (
    <Container>
      {isLoading ? (
        <div className="carregando">
          <div className="carregando2" />
          <img src={loadingGif} alt="" />
        </div>
      ) : null}
      <p>% Devoluções por Motivo</p>
      <Pie {...config} />
      <Table title="" column={column} data={fullData} />
    </Container>
  );
}
