import React, { useState, useEffect } from 'react';
import { ValueType } from 'rsuite/lib/DateRangePicker';
import { formatISO } from 'date-fns';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { ReactComponent as Dots } from '../../icons/3dots-icon.svg';
import CanalPopUp from '../../components/PDVPopUps/Canal';
import TableWControllers from '../../components/tableWControllers';
import Filter from '../../components/PDVfilter';
import { useError } from '../../hooks/errors';

import { Container } from './styles';

export default function Canal() {
  const [data, setData] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [addRowData, setAddRowData] = useState(false);
  const { emitError } = useError();

  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [isFiltering, setIsFiltering] = useState<ValueType>();

  const handleFilter = async (e: ValueType) => {
    setIsFiltering(e);
    if (!e) return;
    try {
      const { data: response } = await api.get('/channels', {
        params: {
          startAt: formatISO(e[0]),
          endAt: formatISO(new Date(formatISO(e[1])).setHours(23, 59, 59)),
        },
      });

      setFilteredData(response);
    } catch (error) {
      emitError(error);
    }
  };

  useEffect(() => {
    api
      .get('/channels')
      .then((res: any) => setData(res.data))
      .catch(error => {
        emitError(error);
      });
  }, []);

  const [addCanal, setAddCanal] = useState(false);

  const columnRede = [{ title: 'Nome Canal', field: 'name' }];

  return (
    <Container>
      <TableWControllers
        importLink="channel"
        importModelLink="https://pdv-general-mills.s3.sa-east-1.amazonaws.com/3065ec9ef7800917bae7bd91616e2626-Canais.xlsx"
        addButtonText="Adicionar Canal"
        filter
        filterState={openFilter}
        setFilter={setOpenFilter}
        title="Canal"
        isFiltering={!!isFiltering}
        column={[
          ...columnRede,

          {
            width: '10%',
            cellStyle: {
              textAlign: 'right',
            },

            render: (rowData: any) => (
              <button
                type="button"
                onClick={() => {
                  setAddRowData(rowData);
                  setOpenEdit(true);
                }}
              >
                <Dots />
              </button>
            ),
          },
        ]}
        data={isFiltering ? filteredData : data}
        addButtonState={addCanal}
        addButtonFunction={setAddCanal}
      />

      {addCanal ? (
        <CanalPopUp isOpen={addCanal} setPopUpState={setAddCanal} />
      ) : null}

      {openEdit ? (
        <CanalPopUp
          isOpen={openEdit}
          setPopUpState={setOpenEdit}
          rowData={addRowData}
        />
      ) : null}

      {openFilter ? (
        <Filter
          isOpen={openFilter}
          setPopUpState={setOpenFilter}
          handleChange={handleFilter}
          handleClearFilters={setIsFiltering}
          date={isFiltering}
        />
      ) : null}
    </Container>
  );
}
