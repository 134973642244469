/* eslint-disable consistent-return */
import React, { createContext, useContext, useState } from 'react';
import api from '../services/api';
import { Notification } from '../models';
import { useError } from './errors';

export interface notificationsContextData {
  notifications: Notification[] | null;
  getNotifications(): Promise<void>;
  setManualNotification(notifications: Notification[]): void;
  isLoading: boolean;
}
export const NotificationsContext = createContext<notificationsContextData>(
  {} as notificationsContextData,
);
export const NotificationsProvider: React.FC = ({ children }: any) => {
  const [notifications, setNotifications] = useState<Notification[]>();
  const [loading, setLoading] = useState(false);
  const { emitError } = useError();

  const setManualNotification = (loginNotifications: Notification[]): void => {
    const sortNotifications = (n: any[]) =>
      n?.sort((a, b) => `${b.create_at}`.localeCompare(a.create_at));

    setNotifications(sortNotifications(loginNotifications));
  };

  const getNotifications = async () => {
    try {
      setLoading(true);

      const response = await api.get('/notifications');
      setNotifications(response.data);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      emitError(error);
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        isLoading: loading,
        notifications,
        getNotifications,
        setManualNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export function useNotifications(): notificationsContextData {
  const context = useContext(NotificationsContext);
  return context;
}
