import React, { useState, useEffect } from 'react';
import { formatISO } from 'date-fns';
import { toast } from 'react-toastify';
import { ValueType } from 'rsuite/lib/DateRangePicker';
import api from '../../services/api';
import { ReactComponent as Dots } from '../../icons/3dots-icon.svg';
import CategoriaPopUp from '../../components/ProdutosPopUP/Categoria';
import TableWControllers from '../../components/tableWControllers';
import Status from '../../components/Table/components/status';
import { Container } from './styles';
import Filter from '../../components/PDVfilter';
import { useError } from '../../hooks/errors';
import { useCategories } from '../../hooks/categories';

export default function Categorias() {
  const { emitError } = useError();
  const [tableData, setTableData] = useState<any[]>();
  const [addCategoria, setAddCategoria] = useState(false);
  const [editCategorias, setEditCategorias] = useState(false);
  const [addRowData, setAddRowData] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [isFiltering, setIsFiltering] = useState<ValueType>();
  const { categories, getCategories } = useCategories();

  const parseData = (list: any[]): any => {
    const parsed = list?.map((it: any) => ({
      ...it,
      parsedStatus: it?.enabled ? 'Habilitado' : 'Desabilitado',
      parsedCreateAt: `${new Date(
        it?.create_at,
      ).toLocaleDateString()} às ${new Date(
        it?.create_at,
      ).toLocaleTimeString()} `,
      parsedUpdateAt: `${new Date(
        it?.update_at,
      ).toLocaleDateString()} às ${new Date(
        it?.update_at,
      ).toLocaleTimeString()} `,
    }));

    return parsed;
  };

  const handleFilter = async (e: ValueType) => {
    setIsFiltering(e);
    if (!e) return;
    try {
      const { data: response } = await api.get('/categories', {
        params: {
          startAt: formatISO(e[0]),
          endAt: formatISO(new Date(formatISO(e[1])).setHours(23, 59, 59)),
        },
      });

      setFilteredData(response);
    } catch (error) {
      emitError(error);
    }
  };

  useEffect(() => {
    const parsed = parseData(categories);
    setTableData(parsed);
  }, [categories]);

  useEffect(() => {
    if (!categories?.length) getCategories();
  }, []);

  const columnCategoria = [
    { title: 'Categoria', field: 'name' },
    {
      title: 'Status',
      field: 'parsedStatus',
      render: rowData => (
        <Status title={rowData.enabled ? 'Habilitado' : 'Desabilitado'} />
      ),
    },
    { title: 'Criado em', field: 'parsedCreateAt' },
    { title: 'Atualizado em', field: 'parsedUpdateAt' },
  ];

  return (
    <Container>
      <TableWControllers
        importLink="category"
        importModelLink="https://pdv-general-mills.s3.sa-east-1.amazonaws.com/07c099c364a7cbb10bb040a98d08bec9-Categorias.xlsx"
        addButtonText="Adicionar Categoria"
        title="Categoria"
        data={isFiltering ? filteredData : tableData}
        filter
        filterState={openFilter}
        setFilter={setOpenFilter}
        isFiltering={!!isFiltering}
        column={[
          ...columnCategoria,
          {
            width: '10%',
            cellStyle: {
              textAlign: 'right',
            },

            render: (rowData: any) => (
              <button
                type="button"
                onClick={() => {
                  setAddRowData(rowData);
                  setEditCategorias(true);
                }}
              >
                <Dots />
              </button>
            ),
          },
        ]}
        addButtonState={addCategoria}
        addButtonFunction={setAddCategoria}
      />

      {addCategoria ? (
        <CategoriaPopUp isOpen={addCategoria} setPopUpState={setAddCategoria} />
      ) : null}

      {editCategorias ? (
        <CategoriaPopUp
          isOpen={editCategorias}
          setPopUpState={setEditCategorias}
          rowData={addRowData}
        />
      ) : null}

      {openFilter ? (
        <Filter
          isOpen={openFilter}
          setPopUpState={setOpenFilter}
          handleChange={handleFilter}
          handleClearFilters={setIsFiltering}
          date={isFiltering}
        />
      ) : null}
    </Container>
  );
}
