/* eslint-disable consistent-return */
import styled, { keyframes, css } from 'styled-components';

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:900px;
  opacity:1;
}

`;

const animationIn = keyframes`

from {
  left:700px;
}

to {
  left:00px;
}

`;
const animationOut = keyframes`

from {
  left:0;
}

to {
  left:-700px;
}

`;

export const Container = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: calc(9 * 99);
  @media screen and (max-width: 880px) {
    top: 54px;
    background: none;
  }
`;

interface Steps {
  stepsProps: string;
}

export const PopUp = styled.div<Steps>`
  width: 90%;
  max-height: 700px;
  overflow-x: hidden;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: ${props =>
    props.stepsProps === 'product'
      ? '14px 16px 160px 16px'
      : '14px 16px 16px 16px'};
  position: relative;
  animation: 0.6s ease ${entranceAnim};

  ${props => {
    if (props.stepsProps === 'pdv') {
      return css`
        .codigo-pdv {
          position: relative;
          animation: 0.4s ${animationIn} ease;
          visibility: visible;
        }
        .pdv-info,
        .addproduct {
          position: fixed;
          animation: 0.4s ${animationOut} ease;
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
      `;
    }
    if (props.stepsProps === 'info') {
      return css`
        .pdv-info {
          position: relative;
          animation: 0.4s ${animationIn} ease;
        }

        .addproduct,
        .codigo-pdv {
          position: fixed;
          animation: 0.4s ${animationOut} ease;
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
      `;
    }
    if (props.stepsProps === 'product') {
      return css`
        width: 100%;
        max-height: 100%;
        height: 100%;
        border-radius: 0px;
        background: var(--bgLight);
        .pop-up-header {
          display: none !important;
        }

        .addproduct {
          position: relative;
          animation: 0.4s ${animationIn} ease;
        }

        .pdv-info,
        .codigo-pdv {
          position: fixed;
          animation: 0.4s ${animationOut} ease;
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
      `;
    }
  }};

  .no-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h6 {
      margin: 1em 0;
      text-align: center;
    }

    button {
      width: 100%;
      max-width: 328px;

      padding: 13px 0;

      background: #fd4084;
      border: #fd4084;
      border-radius: 12px;
      color: white;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 5%;
      transition: 0.3s;

      &:hover {
        background: linear-gradient(225deg, #fd4084 0%, #fd4084 99.99%);
        color: white;
      }
    }
  }

  .pop-up-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding-top: 10px;
    margin-bottom: 16px;

    .close-button {
      position: absolute;
      top: 10px;
      right: 0px;

      svg {
        width: 16px;
      }
    }
  }

  .codigo-pdv {
    label {
      font-size: 12px;
      margin-bottom: 18px;
      display: block;
    }
  }

  .pdv-info {
    ul {
      max-height: 323px;

      overflow-y: auto;
    }

    li {
      padding: 11px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h6 {
        color: #66686d;
        &:nth-child(odd) {
          font-weight: 600;
        }
      }

      & + li {
        border: solid #f3f3f0;
        border-width: 1px 0px 1px 0px;
      }
    }
  }

  .buttons-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    margin-top: 20px;

    button {
      width: 100%;
    }
  }
`;
