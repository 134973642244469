/* eslint-disable consistent-return */
import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import { Team } from '../models';
import { useError } from './errors';

export interface TeamContextData {
  teams: Team[] | null;
  getTeam(): Promise<void>;
  isLoading: boolean;
}
export const TeamContext = createContext<TeamContextData>(
  {} as TeamContextData,
);
export const TeamProvider: React.FC = ({ children }: any) => {
  const [teams, setTeams] = useState<Team[]>();
  const { emitError } = useError();
  const [loading, setLoading] = useState(false);

  const getTeam = async () => {
    try {
      setLoading(true);

      const response = await api.get('/teams');
      setTeams(response.data);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      emitError(error);
    }
  };

  return (
    <TeamContext.Provider
      value={{
        isLoading: loading,
        teams,
        getTeam,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export function useTeams(): TeamContextData {
  const context = useContext(TeamContext);
  return context;
}
