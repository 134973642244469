/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ApprovePopUp from '../../ApprovePopUp';
import { ReactComponent as CloseButton } from '../../../icons/blue-close-button-icon.svg';
import BlueButton from '../../blueButton';
import RedButton from '../../redButton';
import { Container, PopUp } from './styles';
import Input from '../../Form/input';
import Photo from '../../Form/photo';
import api from '../../../services/api';
import Delete from '../../DeletePopUp';
import { useError } from '../../../hooks/errors';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  rowData?: string[] | any;
};

ProdutoPopUp.defaultProps = {
  rowData: false,
};

export default function ProdutoPopUp({
  isOpen,
  setPopUpState,
  rowData,
}: popUpProp) {
  const { emitError } = useError();
  const [steps, setSteps] = useState('info');
  const [fieldPosition, setFieldPosition] = useState(1);
  const [categoryId, setCategoryId] = useState([]);
  const popUpRef = useRef(null);
  const formRef = useRef(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [deletePdv, setDeletePdv] = useState(false);
  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  useEffect(() => {
    api
      .get('/products')
      .then((res: any) => {
        const parsedData = res.data
          .slice(0, 1)
          .map((product: any) => product.category_id);

        setCategoryId(parsedData);
      })
      .catch(error => {
        emitError(error);
      });
  }, []);

  const infoFields = [
    {
      title: 'Nome',
      type: 'input',
      name: 'name',
      value: rowData?.name,
      isRequired: true,
    },
    {
      title: 'SKU',
      type: 'input',
      name: 'sku',
      value: rowData?.sku,
      isRequired: true,
    },

    {
      type: 'hidden',
      name: 'category_id',
      value: categoryId,
    },
  ] as any;

  const endFields = [
    {
      title: 'Preço Mínimo',
      type: 'input',
      name: 'min_price',
      value: rowData?.min_price,
      isRequired: true,
    },
    {
      title: 'Preço máximo',
      type: 'input',
      name: 'max_price',
      value: rowData?.max_price,
      isRequired: true,
    },
  ] as any;

  async function handleDelete() {
    try {
      await api.delete(`/products/${rowData.id}`);
      toast.success('Produto deletado');
      window.location.reload();
    } catch (error: any) {
      toast.error('Produto referenciado por outra tabela');
    }
  }

  async function handlePut(data: any) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O Nome do Produto é obrigatório'),
        sku: Yup.string().required('o SKU é obrigatório'),
        min_price: Yup.string().required('Preço mínimo é obrigatória'),
        max_price: Yup.string().required('Preço máximo é obrigatória'),
        photo: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      try {
        await api.put(`/products/${rowData.id}`, data);
        window.location.reload();
      } catch (error) {
        emitError(error);
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios!');
        setShowConfirmation(false);
        setSteps('info');

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  async function handlePost(data: any) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O Nome do Produto é obrigatório'),
        sku: Yup.string().required('o SKU é obrigatório'),
        min_price: Yup.string().required('Preço mínimo é obrigatória'),
        max_price: Yup.string().required('Preço máximo é obrigatória'),
        photo: Yup.string(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      try {
        await api.post('/products', data);
        window.location.reload();
      } catch (error) {
        emitError(error);
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios!');
        setShowConfirmation(false);
        setSteps('info');

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  useEffect(() => {
    if (steps === 'info') {
      setFieldPosition(1);
    } else if (steps === 'end') {
      setFieldPosition(2);
    } else if (steps === 'pic') {
      setFieldPosition(3);
    }
  }, [steps]);

  const changeButton =
    rowData !== false ? (
      <div style={{ marginTop: '12px', maxWidth: '326px', width: '100%' }}>
        <RedButton
          title="Apagar"
          setCustomState={setDeletePdv}
          customState={deletePdv}
        />
      </div>
    ) : (
      <button
        type="button"
        className="cancel-button"
        onClick={() => setPopUpState(!isOpen)}
      >
        Cancelar
      </button>
    );

  return (
    <Container>
      <PopUp ref={popUpRef}>
        {deletePdv ? (
          <Delete
            title="Ponto de Venda"
            isOpen={deletePdv}
            setCustomState={handleDelete}
            setPopUpState={setDeletePdv}
          />
        ) : null}

        <div className="pop-up-header">
          <h5> Produto</h5>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>
        <div className="popUp-controllers">
          <button
            type="button"
            className={steps === 'info' ? 'active' : ''}
            onClick={() => setSteps('info')}
          >
            Informação
          </button>
          <button
            type="button"
            className={steps === 'end' ? 'active' : ''}
            onClick={() => setSteps('end')}
          >
            Preço
          </button>
          <button
            type="button"
            className={steps === 'pic' ? 'active' : ''}
            onClick={() => setSteps('pic')}
          >
            Foto
          </button>
        </div>

        <div className="conditional-content">
          <Form ref={formRef} onSubmit={rowData ? handlePut : handlePost}>
            <div className=" fields-section teste-action">
              <div
                className={
                  fieldPosition === 1
                    ? 'information-section action'
                    : 'information-section'
                }
              >
                <ul>
                  {infoFields.map(e => (
                    <li>
                      <h6>
                        {e.title}
                        {e?.isRequired && (
                          <span style={{ display: 'inline', color: 'red' }}>
                            {' '}
                            *
                          </span>
                        )}
                      </h6>

                      <Input type={e.type} name={e.name} value={e.value} />
                    </li>
                  ))}
                </ul>
                <div className="step-buttons">
                  <BlueButton
                    setCustomState={setSteps}
                    customState="end"
                    title="Próximo"
                    typeOf="button"
                  />

                  {changeButton}
                </div>
              </div>
              <div
                className={
                  fieldPosition === 2 ? 'end-section action' : 'end-section'
                }
              >
                <ul>
                  {endFields.map(e => (
                    <li>
                      <h6>
                        {e.title}
                        {e?.isRequired && (
                          <span style={{ display: 'inline', color: 'red' }}>
                            {' '}
                            *
                          </span>
                        )}
                      </h6>

                      <Input type="text" value={e?.value} name={e.name} />
                    </li>
                  ))}
                </ul>
                <div className="step-buttons">
                  <BlueButton
                    setCustomState={setSteps}
                    customState="pic"
                    title="Próximo"
                  />

                  {changeButton}
                </div>
              </div>
              <div
                className={
                  fieldPosition === 3 ? 'pic-section action' : 'pic-section'
                }
              >
                <Photo
                  hasDescription={false}
                  currentImage={rowData?.photo}
                  name="description"
                />
                <div className="step-buttons">
                  <BlueButton
                    setCustomState={setShowConfirmation}
                    customState={showConfirmation}
                    title="Próximo"
                  />

                  {changeButton}
                </div>
              </div>
            </div>

            {showConfirmation ? (
              <ApprovePopUp
                sampleText="Adicionar Produto?"
                isOpen={showConfirmation}
                setPopUpState={setShowConfirmation}
              />
            ) : null}
          </Form>
        </div>
      </PopUp>
    </Container>
  );
}
