// eslint-disable-next-line import/no-unresolved
import styled, { keyframes } from 'styled-components';

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100px;
  max-height:100px;
  opacity:1;
}

`;

// eslint-disable-next-line import/prefer-default-export
export const Header = styled.div`
  width: 100%;
  height: 100%;
  padding: 12px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media screen and (min-width: 880px) {
    display: none !important;
  }

  .perfil-pic-button {
    width: 32px;
    height: 32px;
    border-radius: 99px;
    overflow: hidden;
  }

  .perfil-pic {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 99px;
    overflow: hidden;
  }

  svg:nth-child(1) {
    width: 74px;
  }
  svg:nth-child(2) {
    width: 69px;
  }
`;

export const SignOut = styled.div`
  width: 100px;
  padding: 10px 12px;
  bottom: -90%;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 20px;
  animation: 0.4s ${entranceAnim} ease;

  z-index: 99999;

  button {
    display: flex;
    align-items: center;

    svg {
      width: 20px !important;
      margin-right: 6px;
    }
    &:hover {
      h6 {
        color: var(--darkBlue);
      }
    }
  }

  h6 {
    color: #82b2f3;
  }
`;
