/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:900px;
  opacity:1;
}

`;

export const PopUp = styled.div`
  width: 100%;
  max-width: 467px;
  min-height: 1px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 14px 25px;
  overflow: hidden;
  animation: 1s ${entranceAnim} ease;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;

  .pop-up-header {
    width: 100%;
    height: 36px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding-left: 24px;

    button {
      position: absolute;
      top: 10px;
      right: 0px;

      svg {
        width: 16px;
      }
    }
  }

  .body {
    p {
      color: #66686d;
    }
  }

  p {
    text-align: center;
  }

  .button-field {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
  }
`;
