import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  display: flex;
  align-items: center;

  .active {
    background: var(--lightBlue);
    color: white !important;
  }

  button {
    color: var(--lightBlue);
    border-radius: 14px;
    border: 1px solid;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--h6Size);
    font-weight: var(--h6Weight);
    letter-spacing: var(--h6Letter);
    line-height: var(--h6Line);
  }

  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
`;
