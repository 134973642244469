/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import Loading from '../../components/Loading';
import GMLogo from '../../icons/LOGO-GM-White.svg';
import PDVLogo from '../../icons/pdv-logo-white.svg';
import Input from '../../components/Form/input';
import { MainContainer, Container } from './styles';
import { AuthContext } from '../../hooks/auth';
import { useError } from '../../hooks/errors';
import { ReactComponent as Angle } from '../../icons/angle-right-icon.svg';

export default function LoginPage() {
  const { signIn, loading } = useContext(AuthContext);
  const { emitError } = useError();
  const formRef = useRef(null);
  const [resetPassword, setResetPassword] = useState('login');

  async function handleForgotPassowrd(data: any) {
    try {
      const schema = Yup.object().shape({
        password: Yup.string()
          .min(6, 'A senha deve ter no mínimo 6 caracteres')
          .required('A Senha é obrigatória'),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'As senhas devem corresponder',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      delete data.confirmPassword;
      await api.post('sessions/recover/', data);
      toast.success('Um token foi enviado para o e-mail');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  return (
    <MainContainer>
      {loading ? <Loading /> : null}
      <Container resetPass={resetPassword}>
        <img src={GMLogo} alt="gm-logo" className="gm-logo" />

        <section className="conditional-content">
          <Form
            ref={formRef}
            onSubmit={handleForgotPassowrd}
            className={
              resetPassword === 'login' ? 'action login-form' : 'login-form'
            }
          >
            <div className="login-fields">
              <label>
                Nova Senha
                <Input type="text" name="password" placeholder="123123" />
              </label>
              <label>
                Confirmar Senha
                <Input
                  type="text"
                  name="confirmPassword"
                  placeholder="123123"
                />
              </label>
              <label>
                Token
                <Input type="text" name="token" placeholder="A123B321C456" />
              </label>
            </div>

            <button
              type="submit"
              className="login-button"
              style={{ marginTop: 20 }}
            >
              Redefinir Senha
            </button>
            <Link
              to="/login"
              className="forgot"
              style={{ color: '#fff', marginTop: 20, textAlign: 'end' }}
            >
              Voltar
            </Link>
          </Form>
        </section>

        <img src={PDVLogo} alt="pdv-logo" className="pdv-logo" />
      </Container>
    </MainContainer>
  );
}
