import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Controllers = styled.div`
  width: 100%;
  min-height: 40px;
  margin: 20px 0 24px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;

  @media screen and (min-width: 880px) {
    .filter-column-mobile {
      display: none !important;
    }

    .actions-column,
    .filter-column {
      display: flex !important;
    }
  }

  .filter-column-mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .import-btn {
    position: relative;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 12px;
    color: #808895;
    cursor: pointer;
    svg {
      width: 12px;
      margin-right: 10px;
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  }

  button {
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 12px;
    color: #808895;
    svg {
      width: 12px;
      margin-right: 10px;
    }

    path {
      fill: #808895;
    }
  }

  .filter-column {
    flex-basis: calc(50% - 20px);
    display: none;
    align-items: center;
    gap: 26px;

    .search-bar-division {
      width: 100%;
      max-width: 328px;
      position: relative;

      svg {
        width: 17px;
        pointer-events: none;
        position: absolute;
        right: 16px;

        top: 50%;
        transform: translateY(-50%);
      }
    }
    input {
      width: 100%;
      padding: 10px 44px 10px 36px;
      border: 1px solid #abccf7;
      border-radius: 12px;
      color: #99a0aa;
      font-size: var(--pSize);
      font-weight: var(--pWeight);
      pointer-events: none;
    }
  }
  .actions-column {
    flex-basis: 50%;
    display: none;
    align-items: center;
    justify-content: flex-end;
    gap: 26px;

    svg {
      width: 16px;
    }
  }
`;

export const MainContainer = styled.section`
  width: 100%;
  position: relative;
`;

export const Table = styled.div`
  width: 100%;

  @media screen and (max-width: 880px) {
    .MuiInput-root,
    .MuiIconButton-colorInherit {
      display: none;
    }

    .MuiTableCell-footer {
      padding: 6px !important;
    }

    .MuiToolbar-regular {
      button {
        padding: 6px;
      }
    }
    /* .MuiInput-root,
    .MuiToolbar-regular {
      display: none;
    } */
  }

  .MuiIconButton-colorInherit {
    position: absolute;
    top: 0;
    right: 0;

    width: 80px;
    border-radius: 0;
    opacity: 0;
  }

  .MuiInput-root {
    position: absolute;
    padding-left: 10px;
    top: 6px;
    left: 0;
    border: 0 !important;
    outline: 0 !important;

    &::before,
    &::after {
      border: 0 !important;
    }
    .material-icons {
      display: none;
    }
  }

  .MuiFormControl-root,
  .MuiToolbar-root,
  .MuiPaper-root {
    position: unset !important;
  }
  .MuiPaper-root {
    box-shadow: none;
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
    padding: 0 16px;

    .Component-horizontalScrollContainer-12 {
      padding: 0 16px;
    }
    h6 {
      font-size: var(--pSize);
      font-weight: var(--pWeight);
      letter-spacing: var(--pLetter);
      line-height: var(--pLine);
      color: #404249;
      font-family: 'Montserrat', sans-serif !important;
    }

    th {
      text-align: left;
      font-family: 'Montserrat', sans-serif !important;
      div {
        font-size: var(--smallSize);
        font-weight: var(--smallWeight);
        letter-spacing: var(--smallLetter);
        line-height: var(--smallLine);
        color: #66686d;
      }

      .MuiButtonBase-root {
        flex-direction: row;
      }
    }

    td {
      padding: 26px 16px;
      font-size: var(--h6Size) !important;
      font-weight: var(--h6Weight) !important;
      letter-spacing: var(--h6Letter);
      line-height: var(--h6Line);
      color: rgba(102, 104, 109, 1);
      font-family: 'Montserrat', sans-serif !important;
    }

    .MuiIconButton-root,
    .MuiTypography-caption {
      color: var(--darkBluePDV);
    }

    .MuiTableCell-footer {
      border: 0;
    }
  }
`;

export const FilterButton = styled.button<{ isFiltering: boolean }>`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 12px;
  color: #808895;
  color: ${props =>
    props.isFiltering ? 'var(--lightBlue)!important' : '#808895'};
  > svg {
    width: 12px;
    margin-right: 10px;
    > path {
      fill: ${props => (props.isFiltering ? 'var(--lightBlue)' : '#808895')};
    }
  }
`;
