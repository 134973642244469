import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { MainContainer, Controllers, Table, FilterButton } from './styles';
import { ReactComponent as FilterIcon } from '../../icons/filter-icon.svg';
import { ReactComponent as PlusIcon } from '../../icons/blue-plus-sign-icon.svg';
import { ReactComponent as ImportIcon } from '../../icons/importIcon.svg';
import { ReactComponent as ExportIcon } from '../../icons/export-icon.svg';
import { ReactComponent as SearchIcon } from '../../icons/search-icon.svg';
import ImportModal from './components/importModal';
import UpdateModal from './components/updateModal';
import { useAuth } from '../../hooks/auth';

type tableProps = {
  children?: any;
  tableRef?: any;
  title?: string;
  column: any;
  data: any;

  filter?: boolean;
  setFilter?: any;
  filterState?: boolean;
  isFiltering?: boolean;

  importButton?: boolean;
  importLink?: string;
  importModelLink?: string;
  updateButton?: boolean;
  updateLink?: string;
  updateModelLink?: string;
  exportButton?: boolean;

  search?: boolean;
  handleSearch?(searchText: string): void;
  handleLastPage?(currentPage: number, pageSize: number): void;

  addButtonText?: string | boolean;
  addButtonState?: boolean;
  addButtonFunction?: any;

  [x: string]: any;
};

const TableWControllers = ({
  title = 'Ocorrências',
  column,
  data,
  addButtonText = false,
  importButton = true,
  importLink = '',
  importModelLink,
  updateButton = false,
  updateLink = '',
  updateModelLink,
  exportButton = true,
  search = true,
  filter = false,
  setFilter = false,
  filterState = false,
  isFiltering = false,
  addButtonState = null,
  addButtonFunction = false,
  handleLastPage,
  handleSearch,
  tableRef,
  ...rest
}: tableProps) => {
  const [openImport, setOpenImport] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const { user } = useAuth();

  const addingFunction = () => {
    if (!addButtonState) {
      addButtonFunction(!addButtonState);
    }
  };
  const handleFilter = () => {
    if (!addButtonState) {
      setFilter(!filterState);
    }
  };

  const showImportButton = (
    <div className="import-btn">
      {openImport ? (
        <ImportModal
          modelLink={importModelLink}
          importFile={importLink}
          isOpen={openImport}
          setPopUpState={setOpenImport}
        />
      ) : null}
      <ImportIcon />
      <input type="button" onClick={() => setOpenImport(!openImport)} />
      Importar
    </div>
  );

  const showUpdateButton = (
    <div className="import-btn">
      {openUpdate ? (
        <UpdateModal
          modelLink={updateModelLink}
          updateFile={updateLink}
          isOpen={openUpdate}
          setPopUpState={setOpenUpdate}
        />
      ) : null}
      <ImportIcon />
      <input type="button" onClick={() => setOpenUpdate(!openUpdate)} />
      Atualizar
    </div>
  );

  const showSearchBar = (
    <div className="search-bar-division">
      <input type="text" id="search-bar-gm" style={{ paddingLeft: '20px' }} />
      <SearchIcon />
    </div>
  );

  const showFilter = (
    <FilterButton
      type="button"
      onClick={handleFilter}
      isFiltering={isFiltering}
    >
      <FilterIcon />
      Filtros
    </FilterButton>
  );

  const showAddButton = (
    <button
      type="button"
      disabled={!user?.role?.permissions?.includes('canOpenOccurrence')}
      title={
        !user?.role?.permissions?.includes('canOpenOccurrence')
          ? 'Seu cargo não pode abrir ocorrências'
          : null
      }
      onClick={addingFunction}
    >
      <PlusIcon />
      {addButtonText}
    </button>
  );

  const showExportButton = (
    <button type="button">
      <ExportIcon />
      Exportar
    </button>
  );
  return (
    <MainContainer>
      <Controllers>
        <div className="filter-column">
          {search ? showSearchBar : null}
          {filter ? showFilter : null}
        </div>

        <div className="actions-column">
          {addButtonText ? showAddButton : null}
          {importButton ? showImportButton : null}
          {updateButton ? showUpdateButton : null}
          {exportButton ? showExportButton : null}
        </div>

        <div className="filter-column-mobile">
          <h5>{title}</h5>
          {filter ? showFilter : null}
        </div>
      </Controllers>

      <Table>
        <MaterialTable
          title={title}
          columns={column}
          data={data}
          tableRef={tableRef}
          onSearchChange={handleSearch}
          onChangePage={handleLastPage}
          options={{
            search: true,
            sorting: false,
            exportButton: true,
            pageSizeOptions: [5, 10, 20, 50, 100, 200, 400, 600],
            ...rest.options,
          }}
          editable={{
            isEditable: () => false,
          }}
          localization={{
            toolbar: {
              exportCSVName:
                ((rest.localization || {}).toolbar || {}).exportCSVName ||
                'Exportar como .CSV',
              exportPDFName: 'Exportar como .PDF',
            },
          }}
        />
      </Table>
    </MainContainer>
  );
};

export default TableWControllers;
