/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { ValueType } from 'rsuite/lib/DateRangePicker';
import { formatISO } from 'date-fns';
import api from '../../services/api';
import Table from '../../components/Table';

import { MainContainer, Container } from './styles';
import { useError } from '../../hooks/errors';
import ColumnGraph from '../DashboardGeneral/components/devolucao-grafico-coluna';
import Filter from '../../components/PDVfilter';
import { FilterButton } from '../../components/tableWControllers/styles';
import { ReactComponent as FilterIcon } from '../../icons/filter-icon.svg';

export default function Rede() {
  const [data, setData] = useState<any>();
  const [graphData, setGraphData] = useState<any>();

  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [isFiltering, setIsFiltering] = useState<ValueType>();
  const [loading, setLoading] = useState(false);

  const { emitError } = useError();

  const handleFilter = async (e: ValueType | undefined) => {
    setIsFiltering(e);
    if (!e) return;
    buscarDashboard({
      startAt: formatISO(e[0]),
      endAt: formatISO(new Date(formatISO(e[1])).setHours(23, 59, 59)),
    });
  };

  useEffect(() => {
    buscarDashboard({});
  }, []);

  const buscarDashboard = params => {
    setLoading(true);
    api
      .get('/dashboard/network', { params })
      .then((res: any) => {
        setData(res.data);
        setLoading(false);
      })
      .catch(error => {
        emitError(error);
        setLoading(false);
      });
  };

  const limparFiltro = (e: ValueType | undefined) => {
    setIsFiltering(e);
    buscarDashboard({});
  };

  const columnRede = [
    {
      title: 'Rede',
      field: 'name',
    },
    {
      title: 'Total de Visitas em PDVs da Rede',
      field: 'totalApproval',
    },
    {
      title: 'Total de Devoluções',
      field: 'allTotal',
    },
    {
      title: '% de Devoluções',
      field: 'percentApproval',
      render: rowData => `${rowData.percentApproval?.toFixed(0)}%`,
    },
  ];

  useEffect(() => {
    if (!data) return;

    const graphParsedData = data?.occurrencesByNetwork?.map((network: any) => ({
      name: network?.name,
      devolutions: network?.totalApproval || 0,
    }));

    const sorted = graphParsedData?.sort(
      (a, b) => b.devolutions - a.devolutions,
    );

    setGraphData(sorted.slice(0, 10));
  }, [data]);

  const config = {
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
  };

  return (
    <MainContainer>
      <div style={{ paddingBottom: '15px', paddingLeft: '20px' }}>
        <FilterButton
          type="button"
          onClick={() => setOpenFilter(!openFilter)}
          isFiltering={!!isFiltering}
        >
          <FilterIcon />
          Filtros
        </FilterButton>
      </div>

      <Container>
        <ColumnGraph
          title="Devolução por rede"
          data={graphData}
          overrideConfig={config}
          isLoading={loading}
        />
      </Container>

      <Container style={{ marginTop: '37px' }}>
        <div className="dash-card">
          <Table
            title="Devoluções por rede - Detalhes"
            column={columnRede}
            data={data?.occurrencesByNetwork}
            isLoading={loading}
          />
        </div>
      </Container>

      {openFilter ? (
        <Filter
          isOpen={openFilter}
          setPopUpState={setOpenFilter}
          handleChange={handleFilter}
          handleClearFilters={limparFiltro}
          date={isFiltering}
        />
      ) : null}
    </MainContainer>
  );
}
