/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

interface ButtonProps {
  aproved: boolean;
}

export const Container = styled.div<ButtonProps>`
  width: 100%;
  max-width: 328px;

  button {
    width: 100%;

    padding: 13px 0;

    background: ${prop =>
      prop.aproved
        ? 'white'
        : ' linear-gradient(225deg, #fd4084 0%, #af0404 99.99%)'};
    border: ${prop => (prop.aproved ? '1px solid #fd4084' : null)};
    border-radius: 12px;
    color: ${prop => (prop.aproved ? '#fd4084 ' : 'white')};
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 5%;
    transition: 0.3s;

    &:hover {
      background: linear-gradient(225deg, #fd4084 0%, #fd4084 99.99%);
      color: white;
    }
  }
`;
