import styled, { keyframes } from 'styled-components';

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:9000px;
  opacity:1;
}

`;

export const Container = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: calc(11 * 99);
`;

export const PopUp = styled.div`
  width: 100%;
  max-width: 579px;
  max-height: 493px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background: white;
  padding: 14px 36px 14px 26px;
  overflow: hidden;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  animation: 0.4s ${entranceAnim} ease;

  .pop-up-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;

    button {
      position: absolute;
      top: 10px;
      right: 16px;

      svg {
        width: 16px;
      }
    }
  }

  @media screen and (max-width: 880px) {
    .line {
      max-width: 240px !important;
    }
  }

  .approval-row {
    min-width: 100px;
    margin-top: 36px;
    position: relative;

    .title {
      width: 100%;
      font-weight: 600;
      text-align: center;
      margin-bottom: 16px;
    }
    .person-row {
      display: flex;
      justify-content: space-between;
    }

    .person {
      display: flex;
      flex-direction: column;
      align-items: center;

      h5,
      h6 {
        text-align: center;
      }
    }

    .line {
      width: 100%;
      max-width: 440px;
      height: 1px;
      background: var(--green);
      margin: 30px auto;
      display: block;
      position: relative;

      span {
        width: 30px;
        height: 30px;
        background: var(--green);
        border-radius: 99px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &::before,
      &::after {
        content: '';
        width: 30px;
        height: 30px;
        background: var(--green);
        border-radius: 99px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }
  }
`;
