/* eslint-disable consistent-return */
import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import { User } from '../models';
import { useError } from './errors';

export interface UsersContextData {
  users: User[] | null;
  getUsers(): Promise<void>;
  isLoading: boolean;
}
export const UsersContext = createContext<UsersContextData>(
  {} as UsersContextData,
);
export const UsersProvider: React.FC = ({ children }: any) => {
  const [users, setUsers] = useState<User[]>();
  const { emitError } = useError();
  const [loading, setLoading] = useState(false);

  const getUsers = async () => {
    try {
      setLoading(true);

      const response = await api.get('/users');
      setUsers(response.data);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      emitError(error);
    }
  };

  const updateUser = async () => {
    console.log('a');
  };

  return (
    <UsersContext.Provider
      value={{
        isLoading: loading,
        users,
        getUsers,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export function useUsers(): UsersContextData {
  const context = useContext(UsersContext);
  return context;
}
