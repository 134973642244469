/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

interface ButtonProps {
  disableProp: boolean;
}

export const Container = styled.div<ButtonProps>`
  width: 100%;
  max-width: 328px;
  > button {
    width: 100%;
    padding: 13px 0;
    /* background: var(--lightBlue)   ; */
    background: ${props =>
      props.disableProp ? 'var(--mediumGrey)' : 'var(--lightBlue)'};
    border-radius: 12px;
    color: white !important;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 5%;
    transition: 0.3s;
    pointer-events: ${props => (props.disableProp ? 'none' : 'unset')};

    &:hover {
      background: var(--lightBlue);
    }
  }
`;
