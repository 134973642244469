import styled, { keyframes } from 'styled-components';

const animation = keyframes`

from {
  left:700px;
}

to {
  left:00px;
}

`;

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:9000px;
  opacity:1;
}

`;

export const Container = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: calc(9 * 99);
`;

interface PdvProps {
  size: string;
}

export const PopUp = styled.div<PdvProps>`
  width: 100%;
  max-width: ${props => (props.size === 'pic' ? '500px' : '791px')};
  min-height: 40px;
  left: 50%;
  transform: translateX(-50%);
  top: 10%;
  position: absolute;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 14px 16px;
  overflow: hidden;
  animation: 0.5s ${entranceAnim} ease;
  .pop-up-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding: 0 76px 0 24px;

    button {
      position: absolute;
      top: 10px;
      right: 16px;

      svg {
        width: 16px;
      }
    }
  }

  form {
    position: relative;
  }

  .fields-section {
    position: relative;

    input,
    select {
      margin-top: 6px;
    }
    .information-section,
    .end-section,
    .pic-section {
      width: 100%;
      /* flex-basis: 700px; */
      /* flex-grow: 1; */
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }

    .action {
      animation: 0.5s ease ${animation} forwards;
      position: relative;
      opacity: 1;
      pointer-events: unset;
    }
  }

  .popUp-controllers {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .active {
      color: var(--white);
      background: linear-gradient(225deg, #32bdf9 0%, #2e7feb 100%);
    }

    button {
      padding: 8px 20px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      color: var(--mediumGrey);
      font-size: var(--h6Size);
      font-weight: var(--h6Weight);
      letter-spacing: var(--h6Letter);
      line-height: var(--h6Line);
    }
  }

  .conditional-content {
    margin-top: 24px;
    padding: 0 35px;

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      li {
        flex-basis: 328px;
        flex-grow: 1;
        list-style: none;
      }
    }
  }

  .step-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    justify-content: center;
    align-items: center;

    .switchers {
      margin-bottom: 28px;
      justify-content: center;
      display: flex;
      gap: 24px;
    }

    .cancel-button {
      margin-top: 12px;
    }
  }
`;
