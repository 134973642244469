import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as OcorrenciaIcon } from '../../../icons/ocorrencia-icon.svg';
import { ReactComponent as MessageIcon } from '../../../icons/message-icon-mobile.svg';
import PopUpAddOccurrence from '../../PopsUpOcurence/addOcurrence_mobile';

import { Container } from './styles';
import { useAuth } from '../../../hooks/auth';

export default function index() {
  const [addOccurrece, setAddOccurrence] = useState(false);
  const { user } = useAuth();

  return (
    <Container>
      <NavLink
        to="/Ocorrência/Central de Ocorrências"
        className="footer-button"
      >
        <OcorrenciaIcon />
        Ocorrências
      </NavLink>

      <button
        type="button"
        className="new-ocurrence footer-button"
        disabled={!user?.role?.permissions?.includes('canOpenOccurrence')}
        onClick={() => setAddOccurrence(!addOccurrece)}
      >
        <span> +</span>
        Nova Ocorrência
      </button>
      <NavLink to="/notificacoes" className="footer-button">
        <MessageIcon />
        Mensagens
      </NavLink>

      {addOccurrece ? (
        <PopUpAddOccurrence
          isOpen={addOccurrece}
          setPopUpState={setAddOccurrence}
        />
      ) : null}
    </Container>
  );
}
