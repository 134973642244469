import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { ReactComponent as EyeOff } from '../../icons/eye-off.svg';
import { ReactComponent as EyeON } from '../../icons/eye-on.svg';
import Table from '../../components/Table';
import { Container } from './styles';
import { useError } from '../../hooks/errors';
import { useNotifications } from '../../hooks/notifications';

export default function NotificationsPage() {
  const { emitError } = useError();
  const { notifications, setManualNotification, getNotifications } =
    useNotifications();

  const sortNotifications = (n: any[]) =>
    n?.sort((a, b) => `${b.create_at}`.localeCompare(a.create_at));

  useEffect(() => {
    if (!notifications?.length) getNotifications();
  }, []);

  const handleToggleRead = (id: string) => {
    api
      .put(`/notifications/${id}`)
      .then(response => {
        const list = [
          ...notifications.filter(it => it.id !== id),
          response.data,
        ];
        setManualNotification(sortNotifications(list));
      })
      .catch(error => emitError(error));
  };

  const column = [
    {
      title: 'Mensagens',
      field: 'message',
      cellStyle: {
        width: '100%',
      },
    },
    {
      title: 'Data',
      field: 'create_at',
      cellStyle: {
        minWidth: '170px',
      },
      render: rowData =>
        `${new Date(rowData.create_at).toLocaleDateString()} às ${new Date(
          rowData.create_at,
        ).toLocaleTimeString()} `,
    },
  ] as any;

  return (
    <Container>
      <h5>Notificações</h5>
      <div className="table">
        <Table
          column={[
            ...column,

            {
              width: '10%',
              cellStyle: {
                textAlign: 'right',
              },

              render: (rowData: any) =>
                rowData.read ? (
                  <EyeOff
                    title="Marcar como não lido"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleToggleRead(rowData.id)}
                  />
                ) : (
                  <EyeON
                    title="Marcar como lido"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleToggleRead(rowData.id)}
                  />
                ),
            },
          ]}
          data={notifications}
        />
      </div>
    </Container>
  );
}
