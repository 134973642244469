/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ApprovePopUp from '../../ApprovePopUp';
import { ReactComponent as CloseButton } from '../../../icons/blue-close-button-icon.svg';
import BlueButton from '../../blueButton';
import RedButton from '../../redButton';
import { Container, PopUp } from './styles';
import Input from '../../Form/input';
import api from '../../../services/api';
import Delete from '../../DeletePopUp';
import { useError } from '../../../hooks/errors';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  rowData?: string[] | any;
};

CanalPopUp.defaultProps = {
  rowData: false,
};

export default function CanalPopUp({
  isOpen,
  setPopUpState,
  rowData,
}: popUpProp) {
  const { emitError } = useError();
  const popUpRef = useRef(null);
  const formRef = useRef(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [deletePdv, setDeletePdv] = useState(false);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  async function handlePost(data: any) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome do canal é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('/channels', data);
      window.location.reload();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios!');
        setShowConfirmation(false);

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  async function handlePut(data: any) {
    try {
      await api.put(`/channels/${rowData.id}`, data);
      window.location.reload();
    } catch (error: any) {
      formRef.current.setFieldError('name', 'O Nome do Canal é Obrigatória');
      emitError(error);
    }
  }

  async function handleDelete() {
    try {
      await api.delete(`/channels/${rowData.id}`);
      toast.success('Canal deletado');
      window.location.reload();
    } catch (error: any) {
      toast.error('Canal referenciado por outra tabela');
    }
  }

  return (
    <Container>
      <PopUp ref={popUpRef}>
        {deletePdv ? (
          <Delete
            title="Canal"
            isOpen={deletePdv}
            setCustomState={handleDelete}
            setPopUpState={setDeletePdv}
          />
        ) : null}

        <div className="pop-up-header">
          <p>Canal</p>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>
        <Form ref={formRef} onSubmit={rowData ? handlePut : handlePost}>
          <label htmlFor="name">
            Nome
            <span style={{ display: 'inline', color: 'red' }}> *</span>
          </label>

          {rowData ? (
            <Input
              name="name"
              value={rowData.name}
              id="name"
              placeholder={rowData.name}
            />
          ) : (
            <Input name="name" id="name" placeholder="Nome" />
          )}

          {rowData ? (
            <div className="edit-pdv">
              <RedButton
                title="Apagar"
                setCustomState={setDeletePdv}
                customState={deletePdv}
              />
              <BlueButton
                title="Editar"
                setCustomState={setShowConfirmation}
                customState={showConfirmation}
              />
            </div>
          ) : (
            <div className="button-field">
              <BlueButton
                title="salvar"
                setCustomState={setShowConfirmation}
                customState={showConfirmation}
              />

              <button
                type="button"
                className="cancel-button"
                onClick={() => setPopUpState(!isOpen)}
              >
                Cancelar
              </button>
            </div>
          )}

          {showConfirmation ? (
            <ApprovePopUp
              sampleText="Adicionar Canal?"
              isOpen={showConfirmation}
              setPopUpState={setShowConfirmation}
            />
          ) : null}
        </Form>
      </PopUp>
    </Container>
  );
}
