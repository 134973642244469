import React, { useState, useEffect } from 'react';
import { formatISO } from 'date-fns';
import { toast } from 'react-toastify';
import { ValueType } from 'rsuite/lib/DateRangePicker';
import api from '../../services/api';
import { ReactComponent as Dots } from '../../icons/3dots-icon.svg';
import ContratoPopUp from '../../components/PDVPopUps/Contrato';
import TableWControllers from '../../components/tableWControllers';
import Filter from '../../components/PDVfilter';
import { useError } from '../../hooks/errors';

import { Container } from './styles';

export default function Contrato() {
  const [tableData, setTableData] = useState<any[]>();
  const [openEdit, setOpenEdit] = useState(false);
  const [addRowData, setAddRowData] = useState(false);
  const [addContrato, setAddContrato] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [isFiltering, setIsFiltering] = useState<ValueType>();
  const { emitError } = useError();

  const parseData = (list: any[]): any => {
    const parsed = list?.map((it: any) => ({
      ...it,
      parsedZero: it?.zero ? 'Sim' : 'Não',
    }));

    return parsed;
  };

  const handleFilter = async (e: ValueType) => {
    setIsFiltering(e);
    if (!e) return;
    try {
      const { data: response } = await api.get('/contracts', {
        params: {
          startAt: formatISO(e[0]),
          endAt: formatISO(new Date(formatISO(e[1])).setHours(23, 59, 59)),
        },
      });

      setFilteredData(response);
    } catch (error) {
      emitError(error);
    }
  };

  useEffect(() => {
    api
      .get('/contracts')
      .then((res: any) => {
        const parsed = parseData(res.data);
        setTableData(parsed);
      })
      .catch(error => {
        emitError(error);
      });
  }, []);

  const columnContrato = [
    {
      title: 'Nome Contrato',
      field: 'name',
    },
    {
      title: 'Troca zero',
      field: 'parsedZero',
    },
  ];

  return (
    <Container>
      <TableWControllers
        importLink="contract"
        importModelLink="https://pdv-general-mills.s3.sa-east-1.amazonaws.com/63df5ec0888b47af0d658c2101c3960c-Contratos.xlsx"
        addButtonText="Adicionar Contrato"
        filter
        filterState={openFilter}
        setFilter={setOpenFilter}
        title="Contrato"
        isFiltering={!!isFiltering}
        column={[
          ...columnContrato,

          {
            width: '10%',
            cellStyle: {
              textAlign: 'right',
            },

            render: (rowData: any) => (
              <button
                type="button"
                onClick={() => {
                  setAddRowData(rowData);
                  setOpenEdit(true);
                }}
              >
                <Dots />
              </button>
            ),
          },
        ]}
        data={isFiltering ? filteredData : tableData}
        addButtonState={addContrato}
        addButtonFunction={setAddContrato}
      />

      {addContrato ? (
        <ContratoPopUp isOpen={addContrato} setPopUpState={setAddContrato} />
      ) : null}

      {openEdit ? (
        <ContratoPopUp
          isOpen={openEdit}
          setPopUpState={setOpenEdit}
          rowData={addRowData}
        />
      ) : null}

      {openFilter ? (
        <Filter
          isOpen={openFilter}
          setPopUpState={setOpenFilter}
          handleChange={handleFilter}
          handleClearFilters={setIsFiltering}
          date={isFiltering}
        />
      ) : null}
    </Container>
  );
}
