/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Select from '../../../../Form/select';
import Input from '../../../../Form/input';
import Photo from '../../../../Form/photo';
import BlueButton from '../../../../blueButton';
import { Container, PopUp } from './styles';
import { ReactComponent as CloseButton } from '../../../../../icons/blue-close-button-icon.svg';
import api from '../../../../../services/api';
import { useAuth } from '../../../../../hooks/auth';
import { useCategories } from '../../../../../hooks/categories';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  finalData?: string[] | any;
  pdvId: string;
  howMuch: number;
  selectCategory: boolean;
  setSelectCategory: any;
};

AddProductPopUp.defaultProps = {
  finalData: false,
};

export default function AddProductPopUp({
  isOpen,
  setPopUpState,
  finalData,
  howMuch,
  selectCategory,
  setSelectCategory,
  pdvId,
}: popUpProp) {
  const [steps, setSteps] = useState('category');
  const [showInfo, setShowInfo] = useState([]);
  const [seeResults, setSeeResults] = useState(false);
  const { categories, getCategories } = useCategories();

  const popUpRef = useRef(null);
  const { user } = useAuth();

  useEffect(() => {
    if (!categories?.length) getCategories();
  }, []);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setSteps('category');
    }
  }, [isOpen]);

  useEffect(() => {
    setSeeResults(!seeResults);
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  useEffect(() => {
    if (finalData.length > 0) setShowInfo(finalData);
  }, [finalData]);

  async function getProductId() {
    const currentValue = (
      document.getElementById('idProductSku') as HTMLInputElement
    ).value;

    const insertID = document
      .getElementById('idProduct')
      .getElementsByTagName('input')[0];

    if (currentValue?.length < 3) return;
    try {
      const product = await api.get(`/products/${currentValue}`);
      insertID.value = product?.data?.id;
      toast.success('SKU encontrado!');
      setSteps('confirm');
    } catch (error: any) {
      toast.error('SKU incorreto!');
    }
  }

  return (
    <Container isOpen={isOpen}>
      <PopUp stepsProps={steps} ref={popUpRef}>
        <div className="pop-up-header-addProduct">
          <p> Adicionar Produto</p>

          <button
            type="button"
            onClick={() => setPopUpState(!isOpen)}
            className="close-button"
          >
            <CloseButton />
          </button>
        </div>

        <div className="product-category  product-steps">
          <h6> Qual a categoria dos produtos? </h6>

          {/* <Select name="category" fields={['SECO', 'HD']} />
           */}

          <select
            name="Categoria"
            onChange={e => setSelectCategory(e?.target?.value)}
          >
            <option value={null} disabled hidden selected>
              Selecionar categoria
            </option>
            {categories?.map(category => (
              <option value={category.name}>{category.name}</option>
            ))}
          </select>
          <h6 className="lembrete">
            Lembre-se só pode haver uma categoria de produto por ocorrência
          </h6>

          <div className="buttons-section">
            <BlueButton
              title="Próximo"
              setCustomState={setSteps}
              customState="devolution"
            />
            <button
              type="button"
              className="cancel-button"
              onClick={() => setPopUpState(!isOpen)}
            >
              Cancelar
            </button>
          </div>
        </div>

        <div className="product-devolution product-steps">
          <h6> Qual o motivo da devolução? </h6>

          <Select
            name={`products[${howMuch}].justification`}
            fields={['Qualidade', 'Embalagem', 'Validade', 'Quebra de Freezer']}
          />

          <div className="buttons-section">
            <BlueButton
              title="Próximo"
              setCustomState={setSteps}
              customState="sku"
            />
            <button
              type="button"
              className="cancel-button"
              onClick={() => setPopUpState(!isOpen)}
            >
              Cancelar
            </button>
          </div>
        </div>

        <div className="product-sku product-steps">
          <h6> Qual o SKU do produto? </h6>

          <Input id="idProductSku" name="sku" placeholder="0000" />
          <span
            id="idProduct"
            style={{
              opacity: '0',
              pointerEvents: 'none',
              position: 'absolute',
            }}
          >
            <Input name={`products[${howMuch}].product_id`} />
            <Input name="pdv_id" value={pdvId} />
            {user.teams.length <= 0 ? null : (
              <Input name="team_id" value={user.teams[0].id} />
            )}
          </span>

          {/* <button type="button" className="search-button">
            Pesquisar por lista
          </button> */}

          <div className="buttons-section">
            <BlueButton
              title="Próximo"
              setCustomState={getProductId}
              customState="confirm"
            />
            <button
              type="button"
              className="cancel-button"
              onClick={() => setPopUpState(!isOpen)}
            >
              Cancelar
            </button>
          </div>
        </div>

        <div className="product-confirmation product-steps">
          <h6> Confirme o Lote do produto </h6>
          <Input name={`products[${howMuch}].batch`} placeholder="Lote" />

          <h6> Confirme a validade do produto </h6>
          <Input
            name={`products[${howMuch}].validity`}
            placeholder="10/10/2010"
          />

          <div className="buttons-section">
            <BlueButton
              title="Próximo"
              setCustomState={setSteps}
              customState="quantity"
            />
            <button
              type="button"
              className="cancel-button"
              onClick={() => setPopUpState(!isOpen)}
            >
              Cancelar
            </button>
          </div>
        </div>

        <div className="product-quantity  product-steps">
          <h6> Qual a quantidade de produtos a ser devolvida? </h6>

          <Input name={`products[${howMuch}].quantity`} placeholder="0000" />

          <div className="buttons-section">
            <BlueButton
              title="Próximo"
              setCustomState={setSteps}
              customState="value"
            />
            <button
              type="button"
              className="cancel-button"
              onClick={() => setPopUpState(!isOpen)}
            >
              Cancelar
            </button>
          </div>
        </div>

        <div className="product-value  product-steps">
          <h6> Valor Unitário </h6>

          <Input
            name={`products[${howMuch}].sell_price`}
            type="number"
            placeholder="R$ 000,00"
          />

          <div className="buttons-section">
            <BlueButton
              title="Próximo"
              setCustomState={setSteps}
              customState="pic"
            />
            <button
              type="button"
              className="cancel-button"
              onClick={() => setPopUpState(!isOpen)}
            >
              Cancelar
            </button>
          </div>
        </div>

        <div className="product-pic  product-steps">
          <h6> Imagem do Produto </h6>

          <Photo
            name=""
            photoName={`products[${howMuch}].photos[0]`}
            hasDescription={false}
            hasControllers={false}
          />

          <div className="final-data">
            <ul>
              {showInfo.map(e => (
                <li>
                  <h6> {e.title} </h6> <h6> {e.content} </h6>
                </li>
              ))}
            </ul>
          </div>

          <div className="buttons-section">
            {!seeResults ? (
              <BlueButton
                typeOf="submit"
                title="Confirmar a adição de produto"
              />
            ) : (
              <BlueButton
                title="Próximo"
                setCustomState={setSeeResults}
                customState={seeResults}
              />
            )}

            <button
              type="button"
              className="cancel-button"
              onClick={() => setPopUpState(!isOpen)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </PopUp>
    </Container>
  );
}
