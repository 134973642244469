/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useError } from '../../../hooks/errors';
import api from '../../../services/api';
import { ReactComponent as CloseButton } from '../../../icons/blue-close-button-icon.svg';
import BlueButton from '../../blueButton';
import RedButton from '../../redButton';
import SwitchButton from '../../switchButton';
import { Container, PopUp } from './styles';
import Input from '../../Form/input';
import { useRegionals } from '../../../hooks/regional';
import { useNetworks } from '../../../hooks/Network';
import { useContracts } from '../../../hooks/contracts';
import { useChannel } from '../../../hooks/channels';
import Select from '../../Form/select';
import Photo from '../../Form/photo';
import Delete from '../../DeletePopUp';
import ApprovePopUp from '../../ApprovePopUp';
import CancelPopUp from '../../CancelPopUp';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  rowData?: string[] | any;
};

PDVPopUp.defaultProps = {
  rowData: false,
};

export default function PDVPopUp({
  isOpen,
  setPopUpState,
  rowData,
}: popUpProp) {
  const [steps, setSteps] = useState('info');
  const { regionals, getRegional } = useRegionals();
  const { networks, getNetwork } = useNetworks();
  const { contracts, getContract } = useContracts();
  const { channel, getChannel } = useChannel();
  const { emitError } = useError();
  const [channels, setChannels] = useState([]);
  const [networkFields, setNetworkFields] = useState([]);
  const [contractFields, setContractFields] = useState([]);
  const [networkRegionals, setNetworkRegionals] = useState([]);
  const [fieldPosition, setFieldPosition] = useState(1);
  const [deletePdv, setDeletePdv] = useState(false);
  const [initialData, setInitialData] = useState({});
  const popUpRef = useRef(null);
  const formRef = useRef(null);
  const [cancelAction, setCancelAction] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (rowData) {
      const getInitialData = {
        name: rowData.name,
        reference: rowData.reference,
        description: rowData.description,
        cnpj: rowData.details?.cnpj,
        zero: rowData.zero,
        enabled: rowData.enabled,
        address: {
          address: rowData.details?.address,
          complement: rowData.details?.complement,
          state: rowData.details?.state,
          number: rowData.details?.number,
          city: rowData.details?.city,
          district: rowData.details?.district,
        },
      };
      setInitialData(getInitialData);
    } else {
      return null;
    }
  }, []);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  const infoFields = [
    {
      title: 'PDV',
      type: 'input',
      name: 'name',
      isRequired: true,
      value: rowData?.name,
    },
    {
      title: 'CNPJ',
      type: 'input',
      name: 'cnpj',
      max: 14,
      isRequired: true,
      value: rowData?.details?.cnpj,
    },
    {
      title: 'Rede',
      type: 'select',
      name: 'network_id',
      isRequired: true,
      value: rowData?.network?.id,
      options: networkFields,
    },
    {
      title: 'Canal',
      type: 'select',
      name: 'channel_id',
      isRequired: true,
      value: rowData?.channel?.id,
      options: channels,
    },
    {
      title: 'Código SAP',
      type: 'input',
      name: 'reference',
      value: rowData?.reference,
    },
    {
      title: 'Contrato',
      type: 'select',
      name: 'contract_id',
      options: contractFields,
      isRequired: true,
      value: rowData?.contract?.id,
    },
    {
      title: 'Regional',
      type: 'select',
      name: 'regional_id',
      options: networkRegionals,
      isRequired: true,
      value: rowData?.regional?.id,
    },
  ];

  const endFields = [
    {
      title: 'Endereço',
      type: 'input',
      name: 'address.address',
      isRequired: true,
      value: rowData?.details?.address,
    },
    {
      title: 'Complemento',
      type: 'input',
      name: 'address.complement',
      value: rowData?.details?.complement,
    },
    {
      title: 'Estado',
      type: 'select',
      name: 'address.state',
      isRequired: true,
      value: rowData?.details?.state,
      options: [
        'AC',
        'AL',
        'AM',
        'AP',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RO',
        'RS',
        'RR',
        'SC',
        'SE',
        'SP',
        'TO',
      ],
    },
    {
      title: 'Número',
      type: 'input',
      name: 'address.number',
      isRequired: true,
      value: rowData?.details?.number,
    },
    {
      title: 'Cidade',
      type: 'input',
      name: 'address.city',
      isRequired: true,
      value: rowData?.details?.city,
    },
    {
      title: 'Bairro',
      type: 'input',
      name: 'address.district',
      isRequired: true,
      value: rowData?.details?.district,
    },
  ];

  async function handlePost(data: any) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome do PDV é obrigatório'),
        cnpj: Yup.string()
          .min(14, 'O CNPJ precisa ter ao menos 14 digitos')
          .required('o CNPJ é obrigatório'),
        reference: Yup.string(),
        network_id: Yup.string().required('Rede é obrigatória'),
        channel_id: Yup.string().required('Canal é obrigatório'),
        contract_id: Yup.string().required('Contrato é obrigatório'),
        regional_id: Yup.string().required('Regional é obrigatório'),
        description: Yup.string().default(''),
        photo: Yup.string().default(''),
        address: Yup.object().shape({
          address: Yup.string().required('Endereço é obrigatório'),
          state: Yup.string()
            .max(2, 'Estado, campo máximo de 2 caracteres')
            .required('O complemento é obrigatório'),
          number: Yup.string().required('O número é obrigatório'),
          city: Yup.string().required('A cidade é obrigatória'),
          district: Yup.string().required('O estado é obrigatório'),
        }),
      });

      await schema.validate(data, { abortEarly: false });

      const postPdv = await api.post('/pdvs', data);
      window.location.reload();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios');
        setShowConfirmation(false);
        setSteps('info');

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      } else emitError(error);
    }
  }

  async function handlePut(data: any) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O Nome do PDV é obrigatório'),
        cnpj: Yup.string()
          .min(14, 'O CNPJ precisa ter ao menos 14 digitos')
          .required('o CNPJ é obrigatório'),
        network_id: Yup.string().required('Rede é obrigatória'),
        channel_id: Yup.string().required('Canal é obrigatório'),
        contract_id: Yup.string().required('Contrato é obrigatório'),
        regional_id: Yup.string().required('Regional é obrigatório'),
        reference: Yup.string(),
        description: Yup.string(),
        photo: Yup.string(),
        address: Yup.object().shape({
          address: Yup.string(),
          state: Yup.string().max(2, 'Estado, campo máximo de 2 caracteres'),
          number: Yup.string(),
          city: Yup.string(),
          district: Yup.string(),
        }),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { address } = data;

      const removeUnwantedItem = delete data.address;
      data = { ...data, ...address };

      // console.log(data);

      const putPdv = await api.put(`/pdvs/${rowData.id}`, data);
      window.location.reload();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios');
        setShowConfirmation(false);
        setSteps('info');

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      } else emitError(error);
    }
  }

  async function handleDelete() {
    try {
      await api.delete(`/pdvs/${rowData.id}`);
      toast.success('PDV deletado');
      window.location.reload();
    } catch (error: any) {
      toast.error('PDV referenciado por outra tabela');
    }
  }

  useEffect(() => {
    if (steps === 'info') {
      setFieldPosition(1);
    } else if (steps === 'end') {
      setFieldPosition(2);
    } else if (steps === 'pic') {
      setFieldPosition(3);
    }
  }, [steps]);

  useEffect(() => {
    if (!regionals) getRegional();
    if (!contracts) getContract();
    if (!networks) getNetwork();
    if (!channels) getChannel();

    const newChannels = channel?.map((e: any) => ({
      name: e.name,
      id: e.id,
    }));

    const newNetworks = networks?.map((e: any) => ({
      name: e.name,
      id: e.id,
    }));
    const newContracts = contracts?.map((e: any) => ({
      name: e.name,
      id: e.id,
    }));

    const newRegionals = regionals?.map((e: any) => ({
      name: e.name,
      id: e.id,
    }));

    setNetworkFields(newNetworks);
    setChannels(newChannels);
    setContractFields(newContracts);
    setNetworkRegionals(newRegionals);
  }, []);

  return (
    <Container>
      <PopUp ref={popUpRef} size={steps}>
        {deletePdv ? (
          <Delete
            title="Ponto de Venda"
            isOpen={deletePdv}
            setCustomState={handleDelete}
            setPopUpState={setDeletePdv}
          />
        ) : null}

        {cancelAction ? (
          <CancelPopUp
            sampleText="Cancelar abertura do Ponto de Venda?"
            isOpen={cancelAction}
            setCustomState={setPopUpState}
            customState={isOpen}
            setPopUpState={setCancelAction}
          />
        ) : null}

        <div className="pop-up-header">
          <h5> Ponto de Venda</h5>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>
        <div className="popUp-controllers">
          <button
            type="button"
            className={steps === 'info' ? 'active' : ''}
            onClick={() => setSteps('info')}
          >
            Informação
          </button>
          <button
            type="button"
            className={steps === 'end' ? 'active' : ''}
            onClick={() => setSteps('end')}
          >
            Endereço
          </button>
          <button
            type="button"
            className={steps === 'pic' ? 'active' : ''}
            onClick={() => setSteps('pic')}
          >
            Foto
          </button>
        </div>

        <div className="conditional-content">
          <Form
            ref={formRef}
            onSubmit={rowData ? handlePut : handlePost}
            initialData={initialData}
          >
            <div className=" fields-section teste-action">
              <div
                className={
                  fieldPosition === 1
                    ? 'information-section action'
                    : 'information-section'
                }
              >
                <ul>
                  {infoFields.map(e => (
                    <li>
                      <h6>
                        {e.title}
                        {e?.isRequired && (
                          <span style={{ display: 'inline', color: 'red' }}>
                            {' '}
                            *
                          </span>
                        )}
                      </h6>

                      {e.type === 'select' ? (
                        <Select
                          value={e?.value}
                          name={e.name}
                          fields={e.options}
                          optValue
                        />
                      ) : (
                        <Input
                          value={e?.value}
                          type="text"
                          maxLength={e?.max || undefined}
                          name={e.name}
                        />
                      )}
                    </li>
                  ))}
                </ul>

                {!rowData ? (
                  <div className="step-buttons">
                    <div className="switchers">
                      <SwitchButton
                        title="Ativo"
                        name="enabled"
                        isTrue={
                          // eslint-disable-next-line eqeqeq
                          rowData?.enabled != undefined
                            ? rowData?.enabled
                            : true
                        }
                      />
                      <SwitchButton
                        title="Troca Zero"
                        name="zero"
                        isTrue={rowData?.zero}
                      />
                    </div>
                    <BlueButton
                      setCustomState={setSteps}
                      customState="end"
                      title="Próximo"
                      typeOf="button"
                    />

                    <button
                      type="button"
                      className="cancel-button"
                      onClick={() => setPopUpState(!isOpen)}
                    >
                      Cancelar
                    </button>
                  </div>
                ) : (
                  <div className="step-buttons">
                    <div className="switchers">
                      <SwitchButton
                        title="Ativo"
                        name="enabled"
                        isTrue={rowData.enabled}
                      />
                      <SwitchButton
                        title="Troca Zero"
                        name="zero"
                        isTrue={rowData.zero}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div
                className={
                  fieldPosition === 2 ? 'end-section action' : 'end-section'
                }
              >
                <ul>
                  {endFields.map(e => (
                    <li>
                      <h6>
                        {e.title}
                        {e?.isRequired && (
                          <span style={{ display: 'inline', color: 'red' }}>
                            {' '}
                            *
                          </span>
                        )}
                      </h6>
                      {e.type === 'select' ? (
                        <Select
                          value={e?.value}
                          fields={e.options}
                          name={e.name}
                        />
                      ) : (
                        <Input value={e?.value} type="text" name={e.name} />
                      )}
                    </li>
                  ))}
                </ul>

                {!rowData ? (
                  <div className="step-buttons">
                    <BlueButton
                      setCustomState={setSteps}
                      customState="pic"
                      title="Próximo"
                    />

                    <button
                      type="button"
                      className="cancel-button"
                      onClick={() => setPopUpState(!isOpen)}
                    >
                      Cancelar
                    </button>
                  </div>
                ) : null}
              </div>
              <div
                className={
                  fieldPosition === 3 ? 'pic-section action' : 'pic-section'
                }
              >
                <Photo name="description" currentImage={rowData?.photo} />

                {!rowData ? (
                  <div className="step-buttons">
                    <BlueButton
                      setCustomState={setShowConfirmation}
                      customState={showConfirmation}
                      title="Próximo"
                    />

                    <button
                      type="button"
                      className="cancel-button"
                      onClick={() => setPopUpState(!isOpen)}
                    >
                      Cancelar
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            {!rowData ? null : (
              <div className="edit-pdv">
                <RedButton
                  title="Apagar"
                  typeOf="button"
                  setCustomState={setDeletePdv}
                  customState={deletePdv}
                />
                <BlueButton
                  title="Editar"
                  setCustomState={setShowConfirmation}
                  customState={showConfirmation}
                />
              </div>
            )}

            {showConfirmation ? (
              <ApprovePopUp
                sampleText="Adicionar Ponto de Venda?"
                isOpen={showConfirmation}
                setPopUpState={setShowConfirmation}
              />
            ) : null}
          </Form>
        </div>
      </PopUp>
    </Container>
  );
}
