import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const CascadingList = styled.li`
  width: 100%;
  margin: 3px 0;
  list-style: none;
  transition: 0.3s;

  button {
    font-family: Montserrat;
  }

  &:hover {
    span {
      color: var(--white);
    }
    button {
      &::before {
        background: rgba(214, 242, 254, 0.4);
      }
    }

    span {
      path,
      svg {
        fill: var(--white);
      }
    }
  }

  .nav-link {
    width: 100%;
    padding: 7px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    position: relative;

    &::before {
      content: '';
      font-family: Montserrat;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -40px;
      right: -40px;
      background: none;
      transition: 0.3s;
    }

    &::after {
      content: '';
      position: absolute;
      width: 4px;
      top: 0;
      bottom: 0;
      left: -24px;
      background: none;
      transition: 0.3s;
    }

    span {
      display: flex;
      align-items: center;
      font-family: Montserrat;
      font-size: 14px;
      color: var(--white);

      svg {
        width: 16px;
        margin-right: 12px;

        path {
          fill: var(--white);
        }
      }
    }
  }

  .angle-icon {
    width: 8px;
    transition: 0.3s;
    z-index: 2;

    path {
      fill: var(--white);
    }
  }

  .menu-itens {
    overflow: hidden;
    transition: 0.3s ease;
    padding-left: 30px;

    > li {
      padding: 5px 0;
      position: relative;
      transition: 0.3s;
      list-style: none;

      > a {
        display: block;
        text-decoration: none;
        color: var(--white);
        transition: 0.3s;
        font-size: var(--h6Size);
        font-weight: var(--h6Weight);
        letter-spacing: var(--h6Letter);
        line-height: var(--h6Line);
      }

      &:nth-last-child(1) {
        display: none;
      }
    }

    .active-link-gm {
      font-weight: 600;

      &::before {
        content: '• ';
        color: var(--orange);
      }
    }
  }
`;
