import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import DefaultLayout from './pages/defalutModel';
import Globalstyle from './globalStyle/globalStyle';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <>
    <Globalstyle />
    <App />
  </>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();
