import styled, { keyframes } from 'styled-components';

interface Props {
  title?: string;
  customState?: any;
  setCustomState?: any;
  typeOf?: any;
  aproved?: boolean;
}

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:900px;
  opacity:1;
}

`;

export const Container = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: calc(9 * 99);

  @media screen and (max-width: 880px) {
    top: 54px;
  }
`;

export const RedButton = styled.button<Props>`
  width: 100%;

  padding: 13px 0;

  background: ${prop =>
    prop.aproved
      ? 'white'
      : ' linear-gradient(225deg, #fd4084 0%, #af0404 99.99%)'};
  border: ${prop => (prop.aproved ? '1px solid #fd4084' : null)};
  border-radius: 12px;
  color: ${prop => (prop.aproved ? '#fd4084 ' : 'white')};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 5%;
  transition: 0.3s;

  &:hover {
    background: linear-gradient(225deg, #fd4084 0%, #fd4084 99.99%);
    color: white;
  }
`;

export const PopUp = styled.div`
  width: 100%;
  max-width: 1011px;
  height: 700px;
  overflow-y: auto;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 14px 8px 16px 8px;
  position: relative;
  animation: 0.4s ease ${entranceAnim};

  @media screen and (max-width: 880px) {
    .edit-btn {
      display: none;
    }
  }

  .pop-up-header-mobile {
    width: 100%;
    min-height: 80px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 24px;
    margin-bottom: 12px;

    > h5 {
      font-family: 'Exo 2';
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 120%;
      margin-top: 23px;
    }

    .occurrence-options {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 26px;

      .close-button {
        svg {
          transform: rotate(180deg);
          margin-right: 6px;
        }
      }

      button {
        font-size: 10px;
      }
    }

    .approval-codigo {
      margin: 20px 0;
    }
  }

  .pop-up-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding: 0 76px 0 24px;
    margin-bottom: 12px;

    .close-button {
      position: absolute;
      top: 10px;
      right: 16px;

      svg {
        width: 16px;
      }
    }

    .occurrence-options {
      display: flex;
      gap: 26px;
    }

    button {
      font-weight: 400;
      transition: 0ms.3s;
      &:hover {
        color: var(--darkBlue);
        font-weight: 600;
      }
    }
  }

  .table-title {
    display: block;
    background: rgba(0, 173, 181, 0.05);
    padding: 8.5px 14px;
  }

  .table-list {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 8px 20px 20px 20px;
  }

  .table-content {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 6px;

    p,
    label {
      font-size: 14px;
      color: var(--darkGrey);
      font-weight: 700;

      letter-spacing: var(--pLetter);
      line-height: var(--pLine);
    }

    h6 {
      margin-top: 12px;
    }
    > a {
      margin-top: 12px;
      font-size: var(--h6Size);
      font-weight: var(--h6Weight);
      line-height: 14px;
    }
  }

  .table-section {
    border-radius: 20px;
    border: solid 1px var(--lightBlue);
    overflow: hidden;

    .MuiToolbar-regular {
      display: none;
    }
  }

  .status-occurrence {
    text-align: center;
  }

  .buttons-section {
    width: 100%;
    height: 56px;
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: -20px;
    left: 0;
    background: white;
    z-index: 99;

    .buttons {
      flex-basis: 400px;
      display: flex;
      gap: 21px;

      button {
        width: 100%;
        max-width: 233px;
      }
    }
  }

  .approval-codigo {
    padding: 13px 29px;
    border: solid 1px var(--lightBlue);
    border-radius: 12px;
    display: flex;
    align-items: center;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 120%;
    color: var(--lightBlue);
    &::before {
      content: 'Código de Aprovação:';
      margin-right: 5px;
      display: inline-block;
      color: var(--darkGrey);
    }
  }

  @media screen and (max-width: 880px) {
    max-height: unset;
    height: 100%;
    border-radius: 0px;

    .occurence-details,
    .client-details,
    .call-details {
      border-radius: 20px;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
      background: white;
      overflow: hidden;
    }

    .client-details,
    .call-details {
      margin: 15px 0;
    }

    .pop-up-header {
      display: none;
    }

    .buttons-section {
      .buttons {
        flex-grow: 1;
      }

      .approval-codigo {
        display: none;
      }
    }
    .pop-up-header-mobile {
      display: flex !important;
    }

    .table-list {
      flex-direction: column;
      gap: 0px;

      .table-content {
        padding: 11px 0px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        h6 {
          margin: 0;
        }
      }
    }
  }
`;
