/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import Input from './input';
import Textarea from './textarea';
import { useError } from '../../hooks/errors';

import { PhotoContainer, PhotoSection } from './styles/styles';
import { ReactComponent as Camera } from '../../icons/camera-icon-blue.svg';
import { ReactComponent as Xicon } from '../../icons/white-x-icon.svg';
import api from '../../services/api';

type photoProps = {
  name: string;
  photoName?: string;
  hasDescription?: boolean;
  hasControllers?: boolean;
  id?: any;
  currentImage?: any;
  reference?: any;
};

Photo.defaultProps = {
  photoName: 'photo',
  hasDescription: true,
  hasControllers: true,
  id: false,
  currentImage: '',
  reference: false,
};

export default function Photo({
  hasControllers,
  photoName,
  hasDescription,
  name,
  currentImage,
  id,
  reference,
}: photoProps) {
  const { emitError } = useError();

  const [image, setImage] = useState('');
  const [imageDetails, setImageDetails] = useState('');
  const [imageInformation, setImageInformation] = useState({ id });

  const descriptionField = (
    <div className="description-field">
      <label htmlFor="textarea">Descrição</label>

      <Textarea name={name} id="textarea" placeholder="Texto Descritivo" />
    </div>
  );

  async function handleUpload(e) {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await api.post(`/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      response.data.id = response.data.key;
      setImageInformation(response.data);
      setImage(response.data.url);
      setImageDetails(response.data.name);
      return response.data.url;
    } catch (error: any) {
      emitError(error);
      // eslint-disable-next-line no-useless-return, consistent-return
      return;
    }
  }

  async function handleDelete() {
    try {
      await api.delete(`/upload/${imageInformation.id}`);
      setImage('');
      toast.success('Imagem deletada com sucesso');
    } catch (error: any) {
      emitError(error);
    }
  }

  return (
    <PhotoContainer>
      {hasControllers ? <h6>Foto</h6> : null}

      <div className="photo-fields-section">
        <PhotoSection
          className="photo-section"
          url={
            image === '' || image === null || image === undefined
              ? currentImage
              : image
          }
        >
          <Input
            name={photoName}
            value={
              image === '' || image === null || image === undefined
                ? currentImage
                : image
            }
            type="hidden"
          />
          <input
            type="file"
            id="file"
            accept="images/*"
            onInput={e => handleUpload(e)}
          />

          {image === '' ? <Camera /> : null}
        </PhotoSection>

        {hasControllers ? (
          <div className="photo-controllers">
            <label htmlFor="file" className="search-box">
              {image === '' ? '  Nenhum Arquivo Selecionado' : imageDetails}
            </label>
            <label htmlFor="file" className="search-button">
              Procurar
            </label>
            <button
              type="button"
              className="cancel-button"
              onClick={handleDelete}
            >
              <Xicon />
            </button>
          </div>
        ) : null}
      </div>
      {hasDescription ? descriptionField : null}
    </PhotoContainer>
  );
}
