import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const MainContainer = styled.section`
  width: 100%;
  padding: 0 24px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 28px;

  .dash-card,
  .graphCard {
    flex-basis: 340px;
    flex-grow: 1;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    background: white;
    overflow: hidden;
  }

  .graphCard {
    padding: 20px;
    .graph {
      padding: 30px;
    }
    .title {
      margin-bottom: 40px;
    }
  }
`;
