/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import Table from '../../../../components/Table';
import Status from '../../../../components/Table/components/status';
import loadingGif from '../../../../img/loading.gif';
import { Container } from './styles';

interface Props {
  fullData: any;
  isLoading: boolean;
}

export default function InformacoesUltimaSemana({
  fullData,
  isLoading,
}: Props) {
  const column = [
    {
      title: 'PDV',
      field: 'pdv.name',
    },

    {
      title: 'Rede',
      field: 'pdv.network.name',
    },

    {
      title: 'Produtos para Devolução',
      field: 'num',
    },
    {
      title: 'Valor Total da Devolução',
      field: 'num',
      render: rowData => {
        let total = 0;
        rowData.details.map(detail => {
          total += detail.quantity * detail.sell_price;
        });
        return total.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        });
      },
    },

    {
      title: 'Status Ocorrência',
      field: 'status.status',
      render: rowData => <Status title={rowData?.status?.status} />,
    },

    {
      title: 'Data Leitura',
      field: 'create_at',
      render: rowData =>
        `${new Date(rowData.create_at).toLocaleDateString()} às ${new Date(
          rowData.create_at,
        ).toLocaleTimeString()} `,
    },
  ];

  return (
    <Container>
      {isLoading ? (
        <div className="carregando">
          <div className="carregando2" />
          <img src={loadingGif} alt="" />
        </div>
      ) : null}
      <Table
        title="Informações da Última Semana"
        column={column}
        data={fullData?.pastWeekOccurrences}
      />
    </Container>
  );
}
