/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { differenceInDays, formatISO } from 'date-fns';
import { Column } from 'material-table';
import { ValueType } from 'rsuite/lib/DateRangePicker';
import { toast } from 'react-toastify';
import { ReactComponent as Dots } from '../../icons/3dots-icon.svg';
import PopUpEditOccurrence from '../../components/PopsUpOcurence/popUpEditOccurrence';
import PopUpAddOccurrence from '../../components/PopsUpOcurence/addOcurrence';
import TableWControllers from '../../components/tableWControllers';
import Status from '../../components/Table/components/status';
import Priority from '../../components/Table/components/priority';
import api from '../../services/api';
import { Container } from './styles';
import Filter from '../../components/PDVfilter2';
import { useError } from '../../hooks/errors';
import { Occurrence } from '../../models';
import { useOccurrences } from '../../hooks/occurrences';

const ExcelJS = require('exceljs');

export default function CentralDeOcorrencias() {
  const { emitError } = useError();
  const tableRef = useRef<any>();
  const [tableData, setTableData] = useState<any[]>();
  const [isMobile, setIsMobile] = useState(() => {
    const windowSize: any = window.matchMedia('(max-width: 800px)');
    if (windowSize.matches === false) return false;
    return true;
  });
  const [addOccurrece, setAddOccurrence] = useState(false);
  const [addRowData, setAddRowData] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const { getOccurrences, occurrences, updateOccurrences } = useOccurrences();

  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [isFiltering, setIsFiltering] = useState<ValueType>();

  const parseData = (occurrenceList: any[]): any => {
    const parsed = occurrenceList?.map((occurrence: Occurrence) => ({
      ...occurrence,
      priority:
        occurrence?.create_at && getOccurrencePriority(occurrence?.create_at),
      parsedCreate_at: `${new Date(
        occurrence?.create_at,
      ).toLocaleDateString()} às ${new Date(
        occurrence?.create_at,
      ).toLocaleTimeString()} `,
    }));
    return parsed;
  };

  const handleFilter = async (
    e: ValueType | undefined,
    s: string | undefined,
  ) => {
    try {
      const params: any = {};
      if (e && e.length > 0) {
        params.startAt = formatISO(e[0]);
        params.endAt = formatISO(
          new Date(formatISO(e[1])).setHours(23, 59, 59),
        );
      }
      if (s) params.status = s;
      const { data: response } = await api.get('/occurrences', {
        params: { ...params },
      });

      const table = parseData(response);

      let f = e;
      if (s && !f) f = [new Date(), new Date()];
      setIsFiltering(f);

      setFilteredData(table);
    } catch (error) {
      emitError(error);
    }
  };

  const getOccurrencePriority = useCallback((create_at: string) => {
    const priority = differenceInDays(new Date(), new Date(create_at));

    if (priority <= 3) return 'Baixa';
    if (priority > 3 && priority < 6) return 'Média';
    return 'Alta';
  }, []);

  const columnDesk = [
    {
      title: 'Chamado Rubi',
      field: 'num',
      type: 'numeric',
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Ordem SAP',
      field: 'request_sap',
      hidden: false,
      export: false,
    },
    {
      title: 'CNPJ',
      field: 'pdv.details.cnpj',
      hidden: true,
      export: true,
    },
    {
      title: 'Emissor',
      field: 'emissor',
      hidden: true,
      export: true,
    },
    {
      title: 'Ordem SAP',
      field: 'request_sap',
      hidden: true,
      export: true,
    },
    {
      title: 'Solicitante',
      field: 'user.name',
    },
    {
      title: 'Produtos Reportados',
      field: 'details.length',
      type: 'numeric',
      width: '5%',
      cellStyle: {
        width: '5%',
        textAlign: 'left',
        paddingLeft: '40px',
      },
    },
    {
      title: 'Valor Total',
      field: 'total_price',
      render: rowData =>
        rowData.total_price.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
    {
      title: 'Responsável',
      field: 'status.next_user.name',
      render: rowData => rowData?.status?.next_user?.name || '--',
    },
    {
      title: 'Categoria',
      field: 'details[0].product.category.name',
    },
    {
      title: 'Status',
      field: 'status.status',
      render: rowData => <Status title={rowData?.status?.status} />,
    },
    {
      title: 'Prioridade',
      field: 'priority',
      render: rowData => <Priority title={rowData?.priority} />,
    },
    {
      title: 'Data Abertura',
      field: 'parsedCreate_at',
    },
    {
      title: 'Crédito',
      field: 'credit',
      hidden: true,
      export: true,
    },
  ] as Column<any>[];

  const columnMobile = [
    {
      title: 'Chamado',
      field: 'num',
      type: 'numeric',
      cellStyle: {
        width: '10%',
        textAlign: 'left',
      },
    },
    {
      title: 'Solicitante',
      field: 'user.name',
    },
    {
      title: 'Status',
      field: 'status.status',
      render: rowData => <Status title={rowData?.status?.status} />,
    },
  ];

  useEffect(() => {
    if (!occurrences?.length) getOccurrences();
  }, []);

  useEffect(() => {
    if (!occurrences) return;
    const table = parseData(occurrences);
    setTableData(table);
  }, [occurrences]);

  const popupEditHandleChange = (e: any) => {
    const index = occurrences.findIndex(el => el.id === e.id);
    if (index > -1) {
      occurrences[index] = e;
      setTableData(parseData(occurrences));
    }
    setOpenEdit(false);
  };

  return (
    <Container>
      <TableWControllers
        addButtonText="Adicionar Ocorrência"
        title="Central de Ocorrências"
        data={isFiltering ? filteredData : tableData}
        tableRef={tableRef}
        filter
        filterState={openFilter}
        setFilter={setOpenFilter}
        isFiltering={!!isFiltering}
        importLink="occurrences"
        importModelLink="https://pdv-general-mills.s3.sa-east-1.amazonaws.com/86d235557d71736ae6cb89a9921492a1-Ocorrencias.xlsx"
        updateButton
        updateLink="updateOccurrences"
        updateModelLink="https://pdv-general-mills.s3.sa-east-1.amazonaws.com/86d235557d71736ae6cb89a9921492a1-OcorrenciasAtualizar.xlsx"
        addButtonState={addOccurrece}
        addButtonFunction={setAddOccurrence}
        column={[
          ...(isMobile ? columnMobile : columnDesk),
          {
            width: '10%',
            cellStyle: {
              textAlign: 'right',
            },

            render: (rowData: any) => (
              <button
                type="button"
                onClick={() => {
                  setAddRowData(rowData);
                  setOpenEdit(true);
                }}
              >
                <Dots />
              </button>
            ),
          },
        ]}
        options={{
          search: true,
          exportButton: true,
          pageSizeOptions: [5, 10, 20, 50, 100, 200, 400, 600],
          exportCsv: async (columns, renderData) => {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Ocorrências');
            worksheet.addRow([
              'Chamado Rubi',
              'CNPJ',
              'Emissor',
              'Ordem SAP',
              'Solicitante',
              'Produtos Reportados',
              'Valor Total',
              'Responsável',
              'Categoria',
              'Status',
              'Prioridade',
              'Data Abertura',
              'Crédito',
            ]);
            let i = 0;
            while (i < renderData.length) {
              const item = renderData[i];
              worksheet.addRow([
                item.num,
                ((item.pdv || {}).details || {}).cnpj || '',
                '',
                item.request_sap || '',
                (item.user || {}).name || '',
                item.details.length,
                (item.total_price || '').toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                }),
                ((item.status || {}).next_user || {}).name || '--',
                (((item.details[0] || {}).product || {}).category || {}).name ||
                  '',
                (item.status || {}).status || '',
                item.priority,
                item.parsedCreate_at,
                item.credit,
              ]);
              i += 1;
            }
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = url;
            a.download = 'Ocorrências.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
          },
        }}
        localization={{
          toolbar: {
            exportCSVName: 'Exportar como .XLSX',
          },
        }}
      />

      {openEdit ? (
        <PopUpEditOccurrence
          isOpen={openEdit}
          setPopUpState={setOpenEdit}
          rowData={addRowData}
          handleChange={popupEditHandleChange}
        />
      ) : null}

      {addOccurrece ? (
        <PopUpAddOccurrence
          isOpen={addOccurrece}
          setPopUpState={setAddOccurrence}
        />
      ) : null}

      {openFilter ? (
        <Filter
          isOpen={openFilter}
          setPopUpState={setOpenFilter}
          handleChange={handleFilter}
          handleClearFilters={setIsFiltering}
          date={isFiltering}
        />
      ) : null}
    </Container>
  );
}
