/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Input from '../../Form/input';
import Textarea from '../../Form/textarea';
import { ReactComponent as CloseButton } from '../../../icons/blue-close-button-icon.svg';
import BlueButton from '../../blueButton';
import RedButton from '../../redButton';
import { Container, PopUp } from './styles';
import SwitchButton from '../../switchButton';
import api from '../../../services/api';
import Delete from '../../DeletePopUp';
import { useError } from '../../../hooks/errors';

import ApprovePopUp from '../../ApprovePopUp';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  rowData?: string[] | any;
};

ContratoPopUp.defaultProps = {
  rowData: false,
};

export default function ContratoPopUp({
  isOpen,
  setPopUpState,
  rowData,
}: popUpProp) {
  const { emitError } = useError();
  const popUpRef = useRef(null);
  const formRef = useRef(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [textAreaText, setTextArea] = useState<any>(null);

  const [deletePdv, setDeletePdv] = useState(false);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  async function handlePost(data: any) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome do contrato é obrigatório'),
        contract: Yup.string().required(
          'A descrição do contrato é obrigatória',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('/contracts', data);

      window.location.reload();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios');
        setShowConfirmation(false);

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  async function handlePut(data: any) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome do contrato é obrigatório'),
        contract: Yup.string().required(
          'A descrição do contrato é obrigatória',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.put(`/contracts/${rowData.id}`, data);

      window.location.reload();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios');
        setShowConfirmation(false);

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  async function handleDelete() {
    try {
      await api.delete(`/contracts/${rowData.id}`);
      toast.success('Contrato deletado');
      window.location.reload();
    } catch (error: any) {
      toast.error('Contrato referenciado por outra tabela');
    }
  }
  return (
    <Container>
      <PopUp ref={popUpRef}>
        {deletePdv ? (
          <Delete
            title="Contrato"
            isOpen={deletePdv}
            setCustomState={handleDelete}
            setPopUpState={setDeletePdv}
          />
        ) : null}

        <div className="pop-up-header">
          <p>Contrato</p>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>
        <Form ref={formRef} onSubmit={rowData ? handlePut : handlePost}>
          <ul>
            <li>
              <label htmlFor="name">
                Tipo Contrato
                <span style={{ display: 'inline', color: 'red' }}> *</span>
              </label>
              {rowData !== false ? (
                <Input
                  name="name"
                  id="name"
                  value={rowData?.name}
                  placeholder={rowData?.name}
                />
              ) : (
                <Input name="name" id="name" placeholder="Nome" />
              )}
            </li>
            <li>
              <label htmlFor="equipe">
                Contrato
                <span style={{ display: 'inline', color: 'red' }}> *</span>
              </label>
              <Textarea
                name="contract"
                id="equipe"
                value={textAreaText || rowData?.contract}
                onChange={e => setTextArea(e?.target?.value)}
                placeholder="Contrato"
              />
            </li>
          </ul>
          <div className="switch-button">
            <SwitchButton
              title="Troca Zero"
              isTrue={rowData?.zero}
              name="zero"
            />
          </div>

          {rowData ? (
            <div className="edit-pdv">
              <RedButton
                title="Apagar"
                setCustomState={setDeletePdv}
                customState={deletePdv}
              />
              <BlueButton
                title="Editar"
                setCustomState={setShowConfirmation}
                customState={showConfirmation}
              />
            </div>
          ) : (
            <div className="button-field">
              <BlueButton
                title="Salvar"
                setCustomState={setShowConfirmation}
                customState={showConfirmation}
              />

              <button
                type="button"
                className="cancel-button"
                onClick={() => setPopUpState(!isOpen)}
              >
                Cancelar
              </button>
            </div>
          )}

          {showConfirmation ? (
            <ApprovePopUp
              sampleText="Adicionar Contrato?"
              isOpen={showConfirmation}
              setPopUpState={setShowConfirmation}
            />
          ) : null}
        </Form>
      </PopUp>
    </Container>
  );
}
