/* eslint-disable consistent-return */
import React, { createContext, useContext, useState } from 'react';
import api from '../services/api';
import { useError } from './errors';

export interface CategoriesContextData {
  categories: any[] | null;
  getCategories(): Promise<void>;
  updateCategories(occurrence: any): void;
  isLoading: boolean;
}
export const CategoriesContext = createContext<CategoriesContextData>(
  {} as CategoriesContextData,
);
export const CategoriesProvider: React.FC = ({ children }: any) => {
  const [categories, setCategories] = useState<any[]>([]);
  const { emitError } = useError();
  const [loading, setLoading] = useState(false);

  const getCategories = async () => {
    try {
      setLoading(true);

      const response = await api.get('/categories');
      setCategories(response.data);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      emitError(error);
    }
  };

  const updateCategories = (newCategory: any) => {
    setCategories(old => [newCategory, ...old]);
  };

  return (
    <CategoriesContext.Provider
      value={{
        isLoading: loading,
        categories,
        getCategories,
        updateCategories,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export function useCategories(): CategoriesContextData {
  const context = useContext(CategoriesContext);
  return context;
}
