import styled, { keyframes } from 'styled-components';

const entranceAnim = keyframes`

from {
  width:0px;
  max-height:0px;
  opacity:0;
}
to {
  width:100%;
  max-height:9000px;
  opacity:1;
}

`;
export const Container = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: calc(9 * 99);
`;

export const PopUp = styled.div`
  width: 100%;
  max-width: 439px;
  height: 280px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 14px 8px 16px 8px;
  position: relative;
  animation: 0.4s ${entranceAnim} ease;

  @media screen and (max-width: 880px) {
    width: 90%;
  }

  .pop-up-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding: 0 76px 0 24px;

    button {
      position: absolute;
      top: 10px;
      right: 16px;

      svg {
        width: 16px;
      }
    }
  }

  .filter-section {
    flex: 1;
    margin-top: 16px;
    padding: 0 18px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .open-filter-button {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    color: var(--mediumGrey);
  }

  .select-field {
    overflow: hidden;
    select {
      border-color: var(--lightBlue);
      padding-right: 20px;
    }
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 16px;
      margin-top: 12px;

      li {
        list-style: none;
        button {
          color: var(--lightBlue);
          border-radius: 14px;
          border: 1px solid;
          padding: 4px 8px;
          font-size: var(--h6Size);
          font-weight: var(--h6Weight);
          letter-spacing: var(--h6Letter);
          line-height: var(--h6Line);
        }
      }
    }
  }

  .buttons-section {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    button {
      &:nth-child(2) {
        font-size: var(--h6Size);
        color: var(--mediumGrey);
        font-weight: var(--h6Weight);
        letter-spacing: var(--h6Letter);
        line-height: var(--h6Line);
      }
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  max-width: 328px;
  padding: 13px 0;
  background: var(--lightBlue);
  border-radius: 12px;
  color: white !important;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 5%;
  transition: 0.3s;

  &:hover {
    background: var(--lightBlue);
  }
`;
