import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { formatISO } from 'date-fns';
import { ValueType } from 'rsuite/lib/DateRangePicker';
import api from '../../services/api';
import { ReactComponent as Dots } from '../../icons/3dots-icon.svg';
import TableWControllers from '../../components/tableWControllers';
import RegionaisPopUp from '../../components/PDVPopUps/Regionais';
import { Container } from './styles';
import { useTeams } from '../../hooks/teams';
import Filter from '../../components/PDVfilter';
import { useError } from '../../hooks/errors';

export default function Regionais() {
  const { emitError } = useError();
  const { getTeam } = useTeams();
  const [addRegional, setAddRegional] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [addRowData, setAddRowData] = useState(false);
  const [data, setData] = useState();
  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [isFiltering, setIsFiltering] = useState<ValueType>();

  useEffect(() => {
    getTeam();
  }, []);

  useEffect(() => {
    api
      .get('/regionals')
      .then((res: any) => setData(res.data))
      .catch(error => {
        emitError(error);
      });
  }, []);

  const handleFilter = async (e: ValueType) => {
    try {
      const { data: response } = await api.get('/regionals', {
        params: {
          startAt: formatISO(e[0]),
          endAt: formatISO(new Date(formatISO(e[1])).setHours(23, 59, 59)),
        },
      });

      setIsFiltering(e);
      setFilteredData(response);
    } catch (error) {
      emitError(error);
    }
  };

  const columnRede = [
    { title: 'Nome Rede', field: 'name' },

    {
      title: 'n° Equipes',
      field: 'teams.length',
      render: rowData => rowData?.teams?.length,
    },
  ];

  return (
    <Container>
      <TableWControllers
        importLink="regional"
        importModelLink="https://pdv-general-mills.s3.sa-east-1.amazonaws.com/b12c72883a955328d678a4fba44d4269-Regionais.xlsx"
        addButtonText="Adicionar Regional"
        filter
        filterState={openFilter}
        setFilter={setOpenFilter}
        title="Regional"
        isFiltering={!!isFiltering}
        column={[
          ...columnRede,

          {
            width: '10%',
            cellStyle: {
              textAlign: 'right',
            },

            render: (rowData: any) => (
              <button
                type="button"
                onClick={() => {
                  setAddRowData(rowData);
                  setOpenEdit(true);
                }}
              >
                <Dots />
              </button>
            ),
          },
        ]}
        data={isFiltering ? filteredData : data}
        addButtonState={addRegional}
        addButtonFunction={setAddRegional}
      />

      {addRegional ? (
        <RegionaisPopUp isOpen={addRegional} setPopUpState={setAddRegional} />
      ) : null}

      {openEdit ? (
        <RegionaisPopUp
          isOpen={openEdit}
          setPopUpState={setOpenEdit}
          rowData={addRowData}
        />
      ) : null}

      {openFilter ? (
        <Filter
          isOpen={openFilter}
          setPopUpState={setOpenFilter}
          handleChange={handleFilter}
          handleClearFilters={setIsFiltering}
          date={isFiltering}
        />
      ) : null}
    </Container>
  );
}
