import React, { useState, useEffect } from 'react';
import { ValueType } from 'rsuite/lib/DateRangePicker';
import { formatISO } from 'date-fns';
import api from '../../services/api';
import OcorrenciasGeral from './components/ocorrencias';
import Graph from './components/devolucao-grafico';
import Table from '../../components/Table';
import DevolucoesPorProduto from './components/devolucoesPorProduto';
import InformacoesUltimaSemana from './components/informacoesUltimaSemana';
import ColumnGraph from './components/devolucao-grafico-coluna';
import { Container, MainContainer } from './styles';
import { useError } from '../../hooks/errors';
import Filter from '../../components/PDVfilter';
import { FilterButton } from '../../components/tableWControllers/styles';
import { ReactComponent as FilterIcon } from '../../icons/filter-icon.svg';

export default function Geral() {
  const [fullData, setFullData] = useState<any>();
  const [categoryGraph, setCategoryGraph] = useState();
  const [teamGraph, setTeamGraph] = useState();
  const [devolutionGraph, setDevolutionGraph] = useState<any>();
  const [openFilter, setOpenFilter] = useState(false);
  const [isFiltering, setIsFiltering] = useState<ValueType>();
  const [loading, setLoading] = useState(false);
  const { emitError } = useError();

  useEffect(() => {
    buscarDashboard({});
  }, []);

  useEffect(() => {
    if (!fullData) return;

    const devolutionParse: any[] = [];
    Object.entries(fullData?.devolutionPerJustification).forEach(item => {
      if (item[0] === 'total') return;
      const P =
        (Number(item[1]) * 100) / fullData?.devolutionPerJustification.total;
      devolutionParse.push({
        name: item[0],
        devolutions: item[1],
        percent: `${Math.trunc(P)}%`,
      });
    }) as any;

    setDevolutionGraph(devolutionParse);

    const GraphData = fullData?.occurrenceByCategory?.map(category => ({
      name: category?.category,
      devolutions: category?.occurrences || 0,
    }));
    setCategoryGraph(GraphData);

    const GraphDataTeam = fullData?.occurrenceByTeam?.map((team: any) => ({
      name: team?.name,
      devolutions: team?.occurrences?.length || 0,
    }));
    setTeamGraph(GraphDataTeam);
  }, [fullData]);

  const columnPDV = [
    {
      title: 'Pdv',
      field: 'name',
    },
    {
      title: 'Regional',
      field: 'regional.name',
    },
    {
      title: 'Devoluções',
      field: 'occurrences',
      render: rowData => rowData?.occurrences.length || 0,
    },
  ];

  const columnProduct = [
    {
      title: 'Produto',
      field: 'product.name',
    },
    {
      title: 'Categoria',
      field: 'product.category',
    },
    {
      title: 'Total de PDVs com devolução',
      field: 'occurrences',
      render: rowData => rowData?.occurrences.length || 0,
    },
  ];

  const handleFilter = async (e: ValueType | undefined) => {
    setIsFiltering(e);
    if (!e) return;
    buscarDashboard({
      startAt: formatISO(e[0]),
      endAt: formatISO(new Date(formatISO(e[1])).setHours(23, 59, 59)),
    });
  };

  const buscarDashboard = params => {
    setLoading(true);
    api
      .get('/dashboard', { params })
      .then((res: any) => {
        setFullData(res.data);
        setLoading(false);
      })
      .catch(error => {
        emitError(error);
        setLoading(false);
      });
  };

  const limparFiltro = (e: ValueType | undefined) => {
    setIsFiltering(e);
    buscarDashboard({});
  };

  return (
    <MainContainer>
      <div style={{ paddingBottom: '15px' }}>
        <FilterButton
          type="button"
          onClick={() => setOpenFilter(!openFilter)}
          isFiltering={!!isFiltering}
        >
          <FilterIcon />
          Filtros
        </FilterButton>
      </div>

      <Container>
        <OcorrenciasGeral
          title="Ocorrências abertas há até 4 dias"
          data={fullData?.occurrenceBy4Days}
          isLoading={loading}
        />
        <OcorrenciasGeral
          title="Ocorrências abertas entre 4 e 7 dias"
          data={fullData?.occurrenceBy4n7Days}
          isLoading={loading}
        />
        <OcorrenciasGeral
          title="Ocorrências abertas há mais de 7 dias"
          data={fullData?.occurrenceBy7DaysMore}
          isLoading={loading}
        />
      </Container>
      <Container style={{ marginTop: '27px' }}>
        <Graph fullData={devolutionGraph} isLoading={loading} />
        <div className="dash-card">
          <Table
            title="PDVs com maior número de Devoluções"
            column={columnPDV}
            data={fullData?.topPDVS}
            options={{ pageSizeOptions: 10 }}
            isLoading={loading}
          />
        </div>
        <div className="dash-card">
          <Table
            title="Produto com maior número de Devoluções"
            column={columnProduct}
            data={fullData?.topProducts}
            options={{ pageSizeOptions: 10 }}
            isLoading={loading}
          />
        </div>
      </Container>
      <Container style={{ marginTop: '24px' }}>
        <ColumnGraph
          title="Devolução por Categoria"
          data={categoryGraph}
          isLoading={loading}
        />
        <ColumnGraph
          title="Devolução por Equipe"
          data={teamGraph}
          isLoading={loading}
        />
      </Container>

      <Container style={{ marginTop: '24px' }}>
        <InformacoesUltimaSemana fullData={fullData} isLoading={loading} />
      </Container>
      <Container style={{ marginTop: '24px' }}>
        <DevolucoesPorProduto fullData={fullData} isLoading={loading} />
      </Container>

      {openFilter ? (
        <Filter
          isOpen={openFilter}
          setPopUpState={setOpenFilter}
          handleChange={handleFilter}
          handleClearFilters={limparFiltro}
          date={isFiltering}
        />
      ) : null}
    </MainContainer>
  );
}
