import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import 'rsuite/dist/styles/rsuite-default.css';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';

import PrivateRoute from './routes/PrivateRoute';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './hooks/auth';
import { ErrorProvider } from './hooks/errors';
import { NotificationsProvider } from './hooks/notifications';

const ErrorFallback = () => (
  <div
    role="alert"
    style={{
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <h1>Erro de página - 500</h1>
    <div>
      <span>Algo deu errado: </span>
      <span style={{ color: 'red' }}>Erro interno</span>
    </div>

    <a href="/" style={{ paddingTop: 10 }}>
      Voltar
    </a>
  </div>
);

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        pauseOnFocusLoss={false}
        style={{ position: 'absolute' }}
      />
      <BrowserRouter>
        <NotificationsProvider>
          <AuthProvider>
            <ErrorProvider>
              <PrivateRoute />
            </ErrorProvider>
          </AuthProvider>
        </NotificationsProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
