import React, { useState, useEffect } from 'react';
import { formatISO } from 'date-fns';
import { toast } from 'react-toastify';
import { ValueType } from 'rsuite/lib/DateRangePicker';
import ProdutoPopUp from '../../components/ProdutosPopUP/Produto';
import TableWControllers from '../../components/tableWControllers';
import PrecoMaxMin from './preco';
import api from '../../services/api';
import { Container } from './styles';
import { ReactComponent as Dots } from '../../icons/3dots-icon.svg';
import Filter from '../../components/PDVfilter';
import { useError } from '../../hooks/errors';

export default function Produtos() {
  const [addProduct, setAddProduct] = useState(false);
  const [addRowData, setAddRowData] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [tableData, setTableData] = useState<any[]>();
  const [openFilter, setOpenFilter] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const { emitError } = useError();
  const [isFiltering, setIsFiltering] = useState<ValueType>();

  const parseData = (list: any[]): any => {
    const parsed = list?.map((it: any) => ({
      ...it,
      parsedPrice: `Mínimo: ${it?.min_price?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })} - Máximo: ${it?.max_price?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })}`,
      parsedCreateAt: `${new Date(
        it?.create_at,
      ).toLocaleDateString()} às ${new Date(
        it?.create_at,
      ).toLocaleTimeString()} `,
      parsedUpdateAt: `${new Date(
        it?.update_at,
      ).toLocaleDateString()} às ${new Date(
        it?.update_at,
      ).toLocaleTimeString()} `,
    }));

    return parsed;
  };

  const handleFilter = async (e: ValueType) => {
    try {
      const { data: response } = await api.get('/products', {
        params: {
          startAt: formatISO(e[0]),
          endAt: formatISO(new Date(formatISO(e[1])).setHours(23, 59, 59)),
        },
      });

      setIsFiltering(e);
      setFilteredData(response);
    } catch (error) {
      emitError(error);
    }
  };

  useEffect(() => {
    api
      .get('/products')
      .then((res: any) => {
        const parsed = parseData(res.data);
        setTableData(parsed);
      })
      .catch(error => {
        emitError(error);
      });
  }, []);

  const columnProduct = [
    { title: 'SKU', field: 'sku' },
    { title: 'Nome', field: 'name' },
    { title: 'Categoria', field: 'category.name' },
    {
      title: 'Preço',
      field: 'parsedPrice',
      render: rowData => (
        <PrecoMaxMin
          precoMin={rowData.min_price}
          precoMax={rowData.max_price}
        />
      ),
    },
    { title: 'Criado em', field: 'parsedCreateAt' },
    { title: 'Atualizado em', field: 'parsedUpdateAt' },
  ];

  return (
    <Container>
      <TableWControllers
        importLink="product"
        importModelLink="https://pdv-general-mills.s3.sa-east-1.amazonaws.com/76b6772e7e4ee072ded023dc78fb7158-Produtos.xlsx"
        addButtonText="Adicionar Produtos"
        title="Produtos"
        data={isFiltering ? filteredData : tableData}
        addButtonState={addProduct}
        addButtonFunction={setAddProduct}
        filter
        filterState={openFilter}
        setFilter={setOpenFilter}
        isFiltering={!!isFiltering}
        column={[
          ...columnProduct,
          {
            width: '10%',
            cellStyle: {
              textAlign: 'right',
            },

            render: (rowData: any) => (
              <button
                type="button"
                onClick={() => {
                  setAddRowData(rowData);
                  setOpenEdit(true);
                }}
              >
                <Dots />
              </button>
            ),
          },
        ]}
      />

      {openEdit ? (
        <ProdutoPopUp
          isOpen={openEdit}
          setPopUpState={setOpenEdit}
          rowData={addRowData}
        />
      ) : null}

      {addProduct ? (
        <ProdutoPopUp isOpen={addProduct} setPopUpState={setAddProduct} />
      ) : null}

      {openFilter ? (
        <Filter
          isOpen={openFilter}
          setPopUpState={setOpenFilter}
          handleChange={handleFilter}
          handleClearFilters={setIsFiltering}
          date={isFiltering}
        />
      ) : null}
    </Container>
  );
}
