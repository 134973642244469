/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-boolean-value */
import React, { useState } from 'react';
import Input from '../Form/input';
import { Container } from './styles';

type ButtonProps = {
  title?: string;
  customState?: any;
  setCustomState?: any;
  value?: string;
  isTrue?: boolean;
  name: string;
};

FilterButon.defaultProps = {
  title: '',
  value: false,
  customState: false,

  setCustomState: false,
};

export default function FilterButon({
  customState,
  setCustomState,
  value,
  isTrue,
  title,
  name,
}: ButtonProps) {
  const [isActive, setIsActive] = useState(isTrue);

  return (
    <Container>
      <button
        type="button"
        className={isActive ? 'active' : ''}
        onClick={() => setIsActive(!isActive)}
      >
        {title}
      </button>

      {isActive ? (
        <Input
          type="text"
          value={isActive ? value : null}
          checked={isActive}
          name={name}
        />
      ) : null}
    </Container>
  );
}
