/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/charts';
import loadingGif from '../../../../img/loading.gif';
import api from '../../../../services/api';

import { Container } from './styles';

type columnProp = {
  title: string;
  data?: string[] | any;
  overrideConfig?: any;
  isLoading: boolean;
};

ColumnGraph.defaultProps = {
  data: [],
};

export default function ColumnGraph({
  title,
  data,
  overrideConfig,
  isLoading,
}: columnProp) {
  const [graphData, setData] = useState([]);

  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [data]);

  const config = {
    data: graphData,
    xField: 'name',
    yField: 'devolutions',

    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  return (
    <Container>
      {isLoading ? (
        <div className="carregando">
          <div className="carregando2" />
          <img src={loadingGif} alt="" />
        </div>
      ) : null}
      <p>{title}</p>
      <Column {...config} {...overrideConfig} />
    </Container>
  );
}
