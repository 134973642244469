/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { Toggle } from 'rsuite';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ReactComponent as CloseButton } from '../../../icons/blue-close-button-icon.svg';
import BlueButton from '../../blueButton';
import { Container, PopUp, PermissionsContainer } from './styles';
import Delete from '../../DeletePopUp';
import Input from '../../Form/input';
import api from '../../../services/api';
import RedButton from '../../redButton';
import { useError } from '../../../hooks/errors';
import Select from '../../Form/select';
import ApprovePopUp from '../../ApprovePopUp';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  rowData?: string[] | any;
};

interface permissionOpts {
  canOpenOccurrence: boolean;
  canEditOccurrence: boolean;
  canApprove400: boolean;
  canApprove5000: boolean;
  canApproveMore: boolean;
  canEditPDV: boolean;
  canEditUser: boolean;
  canEditProduct: boolean;
}

CargosPopUp.defaultProps = {
  rowData: false,
};

export default function CargosPopUp({
  isOpen,
  setPopUpState,
  rowData,
}: popUpProp) {
  const [steps, setSteps] = useState('info');
  const { emitError } = useError();
  const [fieldPosition, setFieldPosition] = useState(1);
  const [permissions, setPermissions] = useState<permissionOpts>({
    canOpenOccurrence: false,
    canEditOccurrence: false,
    canApprove400: false,
    canApprove5000: false,
    canApproveMore: false,
    canEditPDV: false,
    canEditUser: false,
    canEditProduct: false,
  });
  const popUpRef = useRef(null);
  const formRef = useRef(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const basicRoles = [
    {
      name: 'Representante',
      id: 'USER',
    },
    {
      name: 'Executivo',
      id: 'USER2',
    },
    {
      name: 'Supervisor',
      id: 'SUPERVISOR',
    },
    {
      name: 'Gerente Distrital',
      id: 'MANAGER1',
    },
    {
      name: 'Gerente Regional',
      id: 'MANAGER2',
    },
    {
      name: 'Administrador',
      id: 'ADMIN',
    },
  ];

  const [deletePdv, setDeletePdv] = useState(false);
  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  const infoFields = [
    {
      title: 'Nome',
      type: 'input',
      name: 'name',
      value: rowData.name,
      isRequired: true,
    },
    {
      title: 'Cargo Base',
      type: 'select',
      name: 'basicRole',
      options: basicRoles,
      optValue: true,
      isRequired: true,
    },
  ] as any;

  useEffect(() => {
    if (!rowData) return;
    const parseObjects = {
      canOpenOccurrence: !!rowData?.permissions?.find(
        perm => perm === 'canOpenOccurrence',
      ),
      canEditOccurrence: !!rowData?.permissions?.find(
        perm => perm === 'canEditOccurrence',
      ),
      canApprove400: !!rowData?.permissions?.find(
        perm => perm === 'canApprove400',
      ),
      canApprove5000: !!rowData?.permissions?.find(
        perm => perm === 'canApprove5000',
      ),
      canApproveMore: !!rowData?.permissions?.find(
        perm => perm === 'canApproveMore',
      ),
      canEditPDV: !!rowData?.permissions?.find(perm => perm === 'canEditPDV'),
      canEditUser: !!rowData?.permissions?.find(perm => perm === 'canEditUser'),
      canEditProduct: !!rowData?.permissions?.find(
        perm => perm === 'canEditProduct',
      ),
    };
    setPermissions(parseObjects);
  }, [rowData]);

  async function handlePost(data: any) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome do cargo é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      let parsePermissions: any = Object.entries(permissions).filter(
        item => item[1] === true,
      );
      parsePermissions = parsePermissions.map(item => item[0]);

      await api.post('/roles', { ...data, parsePermissions });
      window.location.reload();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios!');
        setShowConfirmation(false);
        setSteps('info');

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  async function handlePut(data: any) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome do cargo é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      let parsePermissions: any = Object.entries(permissions).filter(
        item => item[1] === true,
      );
      parsePermissions = parsePermissions.map(item => item[0]);

      await api.put(`/roles/${rowData.id}`, {
        ...data,
        permissions: parsePermissions,
      });
      window.location.reload();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios!');
        setShowConfirmation(false);
        setSteps('info');

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  async function handleDelete() {
    try {
      await api.delete(`/roles/${rowData.id}`);
      toast.success('Perfil deletado');
      window.location.reload();
    } catch (error: any) {
      toast.error('Perfil referenciado por outra tabela');
    }
  }

  useEffect(() => {
    if (steps === 'info') {
      setFieldPosition(1);
    } else if (steps === 'end') {
      setFieldPosition(2);
    } else if (steps === 'pic') {
      setFieldPosition(3);
    }
  }, [steps]);

  return (
    <Container>
      <PopUp ref={popUpRef}>
        {deletePdv ? (
          <Delete
            title="Cargo"
            isOpen={deletePdv}
            setCustomState={handleDelete}
            setPopUpState={setDeletePdv}
          />
        ) : null}

        <div className="pop-up-header">
          <h5> Cargo</h5>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>
        <div className="popUp-controllers">
          <button
            type="button"
            className={steps === 'info' ? 'active' : ''}
            onClick={() => setSteps('info')}
          >
            Tipo
          </button>
          <button
            type="button"
            className={steps === 'end' ? 'active' : ''}
            onClick={() => setSteps('end')}
          >
            Permissões
          </button>
        </div>

        <div className="conditional-content">
          <Form ref={formRef} onSubmit={rowData ? handlePut : handlePost}>
            <div className=" fields-section teste-action">
              <div
                className={
                  fieldPosition === 1
                    ? 'information-section action'
                    : 'information-section'
                }
              >
                <ul className="input-field">
                  {infoFields.map(e => (
                    <li>
                      <h6>
                        {e.title}
                        {e?.isRequired && (
                          <span style={{ display: 'inline', color: 'red' }}>
                            {' '}
                            *
                          </span>
                        )}
                      </h6>
                      {e.type === 'select' ? (
                        <Select
                          name={e.name}
                          fields={e.options}
                          value={rowData?.basicRole}
                          optValue={e?.optValue}
                        />
                      ) : (
                        <Input
                          type="text"
                          name={e.name}
                          placeholder={e.placeholder}
                          value={e?.value}
                        />
                      )}
                    </li>
                  ))}
                </ul>

                {!rowData ? (
                  <div className="step-buttons">
                    <BlueButton
                      setCustomState={setSteps}
                      customState="end"
                      title="Próximo"
                      typeOf="button"
                    />

                    <button
                      type="button"
                      className="cancel-button"
                      onClick={() => setPopUpState(!isOpen)}
                    >
                      Cancelar
                    </button>
                  </div>
                ) : null}
              </div>
              <div
                className={
                  fieldPosition === 2 ? 'end-section action' : 'end-section'
                }
              >
                <PermissionsContainer>
                  <div>Ocorrências</div>
                  <span>
                    Abertura de Ocorrências
                    <Toggle
                      size="md"
                      checked={permissions.canOpenOccurrence}
                      onChange={value =>
                        setPermissions(old => ({
                          ...old,
                          canOpenOccurrence: value,
                        }))
                      }
                    />
                  </span>
                  <span>
                    Edição de Ocorrências
                    <Toggle
                      size="md"
                      checked={permissions.canEditOccurrence}
                      onChange={value =>
                        setPermissions(old => ({
                          ...old,
                          canEditOccurrence: value,
                        }))
                      }
                    />
                  </span>

                  <div>Aprovação</div>
                  <span>
                    Até R$ 400,00
                    <Toggle
                      size="md"
                      checked={permissions.canApprove400}
                      onChange={value =>
                        setPermissions(old => ({
                          ...old,
                          canApprove400: value,
                        }))
                      }
                    />
                  </span>
                  <span>
                    De R$ 400,01 até R$5.000,00
                    <Toggle
                      size="md"
                      checked={permissions.canApprove5000}
                      onChange={value =>
                        setPermissions(old => ({
                          ...old,
                          canApprove5000: value,
                        }))
                      }
                    />
                  </span>
                  <span>
                    Acima de R$5.000,00
                    <Toggle
                      size="md"
                      checked={permissions.canApproveMore}
                      onChange={value =>
                        setPermissions(old => ({
                          ...old,
                          canApproveMore: value,
                        }))
                      }
                    />
                  </span>

                  <div>Sistema</div>
                  <span>
                    Edição de Pontos de Venda
                    <Toggle
                      size="md"
                      checked={permissions.canEditPDV}
                      onChange={value =>
                        setPermissions(old => ({
                          ...old,
                          canEditPDV: value,
                        }))
                      }
                    />
                  </span>
                  <span>
                    Edição de Usuários
                    <Toggle
                      size="md"
                      checked={permissions.canEditUser}
                      onChange={value =>
                        setPermissions(old => ({
                          ...old,
                          canEditUser: value,
                        }))
                      }
                    />
                  </span>
                  <span>
                    Edição de Produtos
                    <Toggle
                      size="md"
                      checked={permissions.canEditProduct}
                      onChange={value =>
                        setPermissions(old => ({
                          ...old,
                          canEditProduct: value,
                        }))
                      }
                    />
                  </span>
                </PermissionsContainer>

                {!rowData ? (
                  <div className="step-buttons">
                    <BlueButton
                      setCustomState={setShowConfirmation}
                      customState={showConfirmation}
                      title="Próximo"
                    />

                    <button
                      type="button"
                      className="cancel-button"
                      onClick={() => setPopUpState(!isOpen)}
                    >
                      Cancelar
                    </button>
                  </div>
                ) : null}
              </div>
            </div>

            {rowData ? (
              <div className="edit-pdv">
                <RedButton
                  title="Apagar"
                  setCustomState={setDeletePdv}
                  customState={deletePdv}
                />
                <BlueButton
                  title="Editar"
                  setCustomState={setShowConfirmation}
                  customState={showConfirmation}
                />
              </div>
            ) : null}

            {showConfirmation ? (
              <ApprovePopUp
                sampleText="Adicionar Equipe?"
                isOpen={showConfirmation}
                setPopUpState={setShowConfirmation}
              />
            ) : null}
          </Form>
        </div>
      </PopUp>
    </Container>
  );
}
