import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.section`
  width: 100%;
  padding: 0 24px;

  .central-table {
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }
`;
