/* eslint-disable consistent-return */
import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import { Regional } from '../models';
import { useError } from './errors';

export interface RegionalContextData {
  regionals: Regional[] | null;
  getRegional(): Promise<void>;
  isLoading: boolean;
}
export const RegionalContext = createContext<RegionalContextData>(
  {} as RegionalContextData,
);
export const RegionalProvider: React.FC = ({ children }: any) => {
  const [regionals, setRegionals] = useState<Regional[]>();
  const [loading, setLoading] = useState(false);
  const { emitError } = useError();

  const getRegional = async () => {
    try {
      setLoading(true);

      const response = await api.get('/regionals');
      setRegionals(response.data);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      emitError(error);
    }
  };

  return (
    <RegionalContext.Provider
      value={{
        isLoading: loading,
        regionals,
        getRegional,
      }}
    >
      {children}
    </RegionalContext.Provider>
  );
};

export function useRegionals(): RegionalContextData {
  const context = useContext(RegionalContext);
  return context;
}
