import React from 'react';
import { DateRangePicker, DateRangePickerProps } from 'rsuite';

const { afterToday } = DateRangePicker;

const DatePicker = ({ onChange, ...props }: DateRangePickerProps) => (
  <DateRangePicker
    placeholder="Data Personalizada"
    disabledDate={afterToday()}
    format="DD/MM/YYYY"
    locale={{
      sunday: 'Dom',
      monday: 'Seg',
      tuesday: 'Ter',
      wednesday: 'Qua',
      thursday: 'Qui',
      friday: 'Sex',
      saturday: 'Sab',
      ok: 'OK',
      today: 'Hoje',
      yesterday: 'Ontem',
      last7Days: 'Ultimos 7 dias',
    }}
    onChange={onChange}
    {...props}
  />
);

export default DatePicker;
