import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const MainContainer = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

export const Container = styled.section`
  width: calc(100% - 270px);
  height: 100%;
  background: var(--bgLight);
  position: relative;
  margin-left: auto;
  padding-bottom: 80px;

  @media screen and (max-width: 880px) {
    width: 100%;
    height: calc(100% - 80px);
    margin-bottom: auto;
    overflow-y: auto;

    .copyright {
      display: none !important;
    }
  }

  .copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 23px;

    span:nth-child(1) {
      margin-right: 44px;
    }

    span {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 11px;
      color: #8d8d8d;
    }
  }
`;
