/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-useless-escape */
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
// import { userInfo } from 'os';
import MaterialTable from 'material-table';
import { useError } from '../../../hooks/errors';
import { ReactComponent as CloseButton } from '../../../icons/blue-close-button-icon.svg';
import { ReactComponent as Trash } from '../../../icons/trash.svg';
import { ReactComponent as Upload } from '../../../icons/upload-icon.svg';
import { ReactComponent as SearchIcon } from '../../../icons/search-icon.svg';
import Table from '../../Table';
import GreenButton from '../../greenButton';
import BlueButton from '../../blueButton';
import RedButton from '../../redButton';
import Input from '../../Form/input';
import Select from '../../Form/select';
import api from '../../../services/api';
import { Container, PopUp, TableContainer } from './styles';
import { useAuth } from '../../../hooks/auth';
import Fluxo from '../../FluxoDeAprovacao';
import AddProductForm from '../../AddProductForm';
import { useOccurrences } from '../../../hooks/occurrences';
import { useCategories } from '../../../hooks/categories';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
};

export default function AddOccurrence({ isOpen, setPopUpState }: popUpProp) {
  const popUpRef = useRef(null);

  const { emitError } = useError();

  const [fileList, setFileList] = useState<
    { id: string; name: string; url: string }[]
  >([]);

  const [fluxoDeAprovacao, setFluxoDeAprovacao] = useState(false);
  const [addProduct, setAddProduct] = useState(false);
  const { updateOccurrences } = useOccurrences();
  const { user } = useAuth();

  const [pdvInfo, setPdvInfo] = useState([]);
  const [pdvId, setPDVID] = useState('');
  const [pdvZero, setPdvZero] = useState(false);
  const [notaFiscal, setNotaFiscal] = useState('');
  const [selectCategory, setSelectCategory] = useState<any>();
  const [selectTeam, setSelectTeam] = useState<any>(user?.teams[0]?.id);

  const [productList, setProductList] = useState<any[]>([]);
  const [cnpj, setCNPJ] = useState();
  const [codigoSap, setCodigoSAP] = useState<any>();
  const formRef = useRef(null);
  const addProdRef = useRef<any>();
  const { categories, getCategories } = useCategories();

  const setNewProduct = () => {
    addProdRef?.current.submit();
    addProdRef?.current.reset();
  };

  async function handleSubmit(data: any) {
    try {
      /* if (!notaFiscal) {
        emitError({}, 'NF não pode ser vazia.');
        return;
      } */
      if (fileList.length === 0) {
        emitError({}, 'Você precisa adicionar um arquivo.');
        return;
      }

      const prods = productList?.map(product => {
        delete product?.tableData?.id;
        delete product?.tableData;
        const parsed = { ...product, tableData: null };
        delete parsed?.tableData;
        return product;
      });

      const newOccurrence = await api.post('/occurrences', {
        pdv_id: pdvId,
        team_id: selectTeam,
        nota_fiscal: notaFiscal,
        products: prods,
        files: fileList.map(file => file.url) || [],
      });

      updateOccurrences(newOccurrence.data);
      toast.success('Ocorrência adicionada!');
      setPopUpState(false);
    } catch (error: any) {
      emitError(error);
    }
  }

  const handleCnpjFilter = e => {
    let filtered = typeof e === 'string' ? e : e.target.value;
    filtered = filtered.replace(/[^0-9%]/gi, '');
    if (filtered?.length > 14) return;
    filtered = filtered.replace(/(\d{2})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d{1,2})/, '$1/$2');
    filtered = filtered.replace(/(\d{4})(\d{1,2})/, '$1-$2');
    setCNPJ(filtered);
  };

  useEffect(() => {
    if (!categories?.length) getCategories();
  }, []);

  async function GetPDV(value: any, cnpjBool: boolean) {
    setPdvZero(false);
    let campo = 'Código SAP';
    let valor = value;
    if (cnpjBool) {
      campo = 'CNPJ';
      handleCnpjFilter(value);
      valor = value?.replace(/[^0-9%]/gi, '');
    }

    if ((cnpjBool && valor?.length === 14) || (!cnpjBool && valor)) {
      try {
        const postOcurrence = await api.get(
          `/pdvs/${campo === 'CNPJ' ? 'cnpj' : 'codigoSap'}/${valor}`,
        );
        if (!postOcurrence.data) {
          toast.error(`${campo} Incorreto`);
          setPDVID('');
          setPdvInfo([]);
        } else {
          const parseInfo = postOcurrence.data;
          const pdvInformation = [
            {
              titulo1: 'Nome Fantasia',
              conteudo: 'Pão de Açúcar',
              value: parseInfo.name,
              input_name: 'nomeFantasia',
              disabled: true,
            },
            {
              titulo1: 'Contrato',
              conteudo: 'Diamante',
              input_name: 'contrato',
              value: parseInfo.contract.name,
              disabled: true,
            },
            {
              titulo1: 'Cidade',
              conteudo: 'São Paulo',
              input_name: 'cidade',
              value: parseInfo.details.city,
              disabled: true,
            },
            {
              titulo1: 'Estado',
              conteudo: 'SP',
              input_name: 'estado',
              value: parseInfo.details.state,
              disabled: true,
            },
          ];
          setPDVID(parseInfo.details.pdv_id);
          setPdvInfo(pdvInformation);
          setPdvZero(parseInfo.zero || (parseInfo.contract || {}).zero);
          console.log(
            parseInfo.zero || (parseInfo.contract || {}).zero,
            pdvZero,
          );
          if (cnpjBool) setCodigoSAP(parseInfo.reference);
          else handleCnpjFilter(parseInfo.details.cnpj);
        }
      } catch (error: any) {
        toast.error(`${campo} Incorreto`);
      }
    }
  }

  const handleProduct = (data: any) => {
    setProductList(old => [...old, data]);
    setAddProduct(!addProduct);
  };

  async function handleDelete(id) {
    try {
      await api.delete(`/upload/${id}`);
      setFileList(list => list.filter(item => item.id !== id));
    } catch (error: any) {
      emitError(error);
    }
  }

  async function setNewFile(element) {
    const file = element.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post(`/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setFileList(list => [
        ...list,
        {
          id: response.data.key,
          name: response.data.name,
          url: response.data.url,
        },
      ]);
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  return (
    <Container>
      <PopUp ref={popUpRef}>
        {fluxoDeAprovacao ? (
          <Fluxo
            isOpen={fluxoDeAprovacao}
            setPopUpState={setFluxoDeAprovacao}
          />
        ) : null}

        <section className="desktop-section">
          <div className="pop-up-header">
            <h5> Chamado 0000</h5>
            <div className="occurrence-options">
              <button
                type="button"
                onClick={() => setFluxoDeAprovacao(!fluxoDeAprovacao)}
              >
                Fluxo de Aprovação
              </button>
            </div>
            <button
              type="button"
              onClick={() => setPopUpState(!isOpen)}
              className="close-button"
            >
              <CloseButton />
            </button>
          </div>

          <section className={addProduct ? 'main-info desactive' : 'min-info'}>
            <div className="table-title">
              <p>Dados do Cliente</p>
            </div>
            <ul className="table-list">
              <li className="table-content">
                <label htmlFor="CNPJ">
                  CNPJ{' '}
                  <span style={{ display: 'inline', color: 'red' }}>*</span>
                </label>
                <input
                  name="cnpj"
                  id="CNPJ"
                  placeholder="00.000.000/0000-00"
                  value={cnpj}
                  onChange={e => GetPDV(e?.target?.value, true)}
                />
              </li>
              <li className="table-content table-content-search">
                <label htmlFor="codigoSap">Código SAP</label>
                <input
                  name="codigoSap"
                  id="codigoSAP"
                  placeholder=""
                  value={codigoSap}
                  onChange={e => setCodigoSAP(e?.target?.value)}
                />
                <button type="button" onClick={() => GetPDV(codigoSap, false)}>
                  <SearchIcon />
                </button>
              </li>
              {false && (
                <li className="table-content">
                  <label htmlFor="notaFiscal">NF</label>
                  <input
                    name="notaFiscal"
                    id="notaFiscal"
                    placeholder=""
                    onChange={e => setNotaFiscal(e?.target?.value)}
                  />
                </li>
              )}

              {pdvInfo.map(d => (
                <li className="table-content">
                  <label htmlFor={d.titulo1}> {d.titulo1}</label>
                  <input
                    type="text"
                    id={d.titulo1}
                    name={d.input_name}
                    value={d.value}
                    placeholder={d.conteudo}
                    disabled={d.disabled}
                  />
                </li>
              ))}
            </ul>

            <div className="table-title">
              <p>Dados do chamado</p>
              {pdvZero && (
                <div>
                  Este cliente tem <b>Troca Zero</b> e não é possível criar
                  chamado para ele.
                </div>
              )}
            </div>
            <ul className="table-list dados-do-chamado">
              <li className="table-content">
                <div>
                  <p>
                    Categoria{' '}
                    <span style={{ display: 'inline', color: 'red' }}>*</span>
                  </p>

                  <select
                    name="Categoria"
                    onChange={e => setSelectCategory(e?.target?.value)}
                  >
                    <option value={null} disabled hidden selected>
                      Selecionar categoria
                    </option>
                    {categories?.map(category => (
                      <option value={category.name}>{category.name}</option>
                    ))}
                  </select>
                </div>

                {user.teams?.length > 1 && (
                  <div>
                    <p>
                      Abrir no time{' '}
                      <span style={{ display: 'inline', color: 'red' }}>*</span>
                    </p>

                    <select
                      name="team"
                      onChange={e => setSelectTeam(e?.target?.value)}
                    >
                      {user?.teams?.map(team => (
                        <option value={team.id}>{team.name}</option>
                      ))}
                    </select>
                  </div>
                )}

                {selectCategory && (
                  <div className="add-file-field">
                    <input
                      type="file"
                      accept="application/pdf"
                      onInput={element => setNewFile(element)}
                    />
                    <p>Inserir arquivo</p>
                  </div>
                )}

                <div className="add-product-field">
                  <BlueButton
                    title="Adicionar Produto"
                    setCustomState={setAddProduct}
                    customState={addProduct}
                    disabled={!pdvId || !selectCategory}
                  />
                  <h6>Total adicionados: {productList?.length || 0}</h6>
                </div>
              </li>
            </ul>
            {selectCategory && (
              <div id="file-section">
                {fileList.map(pic => (
                  <div className="file-field">
                    <button
                      type="button"
                      className="erase-img"
                      onClick={() => handleDelete(pic.id)}
                    >
                      X
                    </button>
                    <span>{pic.name}</span>
                  </div>
                ))}
                <span />
              </div>
            )}
            <div className="table-section">
              <TableContainer>
                <tr>
                  <th>Motivo</th>
                  <th>Valor Total</th>
                  <th>Fotos</th>
                  <th> </th>
                </tr>
                {productList?.length ? (
                  <tbody className="row-container">
                    {productList?.map((product, index) => (
                      <tr>
                        <td>{product.justification}</td>
                        <td>
                          {(
                            product?.sell_price * product?.quantity
                          )?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td>{product?.photos?.length || 0}</td>
                        <td>
                          <button
                            type="button"
                            onClick={() => {
                              setProductList(list =>
                                list?.filter((prod, i) => i !== index),
                              );
                            }}
                          >
                            <Trash width="16px" height="16px" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tr>
                    <td>Nenhum</td>
                    <td>Nenhum</td>
                    <td>R$ 0,00</td>
                    <td>0</td>
                    <td> </td>
                  </tr>
                )}
              </TableContainer>
            </div>
          </section>
          <Form
            id="form"
            ref={formRef}
            onSubmit={!addProduct ? handleSubmit : setNewProduct}
          >
            {addProduct && (
              <AddProductForm
                handleProducts={handleProduct}
                category={selectCategory}
                ref={addProdRef}
              />
            )}

            <div className="buttons-section">
              {!addProduct ? (
                <div>
                  <RedButton
                    typeOf="button"
                    title="Cancelar"
                    setCustomState={setPopUpState}
                    customState={isOpen}
                  />
                  <GreenButton
                    typeOf="submit"
                    title="Salvar"
                    disabled={!productList?.length || pdvZero}
                  />
                </div>
              ) : (
                <div>
                  <RedButton
                    typeOf="button"
                    title="Cancelar"
                    setCustomState={setAddProduct}
                    customState={addProduct}
                  />
                  <BlueButton typeOf="submit" title="Adicionar" />
                </div>
              )}
            </div>
          </Form>
        </section>
      </PopUp>
    </Container>
  );
}
