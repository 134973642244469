import React, { useState } from 'react';

import PopUpEditOccurrence from '../../components/PopsUpOcurence/popUpEditOccurrence';

type OccurrenceProps = {
  data: string[];
};

const handleChange = () => {
  console.log('');
};

export default function SeeOccurrence({ data }: OccurrenceProps) {
  const [openEdit, setOpenEdit] = useState(false);

  return (
    <>
      <button
        type="button"
        className="ver-ocorrencia"
        onClick={() => setOpenEdit(!openEdit)}
      >
        Ver Ocorrência
      </button>

      {openEdit ? (
        <PopUpEditOccurrence
          isOpen={openEdit}
          setPopUpState={setOpenEdit}
          rowData={data}
          handleChange={handleChange}
        />
      ) : null}
    </>
  );
}
