/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

interface Props {
  name: string;
  value?: string;
  type?: string;
  change?(value: any): any;
  [x: string]: any;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

export default function Input({
  name,
  value,
  type,
  change,
  ...rest
}: InputProps) {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState<any>();
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      {change ? (
        <input
          ref={inputRef}
          type={type}
          defaultValue={defaultValue}
          value={inputValue || value}
          onChange={e => {
            setInputValue(e?.target?.value);
            change(e?.target?.value);
          }}
          {...rest}
        />
      ) : (
        <input
          ref={inputRef}
          type={type}
          defaultValue={defaultValue}
          value={inputValue || value}
          onChange={e => {
            setInputValue(e?.target?.value);
          }}
          {...rest}
        />
      )}

      {error && <small style={{ color: 'red' }}>{error}</small>}
    </>
  );
}
