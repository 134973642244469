import styled from 'styled-components';

export const Container = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: calc(9 * 99);
`;

export const PopUp = styled.div`
  width: 100%;
  max-width: 467px;
  min-height: 70px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 30px 30px 36px 30px;

  .pop-up-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding: 0;

    button {
      position: absolute;
      top: 0px;
      right: 0px;

      svg {
        width: 16px;
      }
    }
  }

  > p {
    margin: 8px 0 20px 0;
  }

  .description-section {
    display: block;

    textarea {
      min-height: 200px;
    }
  }

  .buttons-section {
    margin-top: 28px;
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: center;

    div {
      margin: 0 auto;
    }
  }
`;
