import styled, { keyframes } from 'styled-components';

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:900px;
  opacity:1;
}

`;

export const Container = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: calc(9 * 99);
`;

export const PopUp = styled.div`
  width: 100%;
  max-width: 467px;
  min-height: 1px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 14px 25px;
  overflow: hidden;
  animation: 1s ${entranceAnim} ease;

  .pop-up-header {
    width: 100%;
    height: 36px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding-left: 24px;

    button {
      position: absolute;
      top: 10px;
      right: 0px;

      svg {
        width: 16px;
      }
    }
  }

  form {
    ul {
      padding: 0 55px;

      li:nth-child(1) {
        margin-bottom: 28px;
      }
    }
    li {
      list-style: none;

      textarea {
        min-height: 170px;
        margin-top: 6px;

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        ::-webkit-scrollbar-thumb {
          background: #99a0aa;
          border-radius: 99px;
        }

        &::-webkit-scrollbar {
          width: 4px;

          border-radius: 99px;
        }
      }
    }

    input {
      margin-top: 6px;
    }

    .switch-button {
      margin: 28px 0;
      display: flex;
      justify-content: center;
    }
  }

  label {
    margin-bottom: 6px;
    display: block;
    --h6Size: 12px;
    --h6Weight: 400;
    --h6Letter: 5%;
    --h6Line: 1.2em;
  }

  .button-field {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
  }
`;
