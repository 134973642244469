import React from 'react';

import styled from 'styled-components';
import { ReactComponent as AngleUp } from '../../icons/angle-up-red-icon.svg';
import { ReactComponent as AngledOWN } from '../../icons/angle-down-blue-icon.svg';

type precoProp = {
  precoMin?: string;
  precoMax?: string;
};

PrecoMaxMin.defaultProps = {
  precoMin: 'R$ 100',
  precoMax: 'R$ 5.000',
};

export default function PrecoMaxMin({ precoMin, precoMax }: precoProp) {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 11px;

    h6 {
      max-width: 90px;
      position: relative;

      font-size: var(--h6Size) !important;
      font-weight: var(--h6Weight) !important;
      letter-spacing: var(--h6Letter) !important;
      line-height: var(--h6Line) !important;

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        right: 10px;
      }
    }

    .preco-min {
    }
  `;

  return (
    <Container className="table-precos">
      <h6 className="preco-max">
        R$ {precoMax} <AngleUp />{' '}
      </h6>
      <h6 className="preco-min">
        R$ {precoMin} <AngledOWN />{' '}
      </h6>
    </Container>
  );
}
