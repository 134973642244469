// eslint-disable-next-line import/no-unresolved
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  flex-basis: 540px;
  flex-grow: 1;
  min-height: 467px;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  overflow: hidden;

  p {
    margin-bottom: 40px;
  }

  .carregando {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .carregando2 {
      background: #fff;
      opacity: 0.6;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3;
    }
    img {
      height: 50px;
      z-index: 4;
    }
  }
`;
