import React, { ReactElement, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { ReactComponent as AngleRight } from '../../../icons/angle-right-icon.svg';
import { Permission } from '../../../models';
import { CascadingList } from './styles';

type ItensSecification = {
  icon: ReactElement;
  title: string;
  hide?: boolean;
  subMenu: SubmenuItem[];
  forceState?: boolean;
};

interface SubmenuItem {
  title: string;
  path: string;
  permission?: Permission;
  hide?: boolean;
}

export default function MenuCascating({
  icon,
  title,
  hide,
  subMenu,
  forceState = false,
}: ItensSecification) {
  const [menuState, setMenuState] = useState(forceState);
  const { user } = useAuth();

  if (!hide) {
    return (
      <CascadingList className={menuState ? 'menu-active' : ''}>
        <button
          type="button"
          onClick={() => setMenuState(!menuState)}
          className="nav-link"
        >
          <span>
            {icon} {title}
          </span>
          <AngleRight
            className="angle-icon"
            style={menuState ? { transform: 'rotate(90deg)' } : {}}
          />
        </button>
        <ul
          style={menuState ? { maxHeight: '300px' } : { maxHeight: '0px' }}
          className="menu-itens "
        >
          {subMenu.map(e =>
            typeof e?.permission === 'string' ? (
              user?.role?.permissions?.includes(e?.permission) && (
                <li>
                  <NavLink activeClassName="active-link-gm" to={e.path}>
                    {e.title}
                  </NavLink>
                </li>
              )
            ) : (
              <>
                {!e?.hide && (
                  <li>
                    <NavLink activeClassName="active-link-gm" to={e.path}>
                      {e.title}
                    </NavLink>
                  </li>
                )}
              </>
            ),
          )}
          <li />
        </ul>
      </CascadingList>
    );
  }
  return <></>;
}
