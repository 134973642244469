/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { differenceInDays, formatISO } from 'date-fns';
import Input from '../../components/Form/input';
import Select from '../../components/Form/select';
import BlueButton from '../../components/blueButton';
import Priority from '../../components/Table/components/priority';
import Steps from './components/steps';
import { Container } from './styles';
import api from '../../services/api';
import { useError } from '../../hooks/errors';
import { useUsers } from '../../hooks/users';
import { useAuth } from '../../hooks/auth';
import SeeOccurrence from './current';
import { OccurrencesProvider, useOccurrences } from '../../hooks/occurrences';

export default function AcompanharOcorrencias() {
  const [filteredData, setFilteredData] = useState(null);
  const { emitError } = useError();
  const { user } = useAuth();
  const { users, getUsers } = useUsers();
  const [usersList, setUsersList] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const { occurrences, getOccurrences } = useOccurrences();

  useEffect(() => {
    if (
      !users &&
      user.role.basicRole !== 'USER' &&
      user.role.basicRole !== 'USER2'
    ) {
      getUsers();
    }

    if (!occurrences?.length) getOccurrences();
  }, []);

  useEffect(() => {
    if (!users) return;
    const usersInformation = users?.map((e: any) => ({
      name: e.name,
      id: e.id,
    }));

    const firtsObj = { name: 'Solicitante', id: '' };
    usersInformation.splice(0, 1, firtsObj);
    setUsersList(usersInformation);
  }, [users]);

  async function handleFilter(formData: any) {
    try {
      if (formData.num && (formData.user_id || formData.priority)) {
        const filterTable = await api.get(`/occurrences/num/${formData.num}`);
        setFilteredData(filterTable.data);
      } else if (!formData.num) {
        setFilteredData(null);

        const body = {} as any;
        if (formData?.priority) {
          body.priority = formData.priority;
        }
        if (formData?.user_id) {
          body.user_id = formData.user_id;
        }
        const filterTable = await api.post('/occurrences/follow', body);

        setFilteredData(filterTable.data);
      } else {
        const filterTable = await api.get(`/occurrences/num/${formData.num}`);
        setFilteredData(filterTable.data);
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  const getPriority = (create_at: string) => {
    const priority = differenceInDays(new Date(), new Date(create_at));

    if (priority <= 3) return 'Baixa';
    if (priority > 3 && priority < 6) return 'Média';
    return 'Alta';
  };

  const priority = [
    {
      name: 'Prioridade',
      id: '',
    },
    {
      name: 'Baixa',
      id: 'LOW',
    },
    {
      name: 'Média',
      id: 'MEDIUM',
    },
    {
      name: 'Alta',
      id: 'HIGH',
    },
  ];

  return (
    <Container>
      <section className="table">
        <p>Acompanhar Ocorrência</p>

        <Form onSubmit={handleFilter}>
          <div className="filter-top">
            <label htmlFor="chamado">
              Chamado
              <Input name="num" type="number" id="chamado" placeholder="0000" />
            </label>

            {!users ? null : (
              <label>
                Solicitante
                <Select optValue name="user_id" fields={usersList} />
              </label>
            )}

            <label>
              Prioridade
              <Select optValue name="priority" fields={priority} />
            </label>
          </div>

          <BlueButton typeOf="submit" title="Filtrar" />
        </Form>
      </section>
      {filteredData === null ? (
        occurrences.slice(0, 5).map(e => (
          <>
            <section className="table-filtered">
              <div className="header-filtered">
                <p>Chamado {e.num}</p>

                <div className="main-info">
                  <span>
                    <h6>Valor total da devolução </h6>
                    {e?.total_price?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>
                  <span>
                    <h6>Solicitante </h6>
                    {e.user.name}
                  </span>
                  <span>
                    <h6>Prioridade </h6>

                    <Priority title={getPriority(e.create_at)} />
                  </span>

                  <SeeOccurrence data={e} />
                </div>
              </div>

              <Steps status={e} />
            </section>
          </>
        ))
      ) : (
        <>
          {filteredData?.map(item => (
            <section className="table-filtered">
              <div className="header-filtered">
                <p>Chamado {item.num}</p>

                <div className="main-info">
                  <span>
                    <h6>Valor total da devolução </h6>
                    {item?.total_price?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>
                  <span>
                    <h6>Solicitante </h6>
                    {item.status.next_user.name}
                  </span>
                  <span>
                    <h6>Prioridade </h6>

                    <Priority title={getPriority(item.create_at)} />
                  </span>

                  <SeeOccurrence data={item} />
                </div>
              </div>

              <Steps status={item} />
            </section>
          ))}
        </>
      )}
    </Container>
  );
}
