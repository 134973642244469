/* eslint-disable import/prefer-default-export */
import React from 'react';

import { ReactComponent as OcorrenciaIcon } from '../../../icons/ocorrencia-icon.svg';
import { ReactComponent as DashIcon } from '../../../icons/dash-icon.svg';
import { ReactComponent as PDVIcon } from '../../../icons/ponto-de-venda-icon.svg';
import { ReactComponent as UserIcon } from '../../../icons/users-icon.svg';
import { ReactComponent as ProductIcon } from '../../../icons/products-icon.svg';

export const adminRoute = [
  {
    title: 'Ocorrência',
    icon: <OcorrenciaIcon />,
    itens: ['Central de Ocorrências', 'Acompanhar Ocorrência'],
  },
  {
    title: 'Dashboard',
    icon: <DashIcon />,
    itens: ['Geral', 'Rede'],
  },
  {
    title: 'Ponto de Venda',
    icon: <PDVIcon />,
    itens: ['Ponto de Venda', 'Redes', 'Regionais', 'Canal', 'Contrato'],
  },
  {
    title: 'Usuários',
    icon: <UserIcon />,
    itens: ['Usuários', 'Equipe', 'Cargos'],
  },
  {
    title: 'Produtos',
    icon: <ProductIcon />,
    itens: ['Produtos', 'Categorias'],
  },
];

export const superRoute = [
  {
    title: 'Ocorrência',
    icon: <OcorrenciaIcon />,
    itens: ['Central de Ocorrências', 'Acompanhar Ocorrência'],
  },

  {
    title: 'Ponto de Venda',
    icon: <PDVIcon />,
    itens: ['Ponto de Venda', 'Redes', 'Regionais', 'Canal', 'Contrato'],
  },
  {
    title: 'Usuários',
    icon: <UserIcon />,
    itens: ['Usuários', 'Equipe', 'Cargos'],
  },
  {
    title: 'Produtos',
    icon: <ProductIcon />,
    itens: ['Produtos', 'Categorias'],
  },
];

export const managerRoute = [
  {
    title: 'Ocorrência',
    icon: <OcorrenciaIcon />,
    itens: ['Central de Ocorrências', 'Acompanhar Ocorrência'],
  },

  {
    title: 'Ponto de Venda',
    icon: <PDVIcon />,
    itens: ['Ponto de Venda', 'Redes', 'Regionais', 'Canal', 'Contrato'],
  },
  {
    title: 'Usuários',
    icon: <UserIcon />,
    itens: ['Usuários', 'Equipe', 'Cargos'],
  },
  {
    title: 'Produtos',
    icon: <ProductIcon />,
    itens: ['Produtos', 'Categorias'],
  },
];

export const userRoute = [
  {
    title: 'Ocorrência',
    icon: <OcorrenciaIcon />,
    itens: ['Central de Ocorrências', 'Acompanhar Ocorrência'],
  },
];
