/* eslint-disable no-param-reassign */

/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-useless-escape */
import { toast } from 'react-toastify';
import React, { useRef, useEffect, useState } from 'react';
import { ReactComponent as CloseButton } from '../../../icons/blue-close-button-icon.svg';
import BlueButton from '../../blueButton';
import api from '../../../services/api';
import { Container, PopUp } from './styles';
import AddProduct from './components/AddProduct';
import { useError } from '../../../hooks/errors';
import { useAuth } from '../../../hooks/auth';
import CancelPopUp from '../../CancelPopUp';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  rowData?: string[] | any;
};

AddOccurrence.defaultProps = {
  rowData: false,
};

export default function AddOccurrence({
  isOpen,
  setPopUpState,
  rowData,
}: popUpProp) {
  const { emitError } = useError();
  const [pdvInfo, setPdvInfo] = useState([]);
  const [steps, setSteps] = useState('pdv');
  const [pdvId, setPDVID] = useState('');
  const [cnpj, setCNPJ] = useState();

  const { user } = useAuth();
  const popUpRef = useRef(null);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  const handleCnpjFilter = e => {
    let filtered = e.value;
    filtered = filtered.replace(/[^0-9%]/gi, '');
    if (filtered?.length > 14) return;
    filtered = filtered.replace(/(\d{2})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d)/, '$1.$2');
    filtered = filtered.replace(/(\d{3})(\d{1,2})/, '$1/$2');
    filtered = filtered.replace(/(\d{4})(\d{1,2})/, '$1-$2');

    setCNPJ(filtered);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  async function GetPDV(e: any) {
    handleCnpjFilter(e);

    const rawCNPJ = e?.value?.replace(/[^0-9%]/gi, '');

    try {
      if (rawCNPJ?.length === 14) {
        const postOcurrence = await api.get(`/pdvs/${rawCNPJ}`);

        const parseInfo = postOcurrence.data;

        const pdvInformation = [
          {
            titulo1: 'CNPJ',
            conteudo: parseInfo.details.cnpj,
          },

          {
            titulo1: 'Nome Fantasia',
            conteudo: parseInfo.name,
          },
          {
            titulo1: 'Contrato',
            conteudo: parseInfo.contract.name,
          },
          {
            titulo1: 'Cidade',
            conteudo: parseInfo.details.city,
          },
          {
            titulo1: 'Estado',
            conteudo: parseInfo.details.state,
          },
        ];
        setPDVID(postOcurrence.data.details.pdv_id);
        setPdvInfo(pdvInformation);
      }
    } catch (error: any) {
      emitError({}, 'CNPJ inválido');
    }
  }

  const getPdvId = (
    <div className="codigo-pdv">
      <label htmlFor="pdv">CNPJ</label>
      <input
        name="details.cnpj"
        id="pdv"
        placeholder="00.000.000/0000-00"
        value={cnpj}
        onChange={e => GetPDV(e.target)}
      />

      <div className="buttons-section">
        <BlueButton
          title="Próximo"
          setCustomState={setSteps}
          customState="info"
          disabled={!pdvId}
        />
        <button
          type="button"
          className="cancel-button"
          onClick={() => setPopUpState(!isOpen)}
        >
          Cancelar
        </button>
      </div>
    </div>
  );

  const noTeamCase = (
    <div className="no-team">
      <h6>Você precisa estar viculado a um time para abir ocorrências</h6>

      <button type="button" onClick={() => setPopUpState(false)}>
        {' '}
        Voltar{' '}
      </button>
    </div>
  );

  return (
    <Container>
      <PopUp ref={popUpRef} stepsProps={steps}>
        <section className="mobile-section">
          {user?.role?.permissions?.includes('canOpenOccurrence') && (
            <div className="pop-up-header">
              <p> Nova Ocorrência</p>

              <button
                type="button"
                onClick={() => setPopUpState(!isOpen)}
                className="close-button"
              >
                <CloseButton />
              </button>
            </div>
          )}

          {user.teams.length <= 0 ? noTeamCase : getPdvId}

          <div className="pdv-info">
            <ul>
              {pdvInfo.map(e => (
                <li>
                  <h6> {e.titulo1} </h6> <h6>{e.conteudo}</h6>
                </li>
              ))}
            </ul>
            <div className="buttons-section">
              <BlueButton
                title="Confirmar PDV"
                setCustomState={setSteps}
                customState="product"
              />
              <button
                type="button"
                className="cancel-button"
                onClick={() => setSteps('pdv')}
              >
                Cancelar
              </button>
            </div>
          </div>

          <div className="addproduct">
            <AddProduct
              pdvId={pdvId}
              pdvData={pdvInfo}
              setPopUpState={setPopUpState}
              isOpen={isOpen}
            />
          </div>
        </section>
      </PopUp>
    </Container>
  );
}
