import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

html, body{

  width:100%;

  min-height:100%;
  height:100%;
  margin: 0;
  padding:0;
  font-family: 'Montserrat', sans-serif;
  overflow-x:hidden;
}




.rs-picker-daterange-menu{
  z-index: 999999;
}


body *{

  outline: none;
  box-sizing:border-box;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  padding:0;
  list-style: none;
}


button{
  background:none;
  border:0;
  cursor:pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 10%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%2387abdb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

input, select, textarea {
      position: relative;
      width: 100%;
      padding: 10px 44px 10px 16px;
      border: 1px solid #abccf7;
      border-radius: 12px;
      color: #99a0aa;
      font-size: var(--pSize);
      font-weight: var(--pWeight);
      resize: none;


      &::placeholder{
        color:#d1d1d1;
      }
    }


    .edit-pdv {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      margin-top: 28px;
      transition: 0ms.3s;
    }

h1, h2, h3, h4, h5, h6, p, td, th, tr{
  font-family: 'Montserrat', sans-serif;
  margin:0;
}

#root{
  width: 100%;
  height: 100%;
  display:grid;





.Toastify{
  position: absolute;
  top:0;
  right:40px;
  left:0;
  overflow:hidden;
  bottom:0;
}

/* Cores globais do sistema */
--darkBlue: #0054A6;
--darkBluePDV: #82B2F3;
--lightBlue:#0099D9;
--teal:#00ADB5;
--orange:#F8971D;
--green:#6CC04A;
--red:#E32726;
--lightGrey:#E6E7E8;
--mediumGrey:#939598;
--pdv0:#404249;
--darkGrey:#4C4D4F;
--pdvNeutro: #404249;
--neutral: #f5f5f5;
--bgLight: #fafafa;
--white: #fff;

/* H1 fonte estilo */
--h1Size: 50px;
--h1Weight: 700;
--h1Letter: 5%;
--h1Line: 1.2em;

/* H2 fonte estilo */
--h2Size: 40px;
--h2Weight: 700;
--h2Letter: 5%;
--h2Line: 1.2em;

/* H3 fonte estilo */
--h3Size: 30px;
--h3Weight: 700;
--h3Letter: 5%;
--h3Line: 1.2em;

/* H4 fonte estilo */
--h4Size: 25px;
--h4Weight: 400;
--h4Letter: 5%;
--h4Line: 1.2em;

/* H5 fonte estilo */
--h5Size: 20px;
--h5Weight: 400;
--h5Letter: 5%;
--h5Line: 1.2em;

/* H6 fonte estilo */
--h6Size: 12px;
--h6Weight: 400;
--h6Letter: 5%;
--h6Line: 1.2em;

/* p fonte estilo */
--pSize: 16px;
--pWeight: 400;
--pLetter: 5%;
--pLine: 1.2em;

/* small fonte estilo */
--smallSize: 8px;
--smallWeight: 600;
--smallLetter: 5%;
--smallLine: 1.2em;

}


h1{
font-size: var(--h1Size);
font-weight: var(--h1Weight);
letter-spacing: var(--h1Letter);
line-height: var(--h1Line);
}

h2{
font-size: var(--h2Size);
font-weight: var(--h2Weight);
letter-spacing: var(--h2Letter);
line-height: var(--h2Line);
}

h3{
font-size: var(--h3Size);
font-weight: var(--h3Weight);
letter-spacing: var(--h3Letter);
line-height: var(--h3Line);
}
h4{
font-size: var(--h4Size);
font-weight: var(--h4Weight);
letter-spacing: var(--h4Letter);
line-height: var(--h4Line);
}
h5{
font-size: var(--h5Size);
font-weight: var(--h5Weight);
letter-spacing: var(--h5Letter);
line-height: var(--h5Line);
}
h6{
font-size: var(--h6Size);
font-weight: var(--h6Weight);
letter-spacing: var(--h6Letter);
line-height: var(--h6Line);
}

p{
font-size: var(--pSize);
font-weight: var(--pWeight);
letter-spacing: var(--pLetter);
line-height: var(--pLine);
}


small{

font-size: var(--smallSize);
font-weight: var(--smallWeight);
letter-spacing: var(--smallLetter);
line-height: var(--smallLine);
}



@media screen and (max-width:880px){

#root{

  display: block;
}


.rs-picker-menu{
  width: 250px;
  left:20px !important;
}
}


`;
