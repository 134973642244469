import styled, { keyframes } from 'styled-components';
import Input from '../Form/input';

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:900px;
  opacity:1;
}

`;

const animation = keyframes`

from {
  left:700px;
}

to {
  left:00px;
}

`;

export const Container = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: calc(9 * 99);
  @media screen and (max-width: 880px) {
    top: 54px;
    background: none;
  }
`;

export const PopUp = styled.div`
  width: 100%;
  max-width: 1011px;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 14px 8px 16px 8px;
  position: relative;
  animation: 0.6s ease ${entranceAnim};

  @media screen and (max-width: 880px) {
    max-height: unset;
    height: 100%;
    border-radius: 0px;

    .desktop-section {
      display: none;
    }

    .pop-up-header-mobile {
      display: flex !important;
    }
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
  }

  &::-webkit-scrollbar {
    width: 1px;
    border-radius: 99px;
  }

  .pop-up-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding: 0 76px 0 24px;
    margin-bottom: 12px;

    .close-button {
      position: absolute;
      top: 10px;
      right: 16px;

      svg {
        width: 16px;
      }
    }

    .occurrence-options {
      display: flex;
      gap: 26px;
    }
  }

  .table-title {
    display: block;
    background: rgba(0, 173, 181, 0.05);
    padding: 8.5px 14px;
  }

  .table-list {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 8px 20px 20px 20px;
  }

  .dados-do-chamado {
    width: 100%;
    li {
      width: 100%;
      flex-direction: row !important;
      align-items: center;
      justify-content: space-between;

      p {
        margin-bottom: 6px;
      }
      small {
        margin: 0 auto;
      }
      .add-product-field {
        flex-basis: 328px;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .product-table-content {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .input-divider {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }

    p,
    label {
      font-size: 14px;
      color: var(--darkGrey);
      font-weight: 700;

      letter-spacing: var(--pLetter);
      line-height: var(--pLine);
    }

    h6 {
      margin-top: 12px;
    }
  }

  .table-content {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 6px;

    p,
    label {
      font-size: 14px;
      color: var(--darkGrey);
      font-weight: 700;

      letter-spacing: var(--pLetter);
      line-height: var(--pLine);
    }

    h6 {
      margin-top: 12px;
    }
  }

  .add-product,
  .desactive {
    position: fixed;
    opacity: 0;
    pointer-events: none;
  }

  .active {
    position: relative !important;
    opacity: 1 !important;
    pointer-events: unset !important;
    animation: 0.5s ease ${animation};
  }

  #picture-section {
    margin-bottom: 30px;
    padding: 8px 20px 20px 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    max-height: 250px !important;
    overflow: auto !important;

    .picture-field {
      width: 100%;
      max-width: 220px;
      height: 220px;
      background-size: cover;
      background-position: center;
      position: relative;
      border-radius: 12px;
      border: 1px solid #abccf7;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .erase-img {
        position: absolute;
        top: 6px;
        right: 6px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.6);
        font-weight: 800;
        font-family: 'Nunito', sans-serif;
        color: white;
        padding: 4px 10px;

        &:hover {
          background: rgba(0, 0, 0, 0.75);
        }
      }

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      svg {
        pointer-events: none;
      }

      img {
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

interface ReportProps {
  fileName?: string;
}

export const ReportInput = styled(Input)<ReportProps>`
  color: transparent;
  cursor: pointer;
  position: relative;
  max-height: 43px;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &::before {
    content: '${props => props?.fileName || 'exemplo.pdf'}';
    color: #99a0aa;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    margin-right: auto;

    width: 86%;
    height: 100%;
    z-index: calc(10 * 99);
  }
`;
