import React, { useRef, useEffect, useState } from 'react';
import { isValid } from 'date-fns';
import { Container } from './styles';

type StepsProps = {
  status?: string[] | any;
};
Steps.defaultProps = {
  status: '',
};

export default function Steps({ status }: StepsProps) {
  const [currentPosition, setCurrentPosition] = useState(0);
  const [currentSize, setCurrentSize] = useState(0);
  const data = [
    {
      title: 'Abertura ocorrência',
      create_at: status?.status?.history[0]?.create_at
        ? `
        ${new Date(
          status?.status?.history[0]?.create_at,
        ).toLocaleDateString()} às
         ${new Date(
           status?.status?.history[0]?.create_at,
         ).toLocaleTimeString()}`
        : '00/00/00 ás 00:00',
      done:
        status?.status?.current === 0 ||
        status?.status?.current === 1 ||
        status?.status?.current === 2 ||
        status?.status?.current === 3 ||
        status?.status?.current === 4 ||
        status?.status?.current === 5,
    },
    {
      title: `Em Análise - ${status?.status?.history[0]?.next_user?.name}`,
      create_at: status?.status?.history[0]?.create_at
        ? `
        ${new Date(
          status?.status?.history[0]?.create_at,
        ).toLocaleDateString()} às
         ${new Date(
           status?.status?.history[0]?.create_at,
         ).toLocaleTimeString()}`
        : '00/00/00 ás 00:00',
      done:
        status?.status?.current === 1 ||
        status?.status?.current === 2 ||
        status?.status?.current === 3 ||
        status?.status?.current === 4 ||
        status?.status?.current === 5,
    },
    {
      title: `Aprovação - ${status?.status?.history[0]?.next_user?.name}`,
      create_at: status?.status?.history[1]?.create_at
        ? `
          ${new Date(
            status?.status?.history[1]?.create_at,
          ).toLocaleDateString()} às
           ${new Date(
             status?.status?.history[1]?.create_at,
           ).toLocaleTimeString()}`
        : '00/00/00 ás 00:00',
      done:
        status?.status?.current === 2 ||
        status?.status?.current === 3 ||
        status?.status?.current === 4 ||
        status?.status?.current === 5,
    },
    {
      title: `Em Análise - ${status?.status?.history[1]?.next_user?.name}`,
      create_at: status?.status?.history[1]?.create_at
        ? `
        ${new Date(
          status?.status?.history[1]?.create_at,
        ).toLocaleDateString()} às
         ${new Date(
           status?.status?.history[1]?.create_at,
         ).toLocaleTimeString()}`
        : '00/00/00 ás 00:00',
      done:
        status?.status?.current === 3 ||
        status?.status?.current === 4 ||
        status?.status?.current === 5,
    },
    {
      title: `Aprovação - ${status?.status?.history[1]?.next_user?.name}`,
      create_at: status?.status?.history[2]?.create_at
        ? `
        ${new Date(
          status?.status?.history[2]?.create_at,
        ).toLocaleDateString()} às
         ${new Date(
           status?.status?.history[2]?.create_at,
         ).toLocaleTimeString()}`
        : '00/00/00 ás 00:00',
      done: status?.status?.current === 4 || status?.status?.current === 5,
    },
    {
      title: `Aprovação - ${status?.status?.history[2]?.next_user?.name}`,
      create_at: status?.status?.history[3]?.create_at
        ? `
        ${new Date(
          status?.status?.history[3]?.create_at,
        ).toLocaleDateString()} às
         ${new Date(
           status?.status?.history[3]?.create_at,
         ).toLocaleTimeString()}`
        : '00/00/00 ás 00:00',
      done: status?.status?.current === 5,
    },
  ];

  const currentState: React.MutableRefObject<any> = useRef(null);

  useEffect(() => {
    if (status?.status?.current < 4) {
      const position = currentState?.current?.offsetLeft;
      setCurrentPosition(position);
    }
  }, [status?.status?.current, currentSize]);

  function seeWindowSize() {
    setCurrentSize(window.innerWidth);
  }

  window.addEventListener('resize', seeWindowSize);

  return (
    <Container size={currentPosition} status={status?.status}>
      {data.slice(0, status?.status?.final + 1).map(e => (
        <div className="current-state">
          <h6> {e.title} </h6>

          <span
            className={e.done === true ? 'current-active' : ''}
            ref={e.done === true ? currentState : null}
          />

          <small>{e.create_at}</small>
        </div>
      ))}
    </Container>
  );
}
