import MaterialTable from 'material-table';
import styled, { keyframes } from 'styled-components';

const entranceAnim = keyframes`

from {
  width:0px;
  max-height:0px;
  opacity:0;
}
to {
  width:100%;
  max-height:900px;
  opacity:1;
}

`;

const animation = keyframes`

from {
  left:700px;
}

to {
  left:00px;
}

`;

export const Container = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: calc(9 * 99);
  @media screen and (max-width: 880px) {
    top: 54px;
    background: none;
  }
`;

export const PopUp = styled.div`
  width: 100%;
  max-width: 80vw;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 14px 8px 16px 8px;
  position: relative;
  animation: 0.6s ease ${entranceAnim};

  @media screen and (max-width: 880px) {
    max-height: unset;
    height: 100%;
    border-radius: 0px;

    .desktop-section {
      display: none;
    }

    .pop-up-header-mobile {
      display: flex !important;
    }
  }

  .pop-up-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding: 0 76px 0 24px;
    margin-bottom: 12px;

    .close-button {
      position: absolute;
      top: 10px;
      right: 16px;

      svg {
        width: 16px;
      }
    }

    .occurrence-options {
      display: flex;
      gap: 26px;
    }
  }

  .table-title {
    display: block;
    background: rgba(0, 173, 181, 0.05);
    padding: 8.5px 14px;
    position: relative;
  }

  .table-title div {
    font-size: 16px;
    background-color: #f4b183;
    color: white;
    position: absolute;
    top: 8px;
    right: 20px;
  }

  .table-list {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 8px 20px 20px 20px;
  }

  .dados-do-chamado {
    width: 100%;
    li {
      width: 100%;
      flex-direction: row !important;
      justify-content: space-between;

      p {
        margin-bottom: 6px;
      }
      small {
        margin: 0 auto;
      }
      .add-product-field {
        flex-basis: 328px;
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .add-file-field {
        flex-basis: 200px;
        height: 43px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        background: var(--lightBlue);

        p {
          color: white;
          font-size: 12px;
          font-weight: 600;
          margin: 0;
          cursor: pointer;
        }

        input {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
  }
  .product-table-content {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .input-divider {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .report-file {
        color: transparent;
        cursor: pointer;
        position: relative;
        max-height: 43px;
        &::-webkit-file-upload-button {
          visibility: hidden;
        }
        &::before {
          content: 'laudo.pdf';
          color: #99a0aa;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;

          width: 100%;
          height: 100%;
          z-index: calc(10 * 99);
        }
      }
    }

    p,
    label {
      font-size: 14px;
      color: var(--darkGrey);
      font-weight: 700;

      letter-spacing: var(--pLetter);
      line-height: var(--pLine);
    }

    h6 {
      margin-top: 12px;
    }
  }

  .table-content {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 6px;

    p,
    label {
      font-size: 14px;
      color: var(--darkGrey);
      font-weight: 700;

      letter-spacing: var(--pLetter);
      line-height: var(--pLine);
    }

    h6 {
      margin-top: 12px;
    }
  }

  .table-content-search {
    position: relative;
    input {
      width: 90%;
    }
    button {
      position: absolute;
      bottom: 8px;
      right: 0;
    }
  }

  .table-section {
    border-radius: 20px;
    border: solid 1px var(--lightBlue);
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 58px;

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0);
    }

    &::-webkit-scrollbar {
      width: 1px;
      border-radius: 99px;
    }

    .MuiToolbar-regular {
      display: none;
    }
  }

  .desktop-section #form {
    position: relative;
  }

  .buttons-section {
    width: 100%;
    height: 56px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    left: 0;
    background: white;
    z-index: 99;

    > span {
      padding: 13px 29px;
      border: solid 1px var(--lightBlue);
      border-radius: 12px;
      display: flex;
      align-items: center;
      font-family: 'Exo 2';
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 120%;
      color: var(--lightBlue);
      &::before {
        content: 'Código de Aprovação:';
        margin-right: 5px;
        display: inline-block;
        color: var(--darkGrey);
      }
    }

    > div {
      flex-basis: 400px;
      display: flex;
      gap: 21px;

      button {
        width: 100%;
        max-width: 233px;
      }
    }
  }

  .add-product,
  .desactive {
    position: fixed;
    opacity: 0;
    pointer-events: none;
  }

  .active {
    position: relative !important;
    opacity: 1 !important;
    pointer-events: unset !important;
    animation: 0.5s ease ${animation};
  }

  #picture-section {
    margin-bottom: 30px;
    padding: 8px 20px 20px 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    max-height: 250px !important;
    overflow: auto !important;

    .picture-field {
      width: 100%;
      max-width: 220px;
      height: 220px;
      background-size: cover;
      background-position: center;
      position: relative;
      border-radius: 12px;
      border: 1px solid #abccf7;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .erase-img {
        position: absolute;
        top: 6px;
        right: 6px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.6);
        font-weight: 800;
        font-family: 'Nunito', sans-serif;
        color: white;
        padding: 4px 10px;

        &:hover {
          background: rgba(0, 0, 0, 0.75);
        }
      }

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      svg {
        pointer-events: none;
      }

      img {
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  #file-section {
    padding: 8px 20px 20px 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    overflow: auto !important;

    .file-field {
      width: 100%;
      max-width: 200px;
      height: 50px;
      background-size: cover;
      background-position: center;
      position: relative;
      border-radius: 12px;
      border: 1px solid #abccf7;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;

      .erase-img {
        position: absolute;
        top: 6px;
        right: 6px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.6);
        font-weight: 800;
        font-family: 'Nunito', sans-serif;
        color: white;
        padding: 4px 10px;

        &:hover {
          background: rgba(0, 0, 0, 0.75);
        }
      }

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      span {
        font-size: 12px;
        padding: 5px;
        overflow: hidden;
        width: calc(100% - 30px);
      }
    }
  }
`;

export const TableContainer = styled.table`
  width: 100%;
  tr {
    height: 54px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  td {
    text-align: center;
  }

  tbody {
    max-height: 162px;
    overflow-y: auto;
  }
`;

// export const TableContainer = styled.div`
//   width: 100%;

//   .MuiPaper-root {
//     box-shadow: none;

//     .Component-horizontalScrollContainer-12 {
//       padding: 0 16px;
//     }
//     h6 {
//       font-size: var(--pSize);
//       font-weight: var(--pWeight);
//       letter-spacing: var(--pLetter);
//       line-height: var(--pLine);
//       color: #404249;
//       font-family: 'Montserrat', sans-serif !important;
//     }

//     th {
//       text-align: left;
//       font-family: 'Montserrat', sans-serif !important;
//       div {
//         font-size: var(--smallSize);
//         font-weight: var(--smallWeight);
//         letter-spacing: var(--smallLetter);
//         line-height: var(--smallLine);
//         color: #66686d;
//       }

//       .MuiButtonBase-root {
//         flex-direction: row;
//       }
//     }

//     td {
//       padding: 26px 16px;
//       font-size: var(--h6Size) !important;
//       font-weight: var(--h6Weight) !important;
//       letter-spacing: var(--h6Letter);
//       line-height: var(--h6Line);
//       color: rgba(102, 104, 109, 1);
//       font-family: 'Montserrat', sans-serif !important;
//     }

//     .MuiIconButton-root,
//     .MuiTypography-caption {
//       color: var(--darkBluePDV);
//     }

//     .MuiTableCell-footer {
//       border: 0;
//     }
//   }
// `;
