import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PopUpPreco from '../../../../components/popUpPreco';
import { ReactComponent as BlueEye } from '../../../../icons/blue-eye-icon.svg';

type priceProps = {
  price: number | string;
};

export default function PrecoPadrao({ price = 'não informado' }: priceProps) {
  const [color, setColor] = useState('#000');
  const [openStatus, setOpenStatus] = useState(false);

  useEffect(() => {
    if (price >= 5000) {
      setColor('var(--red)');
    } else if (price <= 99) {
      setColor('var(--orange)');
    } else if (price >= 100 && price <= 4999) {
      setColor('var(--green)');
    }
  }, [price]);

  const Span = styled.span`
    .price-nonstandard {
      display: flex;
      align-items: center;
      position: relative;

      &::before {
        content: '•';
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        left: -10px;
        color: ${color};
        font-size: 30px;
      }

      button {
        svg {
          margin-left: 10px;
        }
      }
    }
  `;

  const PrecoForadoPadrao = () => {
    if (price === 'não informado') {
      return price;
    }

    if (price >= 5000 || price < 99) {
      return (
        <div className="price-nonstandard">
          {price.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
          <button type="button" onClick={() => setOpenStatus(!openStatus)}>
            <BlueEye />
          </button>
          {openStatus ? (
            <PopUpPreco setPopUpState={setOpenStatus} isOpen={openStatus} />
          ) : null}
        </div>
      );
    }

    return (
      <div className="price-nonstandard">
        {price.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}
      </div>
    );
  };

  return <Span>{PrecoForadoPadrao()}</Span>;
}
