import styled, { keyframes } from 'styled-components';

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:900px;
  opacity:1;
}

`;

export const Container = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: calc(9 * 99);
`;

export const PopUp = styled.div`
  width: 100%;
  max-width: 467px;
  min-height: 1px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 14px 25px;
  overflow: hidden;
  animation: 0.4s ${entranceAnim} ease;

  h6 {
    margin: 20px 0;
    color: var(--pdvNeutro);
    font-weight: 600 !important;
  }

  .edit-pdv {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-top: 28px;
    transition: 0ms.3s;
  }

  .pop-up-header {
    width: 100%;
    height: 36px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding-left: 24px;

    button {
      position: absolute;
      top: 10px;
      right: 0px;

      svg {
        width: 16px;
      }
    }
  }

  form {
    padding: 0 55px;

    input {
      margin-top: 6px;
    }
  }

  label {
    --h6Size: 12px;
    --h6Weight: 400;
    --h6Letter: 5%;
    --h6Line: 1.2em;
  }

  .button-field {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
  }
`;
