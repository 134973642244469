/* eslint-disable prettier/prettier */
// eslint-disable-next-line import/no-unresolved
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Header = styled.header`
  width: 100%;
  min-height: 84px;
  background: var(--neutral);
  padding: 22px 24px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media screen and (max-width: 880px) {
    height: 54px;
    min-height: unset;
    background: var(--darkBlue);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    padding: 0;

    .user-information,
    .user-controllers {
      display: none !important;
    }
  }

  .user-information {
    display: flex;
  }

  .user-perfil-pic {
    width: 38px;
    height: 38px;
    margin-right: 21px;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      background: #030303;
      border-radius: 99px;
    }
  }

  .user-name {
    p {
      margin: 0;
      color: var(--darkGrey);
    }
    button {
      font-size: var(--h6Size);
      font-weight: var(--h6Weight);
      letter-spacing: var(--h6Letter);
      line-height: var(--h6Line);
      padding: 0;
      color: var(--darkBlue);
    }
  }

  .user-controllers {
    display: flex;
    gap: 52px;

    a {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    button {
      &:hover {
        h6 {
          color: var(--darkBlue);
        }
      }
    }

    h6 {
      color: #82b2f3;
    }

    svg {
      width: 24px;
    }
  }
`;

export const NotificationContainer = styled.div<{ notifications: number }>`
  position: relative;
  &::after {
    content: '${props => props?.notifications > 0 ? `${props?.notifications.toString()}` : null}';
    width: 10px;
    height: 10px;

    display: ${props => props?.notifications > 0 ? 'flex' : 'none!important'};
    align-items: center;
    justify-content: center;

    position: absolute;
    top: -5px;
    right: 30%;

    font-size: 9px;
    line-height: 0;

    padding: 8px;
    border-radius: 100%;

    background: var(--red);
    color: #fff;
    z-index: 15;
  }
`;
