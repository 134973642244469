/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';

import MaterialTable, { Column } from 'material-table';
import { differenceInDays } from 'date-fns';
import { ReactComponent as CloseButton } from '../../../../icons/blue-close-button-icon.svg';
import { Container, PopUp, Button } from './styles';
import Status from '../../../../components/Table/components/status';
import Priority from '../../../../components/Table/components/priority';

type popUpProp = {
  setPopUpState?: any;
  isOpen?: boolean;
  occurrences: any;
};

PDVFilter.defaultProps = {
  setPopUpState: false,
  isOpen: true,
};

export default function PDVFilter({
  setPopUpState,
  isOpen,
  occurrences,
}: popUpProp) {
  const popUpRef = useRef(null);

  const handleClick = (e: MouseEvent) => {
    if (e.target === popUpRef?.current) setPopUpState(false);
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  const getPriority = (create_at: string) => {
    const priority = differenceInDays(new Date(), new Date(create_at));

    if (priority <= 3) return 'Baixa';
    if (priority > 3 && priority < 6) return 'Média';
    return 'Alta';
  };

  const TableColumns = [
    {
      title: 'Chamado',
      field: 'num',
      type: 'numeric',
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: 'Solicitante',
      field: 'user.name',
    },
    {
      title: 'Produtos Reportados',
      field: 'details.length',
      type: 'numeric',
      width: '5%',
      cellStyle: {
        width: '5%',
        textAlign: 'left',
        paddingLeft: '40px',
      },
    },
    {
      title: 'Valor Total',
      field: 'total_price',
      render: rowData =>
        rowData?.total_price?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
    },
    {
      title: 'Responsável',
      field: 'status.next_user.name',
      render: rowData => rowData?.status?.next_user?.name || '--',
    },
    {
      title: 'Categoria',
      field: 'details[0].product.category.name',
    },
    {
      title: 'Status',
      field: 'status.status',
      render: rowData => <Status title={rowData?.status?.status} />,
    },
    {
      title: 'Prioridade',
      field: 'priority',
      render: rowData => <Priority title={getPriority(rowData.create_at)} />,
    },
    {
      title: 'Data Abertura',
      field: 'parsedCreate_at',
    },
  ] as Column<any>[];

  return (
    <Container ref={popUpRef}>
      <PopUp>
        <div className="pop-up-header">
          <h5>Central de Ocorrências</h5>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>

        <span className="filter-section">
          <div className="filter-one-field">
            <div className="select-field" style={{ marginTop: '20px' }}>
              <MaterialTable
                title=""
                columns={TableColumns}
                data={occurrences}
                options={{
                  search: true,
                  exportButton: true,
                  pageSizeOptions: [5, 10, 20, 50, 100],
                }}
              />
            </div>
          </div>
        </span>
      </PopUp>
    </Container>
  );
}
