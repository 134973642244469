/* eslint-disable consistent-return */
import React, { createContext, useContext, useState } from 'react';
import api from '../services/api';
import { Occurrence } from '../models';
import { useError } from './errors';

export interface OccurrencesContextData {
  occurrences: any[] | null;
  getOccurrences(): Promise<void>;
  updateOccurrences(occurrence: any): void;
  isLoading: boolean;
}
export const OccurrencesContext = createContext<OccurrencesContextData>(
  {} as OccurrencesContextData,
);
export const OccurrencesProvider: React.FC = ({ children }: any) => {
  const [occurrences, setOccurrences] = useState<any[]>([]);
  const { emitError } = useError();
  const [loading, setLoading] = useState(false);

  const getOccurrences = async () => {
    try {
      setLoading(true);

      const response = await api.get('/occurrences');
      setOccurrences(response.data);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      emitError(error);
    }
  };

  const updateOccurrences = (newOccurrence: any) => {
    setOccurrences(old => [newOccurrence, ...old]);
  };

  return (
    <OccurrencesContext.Provider
      value={{
        isLoading: loading,
        occurrences,
        getOccurrences,
        updateOccurrences,
      }}
    >
      {children}
    </OccurrencesContext.Provider>
  );
};

export function useOccurrences(): OccurrencesContextData {
  const context = useContext(OccurrencesContext);
  return context;
}
