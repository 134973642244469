/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';

import BgImage from '../../img/login-background.webp';

const animation = keyframes`

from {
  left:700px;
}

to {
  left:00px;
}

`;

export const MainContainer = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  background-image: url(${BgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--darkBlue);
`;

interface LoginProps {
  resetPass: string;
}

export const Container = styled.div<LoginProps>`
  width: 100%;
  max-width: 368px;
  padding: 0 20px;
  display: grid;

  .recover-pass,
  .login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  .recover-pass {
    .reset-button {
      width: 100%;
      margin-top: 1em;
      padding: 13px;
      background: linear-gradient(225deg, #808895 0%, #404249 100%);
      color: white;
      position: relative;
      isolation: isolate;
      border-radius: 12px;

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 12px;
        background: linear-gradient(-225deg, #808895 0%, #404249 100%);
        opacity: 0;
        transition: 0.3s;
        z-index: -1;
      }

      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }
    .go-back {
      width: fit-content;
      color: white;
      margin-bottom: 2em;
      font-size: 10px;
      display: flex;

      svg {
        margin-right: 14px;
        transform: rotate(-180deg);
        path {
          color: white;
        }
      }
    }
    label {
      color: white;
      margin-bottom: 12px;
      font-size: var(--h6Size);
      font-weight: var(--h6Weight);
      letter-spacing: var(--h6Letter);
      line-height: var(--h6Line);
    }
  }

  .action {
    animation: 0.4s ease ${animation} forwards;
    position: relative;
    opacity: 1;
    pointer-events: unset;
  }

  .gm-logo {
    width: 100%;
    margin-bottom: 41px;
  }

  .login-fields {
    display: flex;
    flex-direction: column;
    gap: 12px;

    label {
      color: white;
      font-size: var(--h6Size);
      font-weight: var(--h6Weight);
      letter-spacing: var(--h6Letter);
      line-height: var(--h6Line);
    }

    input {
      margin-top: 6px;
    }
  }

  .secondary-options {
    margin: 23px 0 31px 0;
    display: flex;
    justify-content: space-between;

    label {
      align-items: center;
      display: flex;
      color: white;
      font-size: var(--smallSize);
      font-weight: var(--smallWeight);
      letter-spacing: var(--smallLetter);
      line-height: var(--smallLine);
    }
    input {
      width: 26px;
    }

    button {
      color: white;
    }
  }

  .forgot {
    color: white;
    font-size: 10px;
    font-weight: 700;
  }

  .login-button {
    width: 100%;

    padding: 13px;
    background: linear-gradient(225deg, #808895 0%, #404249 100%);
    color: white;
    position: relative;
    isolation: isolate;
    border-radius: 12px;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 12px;
      background: linear-gradient(-225deg, #808895 0%, #404249 100%);
      opacity: 0;
      transition: 0.3s;
      z-index: -1;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  .pdv-logo {
    width: 100%;
    max-width: 128px;

    margin: 72px auto 0 auto;
  }
`;
