/* eslint-disable array-callback-return */

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as CloseButton } from '../../../../../icons/blue-close-button-icon.svg';
import { Container, PopUp } from './styles';
import { useError } from '../../../../../hooks/errors';
import api from '../../../../../services/api';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  rowData?: string[] | any;
};

Carrousel.defaultProps = {
  rowData: [],
};

export default function Carrousel({
  isOpen,
  setPopUpState,
  rowData,
}: popUpProp) {
  const popUpRef = useRef(null);
  const { emitError } = useError();

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  return (
    <Container>
      <PopUp ref={popUpRef}>
        <div className="pop-up-header">
          <h5>Fotos </h5>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>

        <div className="photo-section">
          {rowData?.photos?.map(e => (
            <img src={e} alt={e} />
          ))}
        </div>
      </PopUp>
    </Container>
  );
}
