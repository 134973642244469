import styled, { keyframes } from 'styled-components';

const animation = keyframes`

from {
  left:700px;
}

to {
  left:00px;
}

`;

const entranceAnim = keyframes`

from {

  width:0px;
  max-height:0px;
  opacity:0;
}
to {

  width:100%;
  max-height:9000px;
  opacity:1;
}

`;

export const Container = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: calc(9 * 99);
`;

export const PopUp = styled.div`
  width: 100%;
  max-width: 404px;
  min-height: 40px;
  left: 50%;
  transform: translateX(-50%);
  top: 10%;
  position: absolute;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  padding: 14px 16px;
  overflow: hidden;
  animation: 0.4s ${entranceAnim} ease;

  .pop-up-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding: 0 76px 0 24px;

    button {
      position: absolute;
      top: 10px;
      right: 16px;

      svg {
        width: 16px;
      }
    }
  }

  form {
    position: relative;
    input {
      margin-top: 6px;
    }
  }

  .fields-section {
    position: relative;

    .information-section,
    .end-section {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }

    .action {
      animation: 0.4s ease ${animation} forwards;
      pointer-events: unset;
      position: relative;
      opacity: 1;
    }
  }

  .popUp-controllers {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .active {
      color: var(--white);
      background: linear-gradient(225deg, #32bdf9 0%, #2e7feb 100%);
    }

    button {
      padding: 8px 20px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      color: var(--mediumGrey);
      font-size: var(--h6Size);
      font-weight: var(--h6Weight);
      letter-spacing: var(--h6Letter);
      line-height: var(--h6Line);
    }
  }

  .conditional-content {
    margin-top: 24px;
    padding: 0 35px;

    .input-field {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      li {
        flex-basis: 328px;
        flex-grow: 1;
      }
    }

    .executive-selection {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 16px;
      margin-top: 12px;

      li {
        button {
          color: var(--lightBlue);
          border-radius: 14px;
          border: 1px solid;
          padding: 4px 8px;
          font-size: var(--h6Size);
          font-weight: var(--h6Weight);
          letter-spacing: var(--h6Letter);
          line-height: var(--h6Line);
        }
      }
    }
  }

  .step-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    justify-content: center;
    align-items: center;

    .cancel-button {
      margin-top: 12px;
    }
  }
`;

export const CascadingList = styled.li`
  width: 100%;
  margin: 3px 0;
  list-style: none;
  transition: 0.3s;

  &:hover {
    span {
      color: var(--white);
    }
    button {
      &::before {
        background: rgba(214, 242, 254, 0.4);
      }
    }

    span {
      path,
      svg {
        fill: var(--white);
      }
    }
  }

  .nav-link {
    width: 100%;
    padding: 7px 0;
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;

    span {
      display: flex;
      align-items: center;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 600;
      color: var(--pdvNeutro);
    }
  }

  .angle-icon {
    width: 16px;
    transition: 0.3s;
    z-index: 2;
    margin-left: 8px;

    path {
      fill: var(--pdvNeutro);
    }
  }

  .menu-itens {
    overflow: hidden;
    transition: 0.3s ease;

    li {
      padding: 5px 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 0.3s;
      list-style: none;

      h6 {
        display: block;
        color: var(--pdvNeutro);
        transition: 0.3s;
      }

      &:nth-last-child(1) {
        display: none;
      }
    }

    .active-link-gm {
      font-weight: 600;

      &::before {
        content: '• ';
        color: var(--orange);
      }
    }
  }
`;

export const SwitchButton = styled.div`
  display: flex;
  align-items: center;

  .active {
    background: var(--darkBlue);
    span {
      right: 4px;
      opacity: 1;
    }
  }
  button {
    width: 40px;
    height: 20px;
    background: #d9d9db;
    border-radius: 12px;
    position: relative;

    span {
      width: 12px;
      height: 12px;
      position: absolute;
      border-radius: 99px;
      background: white;
      top: 50%;
      transform: translateY(-50%);
      right: 22px;
      opacity: 0.8;
      transition: 0.3s;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  h6 {
    margin-left: 14px;
  }
`;

export const PermissionsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 15px;

  > div {
    color: #808895;
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 15px;
  }

  > span {
    color: #808895;
    font-family: 'Montserrat';
    font-size: 13px;

    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;
