/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { formatISO } from 'date-fns';
import { ValueType } from 'rsuite/lib/DateRangePicker';
import api from '../../services/api';
import { ReactComponent as Dots } from '../../icons/3dots-icon.svg';
import EquipePopUp from '../../components/userPopUps/Equipe';
import TableWControllers from '../../components/tableWControllers';
import { useUsers } from '../../hooks/users';
import Filter from '../../components/PDVfilter';
import { basicRole } from '../../models';
import { useError } from '../../hooks/errors';

import { Container } from './styles';

export default function Equipe() {
  const { emitError } = useError();
  const [tableData, setTableData] = useState<any[]>();
  const [addEquipe, setAddEquipe] = useState(false);
  const [editEquipe, setEditEquipe] = useState(false);
  const [addRowData, setAddRowData] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const { users, getUsers } = useUsers();
  const [filteredData, setFilteredData] = useState();
  const [isFiltering, setIsFiltering] = useState<ValueType>();

  const parseData = (list: any[]): any => {
    const parsed = list?.map((it: any) => ({
      ...it,

      parsedMANAGER2: findUser(it.users, 'MANAGER2'),
      parsedMANAGER1: findUser(it.users, 'MANAGER1'),
      parsedSUPERVISOR: findUser(it.users, 'SUPERVISOR'),

      parsedCreateAt: `${new Date(
        it?.create_at,
      ).toLocaleDateString()} às ${new Date(
        it?.create_at,
      ).toLocaleTimeString()} `,
      parsedUpdateAt: `${new Date(
        it?.update_at,
      ).toLocaleDateString()} às ${new Date(
        it?.update_at,
      ).toLocaleTimeString()} `,
    }));

    return parsed;
  };

  const handleFilter = async (e: ValueType) => {
    try {
      const { data: response } = await api.get('/teams', {
        params: {
          startAt: formatISO(e[0]),
          endAt: formatISO(new Date(formatISO(e[1])).setHours(23, 59, 59)),
        },
      });

      setIsFiltering(e);
      setFilteredData(response);
    } catch (error) {
      emitError(error);
    }
  };

  const findUser = (userList: any[], role: basicRole): string => {
    const foundIt = userList?.find(user => user.role.basicRole === role);
    return foundIt?.name || 'Nenhum';
  };

  useEffect(() => {
    if (!users) getUsers();
    api
      .get('/teams')
      .then((res: any) => {
        const parsed = parseData(res.data);
        setTableData(parsed);
      })
      .catch(error => {
        emitError(error);
      });
  }, []);

  const columnUser = [
    { title: 'Equipe', field: 'name' },
    {
      title: 'Gerente Regional',
      field: 'parsedMANAGER2',
    },
    {
      title: 'Gerente Distrital',
      field: 'parsedMANAGER1',
    },
    {
      title: 'Supervisor',
      field: 'parsedSUPERVISOR',
    },
    { title: 'Criado em', field: 'parsedCreateAt' },
    { title: 'Atualizado em', field: 'parsedUpdateAt' },
  ];

  return (
    <Container>
      <TableWControllers
        addButtonText="Adicionar Equipes"
        title="Equipes"
        filter
        filterState={openFilter}
        setFilter={setOpenFilter}
        isFiltering={!!isFiltering}
        column={[
          ...columnUser,
          {
            width: '10%',
            cellStyle: {
              textAlign: 'right',
            },

            render: (rowData: any) => (
              <button
                type="button"
                onClick={() => {
                  setAddRowData(rowData);
                  setEditEquipe(true);
                }}
              >
                <Dots />
              </button>
            ),
          },
        ]}
        data={isFiltering ? filteredData : tableData}
        addButtonState={addEquipe}
        addButtonFunction={setAddEquipe}
      />

      {addEquipe ? (
        <EquipePopUp isOpen={addEquipe} setPopUpState={setAddEquipe} />
      ) : null}

      {editEquipe ? (
        <EquipePopUp
          isOpen={editEquipe}
          setPopUpState={setEditEquipe}
          rowData={addRowData}
        />
      ) : null}

      {openFilter ? (
        <Filter
          isOpen={openFilter}
          setPopUpState={setOpenFilter}
          handleChange={handleFilter}
          handleClearFilters={setIsFiltering}
          date={isFiltering}
        />
      ) : null}
    </Container>
  );
}
