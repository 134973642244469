/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useError } from '../../hooks/errors';
import Input from '../Form/input';
import api from '../../services/api';
import { ReactComponent as Camera } from '../../icons/camera-icon-blue.svg';
import { ReactComponent as Upload } from '../../icons/upload-icon.svg';
import { ReportInput } from './styles';
import OutPricePopUp from './OutPricePopUp';
import { useMotivations } from '../../hooks/motivations';

interface AddProductRef {
  submit(): void;
  reset(): void;
}

interface Props {
  children?: any;
  category: string;
  handleProducts(data: any): void;
}

const AddProductForm: React.ForwardRefRenderFunction<AddProductRef | Props> = (
  props: Props,
  fRef,
) => {
  const [imageList, setImageList] = useState<{ id: string; url: string }[]>([]);
  const [selectMotivation, setSelectMotivation] = useState('Qualidade');
  const { motivations, getMotivations } = useMotivations();
  const [reportFile, setReportFile] = useState<any>();
  const [formSKU, setFormSKU] = useState<any>();
  const [showOutPriceModal, setShowOutPriceModal] = useState<boolean>(false);
  const [reportUploaded, setReportUploaded] = useState<any>();
  const [outPriceJustification, setOutPriceJustification] = useState<
    string | null
  >();
  const [sellPrice, setSellPrice] = useState<number>();
  const [quantity, setQuantity] = useState<number>();
  const [totalPrice, setTotalPrice] = useState<number>();
  const [currentProduct, setCurrentProduct] = useState<any>();
  const formRef = useRef<any>();
  const { emitError } = useError();

  useImperativeHandle(fRef, () => ({
    submit() {
      formRef?.current.submitForm();
      return formRef?.current.getData();
    },

    reset() {
      // formRef?.current.reset();
    },
  }));

  useEffect(() => {
    getMotivations(props.category);
  }, []);

  useEffect(() => {
    if (!quantity || !sellPrice) return;
    setTotalPrice(quantity * sellPrice);
  }, [sellPrice, quantity]);

  const handleSubmit = async formData => {
    if (
      selectMotivation === 'Validade' &&
      formData.validity &&
      formData.validity.replace(/\D/g, '') >
        new Date().toISOString().split('T')[0].replace(/\D/g, '')
    ) {
      emitError({}, 'Data de validade não pode ser maior que a data de hoje!');
      return;
    }

    try {
      const schema = Yup.object().shape({
        product_id: Yup.string().required('Produto é obrigatório'),
        justification: Yup.string().required('Motivo é obrigatório'),
        batch: Yup.string(),
        report:
          selectMotivation === 'Quebra de Freezer'
            ? Yup.string().required('Laudo tecnico é obrigatório')
            : Yup.string(),
        quantity: Yup.number().min(1).required('Quantidade é obrigatória'),
        sell_price: Yup.number().required('Preço é obrigatório'),
        validity:
          selectMotivation === 'Validade'
            ? Yup.string().required('Validade é obrigatória')
            : Yup.string(),
        outPriceJustification: Yup.string(),
        photos: Yup.array().of(Yup.string()),
      });

      if (reportUploaded) formData.report = reportUploaded?.url;
      if (outPriceJustification)
        formData.outPriceJustification = outPriceJustification;
      formData.justification = selectMotivation;
      formData.photos = imageList.map(img => img.url) || [];
      formData.product_id = currentProduct?.id;
      delete formData.category;
      delete formData.name;
      delete formData.sku;
      delete formData.picture;

      formData = Object.entries(formData).reduce(
        // eslint-disable-next-line no-return-assign
        (a, [k, v]: any) => (v?.length <= 0 ? a : ((a[k] = v), a)),
        {} as any,
      );

      await schema.validate(formData, {
        abortEarly: false,
      });

      // eslint-disable-next-line react/prop-types
      props.handleProducts(formData);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha todos os campos obrigatórios!');

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef?.current?.setErrors(errorMessages);
      }
    }
  };

  async function handleDelete(id) {
    try {
      await api.delete(`/upload/${id}`);
      setImageList(list => list.filter(item => item.id !== id));
    } catch (error: any) {
      emitError(error);
    }
  }

  async function setNewImage(element) {
    const file = element.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post(`/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setImageList(list => [
        ...list,
        { id: response.data.key, url: response.data.url },
      ]);
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  async function handleReport(e) {
    setReportFile(e.target.files[0]);
    setReportUploaded(await handleUpload(e));
  }

  async function handleUpload(e) {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await api.post(`/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error: any) {
      emitError(error);
      // eslint-disable-next-line no-useless-return, consistent-return
      return;
    }
  }

  async function handleSellPriceBlur() {
    if (!sellPrice) return;

    if (
      sellPrice < Number(currentProduct?.min_price) ||
      sellPrice > Number(currentProduct?.max_price)
    ) {
      setShowOutPriceModal(true);
    }
  }

  useEffect(() => {
    if (!formSKU || formSKU?.length < 3) return;

    const timeoutId = setTimeout(() => {
      // eslint-disable-next-line no-async-promise-executor
      const promise = new Promise(async (resolve, reject) => {
        try {
          const product = await api.get(`/products/${formSKU}`);

          if (props.category !== product?.data?.category?.name) {
            toast.info('SKU não pertence a categoria selecionada!');
            reject(new Error('false'));
          }
          resolve(true);
          setCurrentProduct(product.data);
        } catch (error: any) {
          // eslint-disable-next-line no-console
          console.error(error);
          reject(new Error('false'));

          setCurrentProduct(undefined);
        }
      });

      toast.promise(promise, {
        pending: 'Procurando SKU...',
        success: 'SKU Encontrado!',
        error: 'SKU Incorreto!',
      });
    }, 1300);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId);
  }, [formSKU]);

  return (
    <>
      <Form id="form" ref={formRef} onSubmit={handleSubmit}>
        <section className="add-product active">
          <div className="table-title">
            <p>Adicionar Produto</p>
          </div>

          <ul className="table-list" id="form">
            <li className="product-table-content">
              <div className="input-divider">
                <div>
                  <label>Categoria</label>
                  <Input
                    id="category"
                    name="category"
                    type="text"
                    value={props.category}
                    placeholder="Ex: Categoria X"
                    disabled
                  />
                </div>

                <div>
                  <label>
                    SKU{' '}
                    <span style={{ display: 'inline', color: 'red' }}>*</span>
                  </label>
                  <Input
                    id="sku"
                    name="sku"
                    type="text"
                    placeholder="Ex: 432234"
                    change={value => setFormSKU(value)}
                  />
                </div>

                <div>
                  <label>Nome Produto</label>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    value={currentProduct && currentProduct.name}
                    placeholder="Ex: Produto X"
                    disabled
                  />
                </div>
              </div>
              <div className="input-divider">
                <div>
                  <label>
                    Motivo Devolução{' '}
                    <span style={{ display: 'inline', color: 'red' }}>*</span>
                  </label>
                  <select
                    id="justification"
                    name="justification"
                    placeholder="SELECT"
                    onChange={e => setSelectMotivation(e?.target?.value)}
                  >
                    <option value={null} disabled hidden selected>
                      Selecionar motivo
                    </option>
                    {motivations?.map(motivation => (
                      <option value={motivation}>{motivation}</option>
                    ))}
                  </select>
                </div>

                {selectMotivation === 'Quebra de Freezer' && (
                  <div style={{ width: '200px' }}>
                    <label>
                      Laudo Técnico{' '}
                      <span style={{ display: 'inline', color: 'red' }}>*</span>
                    </label>
                    <div style={{ position: 'relative' }}>
                      <ReportInput
                        id="report"
                        name="report"
                        type="file"
                        fileName={reportFile?.name}
                        accept=".pdf"
                        onChange={handleReport}
                      />
                      <Upload
                        style={{
                          position: 'absolute',
                          right: '6px',
                          top: '50%',
                          transform: 'translate(0, -50%)',
                          pointerEvents: 'none',
                        }}
                      />
                    </div>
                  </div>
                )}

                <div>
                  <label>
                    Quantidade{' '}
                    <span style={{ display: 'inline', color: 'red' }}>*</span>
                  </label>
                  <Input
                    id="quantity"
                    name="quantity"
                    type="number"
                    change={setQuantity}
                    placeholder="10"
                  />
                </div>

                <div>
                  <label>
                    Preço de Venda{' '}
                    <span style={{ display: 'inline', color: 'red' }}>*</span>
                  </label>
                  <Input
                    id="sell_price"
                    name="sell_price"
                    type="number"
                    change={setSellPrice}
                    placeholder="R$ 10,00"
                    disabled={!currentProduct}
                    title={!currentProduct && 'Insira um SKU.'}
                    onBlur={handleSellPriceBlur}
                  />
                </div>
              </div>
              <div className="input-divider">
                <div>
                  <label>
                    Validade{' '}
                    {selectMotivation === 'Validade' && (
                      <span style={{ display: 'inline', color: 'red' }}>*</span>
                    )}
                  </label>
                  <div>
                    <Input id="validity" name="validity" type="date" />
                  </div>
                </div>
                {selectMotivation === 'Qualidade' && (
                  <div>
                    <label>Lote</label>
                    <Input
                      id="batch"
                      name="batch"
                      type="text"
                      placeholder="Lote X"
                    />
                  </div>
                )}

                <div style={{ width: '100px' }}> </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label>Valor Total da Devolução</label>
                  <span>
                    {(totalPrice || 10000)?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </section>
      </Form>

      <div id="picture-section">
        <div className="picture-field">
          <input
            type="file"
            accept="image/*"
            onInput={element => setNewImage(element)}
          />
          <Camera /> <p>Inserir Imagem</p>
        </div>
        {imageList.map(pic => (
          <span
            // id={`picture-position-${pic.position}`}
            className="picture-field added-pic"
            style={{ backgroundImage: `url(${pic.url})` }}
          >
            <button
              type="button"
              className="erase-img"
              onClick={() => handleDelete(pic.id)}
            >
              X
            </button>
            <Input type="hidden" name="picture" value={pic.url} />
          </span>
        ))}
        <span />
      </div>

      {showOutPriceModal && (
        <OutPricePopUp
          isOpen={showOutPriceModal}
          setPopUpState={setShowOutPriceModal}
          handleOutPrice={data => setOutPriceJustification(data)}
          product={currentProduct}
        />
      )}
    </>
  );
};

export default forwardRef(AddProductForm) as any;
