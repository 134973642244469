import React, { useEffect, useState } from 'react';

type priorityProps = {
  title: string;
};

export default function Priority({ title }: priorityProps) {
  const [color, setColor] = useState('#000');

  useEffect(() => {
    if (title === 'Baixa') {
      setColor('var(--green)');
    } else if (title === 'Alta') {
      setColor('var(--red)');
    } else if (title === 'Média') {
      setColor('var(--orange)');
    }
  }, [title]);

  const priorityStatus = {
    background: color,
    padding: '4px 8px',
    borderRadius: '99px',
    color: 'white',
  };

  return <span style={priorityStatus}>{title}</span>;
}
