/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

interface Props {
  name: string;
  fields: string[] | any[];
  optValue?: boolean;
  value?: any;
  change?(value: any): void;
  [x: string]: any;
}

type SelectProps = JSX.IntrinsicElements['select'] & Props;

export default function Select({
  optValue = false,
  name,
  fields,
  value,
  change,
  ...rest
}: SelectProps) {
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  const [selected, setSelected] = useState<any>();

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      {change ? (
        <select
          ref={inputRef}
          value={selected || value}
          onChange={e => {
            setSelected(e?.target?.value);
            change(e);
          }}
          {...rest}
        >
          {optValue
            ? fields?.map((e: null | undefined | any) => (
                <option key={e.id} value={e?.id} selected={e?.value}>
                  {e.name}
                </option>
              ))
            : fields?.map((e: null | undefined | any) => (
                <option value={e} selected={e?.value}>
                  {e}
                </option>
              ))}
        </select>
      ) : (
        <select
          ref={inputRef}
          value={selected || value}
          onChange={e => {
            setSelected(e?.target?.value);
          }}
          {...rest}
        >
          {optValue
            ? fields?.map((e: null | undefined | any) => (
                <option key={e.id} value={e?.id} selected={e?.value}>
                  {e.name}
                </option>
              ))
            : fields?.map((e: null | undefined | any) => (
                <option key={e.id} value={e} selected={e?.value}>
                  {e}
                </option>
              ))}
        </select>
      )}
      {error && <small style={{ color: 'red' }}>{error}</small>}
    </>
  );
}
