import React, { useRef, useEffect, useState } from 'react';

import { ReactComponent as CloseButton } from '../../../../../icons/blue-close-button-icon.svg';
import { Container, PopUp } from './styles';
import HistoryStatus from './status';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;

  status?: string[] | any;
};

PopUpHistory.defaultProps = {
  status: [],
};

export default function PopUpHistory({
  isOpen,
  setPopUpState,

  status,
}: popUpProp) {
  const [currentPosition, setCurrentPosition] = useState(0);
  const popUpRef = useRef(null);
  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  const data = [
    {
      content: <HistoryStatus step={0} history={status} />,
      done:
        status.status.current === 0 ||
        status.status.current === 1 ||
        status.status.current === 2 ||
        status.status.current === 3 ||
        status.status.current === 4 ||
        status.status.current === 5,
    },
    {
      content: <HistoryStatus step={1} history={status} />,
      done:
        status.status.current === 1 ||
        status.status.current === 2 ||
        status.status.current === 3 ||
        status.status.current === 4 ||
        status.status.current === 5,
    },
    {
      content: <HistoryStatus step={2} history={status} />,
      done:
        status.status.current === 2 ||
        status.status.current === 3 ||
        status.status.current === 4 ||
        status.status.current === 5,
    },
    {
      content: <HistoryStatus step={3} history={status} />,
      done:
        status.status.current === 3 ||
        status.status.current === 4 ||
        status.status.current === 5,
    },
    {
      content: <HistoryStatus step={4} history={status} />,
      done: status.status.current === 4 || status.status.current === 5,
    },
    {
      content: <HistoryStatus step={5} history={status} />,
      done: status.status.current === 5,
    },
  ];

  const currentState: React.MutableRefObject<any> = useRef(null);

  useEffect(() => {
    if (status.status.current < 4) {
      const position =
        (status.status.current * 100) / (status.status.final + 1);
      setCurrentPosition(position);
    }
  }, [status.status.current]);

  return (
    <Container>
      <PopUp size={currentPosition} status={status.status} ref={popUpRef}>
        <div className="pop-up-header">
          <h5> Histórico da chamada</h5>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>

        <div className="steps-on-history">
          {data.slice(0, status.status.final + 1).map(e => (
            <div className="current-state">
              <span
                className={e.done === true ? 'current-active' : ''}
                ref={e.done === true ? currentState : null}
              />
              {e.content}
            </div>
          ))}
        </div>
      </PopUp>
    </Container>
  );
}
