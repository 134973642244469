/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ReactComponent as CloseButton } from '../../../icons/blue-close-button-icon.svg';
import BlueButton from '../../blueButton';
import { Container, PopUp } from './styles';
import Input from '../../Form/input';
import api from '../../../services/api';
import RedButton from '../../redButton';
import Delete from '../../DeletePopUp';
import { useError } from '../../../hooks/errors';
import ApprovePopUp from '../../ApprovePopUp';
import { useCategories } from '../../../hooks/categories';
import SwitchButton from '../../switchButton';

type popUpProp = {
  setPopUpState: any;
  isOpen: boolean;
  rowData?: string[] | any;
};

CategoriaPopUp.defaultProps = {
  rowData: false,
};

export default function CategoriaPopUp({
  isOpen,
  setPopUpState,
  rowData,
}: popUpProp) {
  const popUpRef = useRef(null);
  const { emitError } = useError();
  const formRef = useRef(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { updateCategories } = useCategories();

  const [deletePdv, setDeletePdv] = useState(false);

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  async function handlePost(data: any) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome da categoria é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const newCategory = await api.post('/categories', data);
      updateCategories(newCategory.data);
      toast.success('Nova categoria adicionada!');
      setPopUpState(false);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios!');
        setShowConfirmation(false);

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  async function handlePut(data: any) {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome da categoria é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.put(`/categories/${rowData.id}`, data);
      window.location.reload();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        emitError({}, 'Preencha os campos obrigatórios!');
        setShowConfirmation(false);

        const errorMessages = {};
        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        formRef.current.setErrors(errorMessages);
      }
    }
  }

  async function handleDelete() {
    try {
      await api.delete(`/categories/${rowData.id}`);
      toast.success('Categoria deletado');
      window.location.reload();
    } catch (error: any) {
      toast.error('Categoria referenciado por outra tabela');
    }
  }

  return (
    <Container>
      <PopUp ref={popUpRef}>
        {deletePdv ? (
          <Delete
            title="Ponto de Venda"
            isOpen={deletePdv}
            setCustomState={handleDelete}
            setPopUpState={setDeletePdv}
          />
        ) : null}
        <div className="pop-up-header">
          <p>Categoria</p>

          <button type="button" onClick={() => setPopUpState(!isOpen)}>
            <CloseButton />
          </button>
        </div>
        <Form ref={formRef} onSubmit={rowData ? handlePut : handlePost}>
          <ul>
            <li>
              <label htmlFor="name">
                Nome
                <span style={{ display: 'inline', color: 'red' }}> *</span>
              </label>
              <Input
                name="name"
                id="name"
                placeholder={rowData.name}
                value={rowData.name}
              />
              <Input name="enabled" type="hidden" value="true" />
            </li>
          </ul>
          <div className="switch-button">
            <SwitchButton
              title="Habilitado"
              name="enabled"
              isTrue={
                // eslint-disable-next-line eqeqeq
                rowData?.enabled != undefined ? rowData?.enabled : true
              }
            />
          </div>

          {rowData ? (
            <div className="edit-pdv">
              <RedButton
                title="Apagar"
                setCustomState={setDeletePdv}
                customState={deletePdv}
              />
              <BlueButton
                title="Editar"
                setCustomState={setShowConfirmation}
                customState={showConfirmation}
              />
            </div>
          ) : (
            <div className="button-field">
              <BlueButton
                setCustomState={setShowConfirmation}
                customState={showConfirmation}
                title="Salvar"
              />

              <button
                type="button"
                className="cancel-button"
                onClick={() => setPopUpState(!isOpen)}
              >
                Cancelar
              </button>
            </div>
          )}

          {showConfirmation ? (
            <ApprovePopUp
              sampleText="Adicionar Categoria?"
              isOpen={showConfirmation}
              setPopUpState={setShowConfirmation}
            />
          ) : null}
        </Form>
      </PopUp>
    </Container>
  );
}
