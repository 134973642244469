/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as CloseButton } from '../../../icons/blue-close-button-icon.svg';
import RedButton from '../../redButton';
import { PopUp } from './styles';

type popUpProp = {
  handleOutPrice(data: any): void;
  product: any;
  setPopUpState: any;
  isOpen: boolean;
};

export default function ApprovePopUp({
  isOpen,
  setPopUpState,
  handleOutPrice,
  product,
}: popUpProp) {
  const popUpRef = useRef(null);
  const [justification, setJustification] = useState<string | null>();

  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    if ((popUpRef?.current! as any)?.contains(e?.target)) {
      return;
    }
    setPopUpState(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isOpen]);

  return (
    <PopUp ref={popUpRef}>
      <div className="pop-up-header-approve">
        <p style={{ width: '100%', justifyContent: 'center' }}>
          Preço fora do padrão
        </p>

        <button type="button" onClick={() => setPopUpState(!isOpen)}>
          <CloseButton />
        </button>
      </div>

      <div>
        <p>
          Os valores padrões para esse produto são:
          <div>
            <span style={{ padding: '0 10px' }}>
              Mínimo:{' '}
              <strong>{product?.min_price.toFixed(3).slice(0, -1)}</strong>
            </span>
            <span style={{ padding: '0 10px' }}>
              Máximo:{' '}
              <strong>{product?.max_price.toFixed(3).slice(0, -1)}</strong>
            </span>
          </div>
        </p>
        <form onSubmit={() => undefined} style={{ marginTop: '40px' }}>
          <textarea
            name="outPriceJustification"
            placeholder="Insira aqui a justificativa no valor"
            rows={5}
            onChange={e => {
              setJustification(e?.target?.value);
            }}
          />
        </form>
      </div>

      <div className="button-field">
        <RedButton
          title="Adicionar"
          typeOf="button"
          disabled={!justification || justification?.length < 3}
          onClick={() => {
            handleOutPrice(justification);
            setPopUpState(false);
          }}
        />

        <button
          type="button"
          className="cancel-button"
          onClick={() => setPopUpState(!isOpen)}
        >
          Cancelar
        </button>
      </div>
    </PopUp>
  );
}
