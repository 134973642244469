import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.section`
  width: 100%;
  padding: 0 24px;

  .table {
    padding: 12px 23px 24px 23px;
    border-radius: 20px;
    background: var(--white);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);

    form {
      div:nth-child(2) {
        margin: 24px auto 0 auto;
        display: block;
      }
    }
    .filter-top {
      margin-top: 16px;
      display: flex;
      gap: 24px;

      label {
        flex-basis: 200px;
        flex-grow: 1;
        font-size: var(--h6Size);
        font-weight: var(--h6Weight);
        letter-spacing: var(--h6Letter);
        line-height: var(--h6Line);

        input,
        select {
          margin-top: 6px;
        }
      }
    }
  }

  .table-filtered {
    margin-top: 36px;
    padding: 12px 23px 24px 23px;
    border-radius: 20px;
    background: var(--white);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);

    .header-filtered {
      display: flex;
      justify-content: space-between;

      .main-info {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        h6 {
          color: #b3b8bf;
          margin-right: 8px;
        }
        span {
          display: flex;
          align-items: center;
          font-size: var(--h6Size);
          font-weight: var(--h6Weight);
          letter-spacing: var(--h6Letter);
          line-height: var(--h6Line);
          color: #66686d;
        }
      }

      .ver-ocorrencia {
        border: 1px solid #82b2f3;
        border-radius: 12px;
        padding: 9px 24px;
        color: #82b2f3;
        --smallSize: 8px;
        --smallWeight: 600;
        --smallLetter: 5%;
        --smallLine: 1.2em;
      }
    }
  }
`;
