/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

interface Prop {
  size: number;
  status: string;
}

export const Container = styled.div<Prop>`
  margin-top: 32px;
  display: flex;
  position: relative;
  justify-content: space-between;

  &::after {
    content: '';
    height: 1px;
    background: #d9d9db;
    right: 70px;
    left: 60px;
    position: absolute;
    top: 36%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .current-active {
    position: unset !important ;
    background: ${prop =>
      prop.status === 'Reprovado' ? 'var(--red)' : 'var(--green)'} !important;
    &::before {
      content: '';
      height: 1px;
      background: var(--green);
      background: ${prop =>
        prop.status === 'Reprovado' ? 'var(--red)' : 'var(--green)'};
      width: ${prop => prop.size - 35}px;
      left: 60px;
      position: absolute;
      top: 36%;
      transform: translateY(-50%);
      z-index: -1;
    }
  }

  .current-state {
    height: 118px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h6,
    small,
    span {
      text-align: center;
      z-index: 2;
    }
    small {
      width: 80px;
    }

    span {
      width: 28px;
      height: 28px;
      position: relative;
      border-radius: 99px;
      margin: 12px 0 15px 0;
      background: #d4d4d4;
      z-index: 2;
    }
  }
`;
