/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import Loading from '../../components/Loading';
import GMLogo from '../../icons/LOGO-GM-White.svg';
import PDVLogo from '../../icons/pdv-logo-white.svg';
import Input from '../../components/Form/input';
import { MainContainer, Container } from './styles';
import { AuthContext } from '../../hooks/auth';
import { useError } from '../../hooks/errors';
import { ReactComponent as Angle } from '../../icons/angle-right-icon.svg';

export default function LoginPage() {
  const { signIn, loading } = useContext(AuthContext);
  const { emitError } = useError();
  const formRef = useRef(null);
  const [resetPassword, setResetPassword] = useState('login');
  const [userData, setUserData] = useState({ email: '', password: '' });

  async function handleForgotPassowrd(data: any) {
    await api.post('sessions/password/recover/', data);
    toast.success('Um token foi enviado para o e-mail');
  }

  return (
    <MainContainer>
      {loading ? <Loading /> : null}
      <Container resetPass={resetPassword}>
        <img src={GMLogo} alt="gm-logo" className="gm-logo" />

        <section className="conditional-content">
          <Form
            ref={formRef}
            onSubmit={handleForgotPassowrd}
            className={
              resetPassword === 'login' ? 'action login-form' : 'login-form'
            }
          >
            <div className="login-fields">
              <label>
                E-mail
                <Input
                  type="text"
                  name="email"
                  placeholder="exemplo@gm.com.br"
                  value={userData.email}
                  onChange={e =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                />
              </label>
            </div>

            <button
              type="submit"
              className="login-button"
              style={{ marginTop: 20 }}
            >
              Recuperar
            </button>
            <Link
              to="/login"
              className="forgot"
              style={{ color: '#fff', marginTop: 20, textAlign: 'end' }}
            >
              Voltar
            </Link>
          </Form>
        </section>

        <img src={PDVLogo} alt="pdv-logo" className="pdv-logo" />
      </Container>
    </MainContainer>
  );
}
