/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback } from 'react';
import { differenceInDays } from 'date-fns';
import { ReactComponent as DotsIcon } from '../../../../icons/3dots-icon.svg';
import loadingGif from '../../../../img/loading.gif';
import { Container } from './styles';
import OccurrencesTableModal from '../OccurrencesTableModal';
import { Occurrence } from '../../../../models';

interface Props {
  title: string;
  data: any;
  isLoading: boolean;
}

export default function OcorrenciasGeral({ title, data, isLoading }: Props) {
  const [occurrenceColor, setOccurrenceColor] = useState('dafault');
  const [openFilter, setOpenFilter] = useState(false);
  const [tableData, setTableData] = useState();

  useEffect(() => {
    if (title.indexOf('4 dias') > -1) {
      setOccurrenceColor('default');
    } else if (title.indexOf('4 e 7 dias') > -1) {
      setOccurrenceColor('orange');
    } else if (title.indexOf('mais de 7 dias') > -1) {
      setOccurrenceColor('red');
    }
  }, [title]);

  const getOccurrencePriority = useCallback((create_at: string) => {
    const priority = differenceInDays(new Date(), new Date(create_at));

    if (priority <= 3) return 'Baixa';
    if (priority > 3 && priority < 6) return 'Média';
    return 'Alta';
  }, []);

  useEffect(() => {
    const table = data?.map((occurrence: Occurrence) => ({
      ...occurrence,
      priority: getOccurrencePriority(occurrence.create_at),
      parsedCreate_at: `${new Date(
        occurrence.create_at,
      ).toLocaleDateString()} às ${new Date(
        occurrence.create_at,
      ).toLocaleTimeString()} `,
    }));
    setTableData(table);
  }, [data]);

  return (
    <Container occurrenceColor={occurrenceColor}>
      {isLoading ? (
        <div className="carregando">
          <div className="carregando2" />
          <img src={loadingGif} alt="" />
        </div>
      ) : null}
      <h6>{title}</h6>

      <h1>{data?.length || 0}</h1>

      <DotsIcon
        className="dots-icon"
        style={{ cursor: 'pointer' }}
        onClick={() => setOpenFilter(true)}
      />

      {openFilter ? (
        <OccurrencesTableModal
          isOpen={openFilter}
          setPopUpState={setOpenFilter}
          occurrences={tableData}
        />
      ) : null}
    </Container>
  );
}
